import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { boolean, date, number, object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormInput,
  FormSelect,
  useFlyIn,
} from '@epix-web-apps/ui'
import {
  DEFAULT_PARENT_STATUS,
  DEFAULT_PRIVATELY_INSURED,
  numericString,
  useGetBuropartnerContractDetailsQuery,
  useUpdateBuropartnerContractDetailSocialSecurityMutation,
  GraphqlError,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { FormSwitch } from '../../../form-components/form-switch'
import { useEffect, useState } from 'react'

/* eslint-disable-next-line */
export interface EditBuropartnerSocialSecurityProps {
  contractId: string | undefined
}

export function EditBuropartnerSocialSecurity({ contractId }: EditBuropartnerSocialSecurityProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useGetBuropartnerContractDetailsQuery({
      contractId: contractId || ''
    })

  const { insuranceStatusOptions, parentStatusOptions } = formTypeSelectOptions

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editBuropartnerSocialSecuritySchema = object({
    socialSecurityNumber: string().optional().nullable(),
    nameStatutoryHealthFund: string().optional().nullable(),
    parentStatusType: string().optional().nullable(),
    parentStatusTypeStartDate: date().optional().nullable(),
    insuranceStatusType: string().optional().nullable(),
    medicalInsuranceDistributions: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    ),
    medicalInsuranceDeductible: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    ),
    nursingCareContributions: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    ),
    nursingCareDeductible: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    ),
    memberProfessionalUtility: boolean(),
    nameProfessionalUtility: string().optional().nullable(),
    memberShipNumber: string().optional().nullable(),
    hasApprenticeship: boolean(),
    apprenticeshipStartDate: date().optional().nullable(),
    apprenticeshipEndDate: date().optional().nullable(),
    hasStudentContract: boolean(),
    studentContractStartDate: date().optional().nullable(),
    studentContractEndDate: date().optional().nullable()
  })
    .refine(
      data =>
        data.apprenticeshipEndDate && data.apprenticeshipStartDate
          ? data.apprenticeshipEndDate >= data.apprenticeshipStartDate
          : !data.apprenticeshipEndDate,
      {
        message: t('form.validation.enddateafterstartdate'),
        path: ['apprenticeshipEndDate']
      }
    )
    .refine(
      data =>
        data.studentContractEndDate && data.studentContractStartDate
          ? data.studentContractEndDate >= data.studentContractStartDate
          : !data.studentContractEndDate,
      {
        message: t('form.validation.enddateafterstartdate'),
        path: ['studentContractEndDate']
      }
    )

  type EditBuropartnerSocialSecurityForm = TypeOf<typeof editBuropartnerSocialSecuritySchema>

  const updateMutation = useUpdateBuropartnerContractDetailSocialSecurityMutation()

  const socialSecurity =
    partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailSocialSecurityModel

  const form = useForm<EditBuropartnerSocialSecurityForm>({
    resolver: zodResolver(editBuropartnerSocialSecuritySchema),
    defaultValues: {
      socialSecurityNumber: socialSecurity?.socialSecurityNumber,
      nameStatutoryHealthFund: socialSecurity?.nameStatutoryHealthFund,
      parentStatusType: socialSecurity?.parentStatusType?.key,
      parentStatusTypeStartDate: socialSecurity?.parentStatusTypeStartDate
        ? new Date(socialSecurity?.parentStatusTypeStartDate)
        : null,
      insuranceStatusType: socialSecurity?.insuranceStatusType?.key,
      medicalInsuranceDistributions: socialSecurity?.medicalInsuranceContribution,
      medicalInsuranceDeductible: socialSecurity?.medicalInsuranceDeductible,
      nursingCareContributions: socialSecurity?.nursingCareContributions,
      nursingCareDeductible: socialSecurity?.nursingCareDeductible,
      memberProfessionalUtility: socialSecurity?.memberProfessionalUtility,
      nameProfessionalUtility: socialSecurity?.nameProfessionalUtility,
      memberShipNumber: socialSecurity?.memberShipNumber,
      hasApprenticeship: socialSecurity?.hasApprenticeship,
      apprenticeshipStartDate: socialSecurity?.apprenticeshipStartDate
        ? new Date(socialSecurity?.apprenticeshipStartDate)
        : null,
      apprenticeshipEndDate: socialSecurity?.apprenticeshipEndDate
        ? new Date(socialSecurity?.apprenticeshipEndDate)
        : null,
      hasStudentContract: socialSecurity?.hasStudentContract,
      studentContractStartDate: socialSecurity?.studentContractStartDate
        ? new Date(socialSecurity?.studentContractStartDate)
        : null,
      studentContractEndDate: socialSecurity?.studentContractEndDate
        ? new Date(socialSecurity?.studentContractEndDate)
        : null
    }
  })
  const { control } = form
  const watchedInsuranceStatus = useWatch({
    control,
    name: `insuranceStatusType`
  })
  const watchedMemberProfessionalUtility = useWatch({
    control,
    name: `memberProfessionalUtility`
  })
  const watchedParentStatus = useWatch({ control, name: 'parentStatusType' })
  const watchedHasApprenticeship = useWatch({
    control,
    name: `hasApprenticeship`
  })
  const watchedHasStudentContract = useWatch({
    control,
    name: `hasStudentContract`
  })

  useEffect(() => {
    form.resetField('medicalInsuranceDistributions')
    form.resetField('nursingCareContributions')
  }, [watchedInsuranceStatus])

  const handleOnSubmit = async (buroparterSocialSecurity: EditBuropartnerSocialSecurityForm) => {
    const parentStatusStartDate =
      buroparterSocialSecurity.parentStatusType && buroparterSocialSecurity.parentStatusType !== DEFAULT_PARENT_STATUS
        ? buroparterSocialSecurity.parentStatusTypeStartDate
          ? buroparterSocialSecurity.parentStatusTypeStartDate
          : null
        : null

    await updateMutation
      .mutateAsync({
        buropartnerContractDetailSocialSecurityCommand: {
          id: partnerSpecificDetails?.buropartnerContractDetailByContractId.id || '',
          socialSecurityNumber: buroparterSocialSecurity.socialSecurityNumber,
          nameStatutoryHealthFund: buroparterSocialSecurity.nameStatutoryHealthFund,
          parentStatusTypeKey: buroparterSocialSecurity.parentStatusType,
          parentStatusTypeStartDate: parentStatusStartDate,
          insuranceStatusTypeKey: buroparterSocialSecurity.insuranceStatusType,
          medicalInsuranceContributions:
            buroparterSocialSecurity.insuranceStatusType === DEFAULT_PRIVATELY_INSURED
              ? buroparterSocialSecurity.medicalInsuranceDistributions
              : null,
          medicalInsuranceDeductible:
            buroparterSocialSecurity.insuranceStatusType === DEFAULT_PRIVATELY_INSURED
              ? buroparterSocialSecurity.medicalInsuranceDeductible
              : null,
          nursingCareContributions:
            buroparterSocialSecurity.insuranceStatusType === DEFAULT_PRIVATELY_INSURED
              ? buroparterSocialSecurity.nursingCareContributions
              : null,
          nursingCareDeductible:
            buroparterSocialSecurity.insuranceStatusType === DEFAULT_PRIVATELY_INSURED
              ? buroparterSocialSecurity.nursingCareDeductible
              : null,
          memberProfessionalUtility: buroparterSocialSecurity.memberProfessionalUtility,
          nameProfessionalUtility: buroparterSocialSecurity.nameProfessionalUtility,
          memberShipNumber: buroparterSocialSecurity.memberShipNumber,
          hasApprenticeship: buroparterSocialSecurity.hasApprenticeship,
          apprenticeshipStartDate: buroparterSocialSecurity.apprenticeshipStartDate,
          apprenticeshipEndDate: buroparterSocialSecurity.apprenticeshipEndDate,
          hasStudentContract: buroparterSocialSecurity.hasStudentContract,
          studentContractStartDate: buroparterSocialSecurity.studentContractStartDate,
          studentContractEndDate: buroparterSocialSecurity.studentContractEndDate
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editburoparnersocialsecurity.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="socialSecurityNumber" label={t('form.field.desocialsecuritynumber')} />

        <FormInput sx={12} name="nameStatutoryHealthFund" label={t('form.field.namestatutoryhealthfund')} />

        <FormSelect
          sx={12}
          name="parentStatusType"
          label={t('form.field.parentstatus')}
          options={parentStatusOptions}
        />

        {watchedParentStatus && watchedParentStatus !== DEFAULT_PARENT_STATUS && (
          <FormDatepicker sx={12} name="parentStatusTypeStartDate" label={t('form.field.parentstatusstartdate')} />
        )}

        <FormSelect
          sx={12}
          name="insuranceStatusType"
          label={t('form.field.insurancestatus')}
          options={insuranceStatusOptions}
        />

        {watchedInsuranceStatus === DEFAULT_PRIVATELY_INSURED && (
          <>
            <FormInput
              sx={12}
              name="medicalInsuranceDistributions"
              label={t('form.field.medicalinsurancedistribution')}
              disabled={watchedInsuranceStatus !== DEFAULT_PRIVATELY_INSURED}
            />

            <FormInput
              sx={12}
              name="medicalInsuranceDeductible"
              label={t('form.field.medicalinsurancedeductible')}
              disabled={watchedInsuranceStatus !== DEFAULT_PRIVATELY_INSURED}
            />

            <FormInput
              sx={12}
              name="nursingCareContributions"
              label={t('form.field.nursingcarecontributions')}
              disabled={watchedInsuranceStatus !== DEFAULT_PRIVATELY_INSURED}
            />

            <FormInput
              sx={12}
              name="nursingCareDeductible"
              label={t('form.field.nursingcaredeductible')}
              disabled={watchedInsuranceStatus !== DEFAULT_PRIVATELY_INSURED}
            />
          </>
        )}

        <FormSwitch
          sx={12}
          name="memberProfessionalUtility"
          onChange={() => {
            form.resetField('nameProfessionalUtility', { defaultValue: null })
            form.resetField('memberShipNumber', { defaultValue: null })
          }}
          label={t('form.field.memberprofessionalutility')}
        />

        {watchedMemberProfessionalUtility && (
          <>
            <FormInput sx={12} name="nameProfessionalUtility" label={t('form.field.nameprofessionalutility')} />

            <FormInput sx={12} name="memberShipNumber" label={t('form.field.memberShipNumber')} />
          </>
        )}

        <FormSwitch
          sx={12}
          name="hasApprenticeship"
          onChange={() => {
            form.resetField('apprenticeshipStartDate', { defaultValue: null })
            form.resetField('apprenticeshipEndDate', { defaultValue: null })
          }}
          label={t('form.field.apprenticeship')}
        />

        {watchedHasApprenticeship && (
          <>
            <FormDatepicker sx={6} name="apprenticeshipStartDate" label={t('form.field.apprenticeshipstartdate')} />

            <FormDatepicker sx={6} name="apprenticeshipEndDate" label={t('form.field.apprenticeshipenddate')} />
          </>
        )}

        <FormSwitch
          sx={12}
          name="hasStudentContract"
          onChange={() => {
            form.resetField('studentContractStartDate', { defaultValue: null })
            form.resetField('studentContractEndDate', { defaultValue: null })
          }}
          label={t('form.field.studentcontract')}
        />

        {watchedHasStudentContract && (
          <>
            <FormDatepicker sx={6} name="studentContractStartDate" label={t('form.field.studentcontractstartdate')} />

            <FormDatepicker sx={6} name="studentContractEndDate" label={t('form.field.studentcontractenddate')} />
          </>
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditBuropartnerSocialSecurity
