import { MsalProvider } from '@azure/msal-react'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionType,
  PublicClientApplication
} from '@azure/msal-browser'
import { EndSessionRequest, RedirectRequest } from '@azure/msal-browser'
import { EpixLoadingPage } from '@epix-web-apps/ui'
import { environment } from '../../../../environments/environment'
import { setFetchHeader, useGetMfaEnabledQuery } from '@epix-web-apps/core'
import { useEffect, useState } from 'react'

const hostArray = window.location.host.split('.')
export const SUBDOMAIN = hostArray.length > 1 ? hostArray[0] : ''
export const PORT = window.location.port ? `:${window.location.port}` : ''
export const SUBDOMAIN_STATE_PREFIX = 'subdomain-'
sessionStorage.setItem('client', SUBDOMAIN)

const authorityBase = `https://${environment.auth.b2cDomainName}/${environment.auth.tenantName}/`
const msalInstance = new PublicClientApplication({
  auth: {
    clientId: environment.auth.clientId,
    authority: `${authorityBase}/${environment.auth.policyNameMFA}`,
    knownAuthorities: [environment.auth.b2cDomainName],
    redirectUri: `${window.location.protocol}//${environment.auth.redirectDomain}${PORT}/${environment.auth.redirectAuthPage}`,
    postLogoutRedirectUri: `${window.location.protocol}//${environment.auth.redirectDomain}${PORT}/${environment.auth.redirectAuthPage}`
  }
})
msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts()
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }
})

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest: RedirectRequest = {
  scopes: ['https://myepix.onmicrosoft.com/525b221b-2a03-4113-a313-badcc1726282/People.ReadWrite.All'],
  state: `${SUBDOMAIN_STATE_PREFIX}${SUBDOMAIN}`,
  domainHint: SUBDOMAIN
}

export const logoutRequest: EndSessionRequest = {
  state: `${SUBDOMAIN_STATE_PREFIX}${SUBDOMAIN}`
}

export const resetPasswordRequest = {
  ...loginRequest,
  authority: 'https://myepix.b2clogin.com/myepix.onmicrosoft.com/b2c_1_reset_password'
}

export interface IsAuthentictedProps {
  children: React.ReactNode
}

export const acquireToken = async () => {
  return msalInstance
    .acquireTokenSilent(loginRequest)
    .then(tokenResponse => {
      return tokenResponse
    })
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(loginRequest)
      }
      return null
    })
}

export function IsAuthenticted({ children }: IsAuthentictedProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const { data: mfaEnabled } = useGetMfaEnabledQuery()
  useEffect(() => {
    if (mfaEnabled?.mfaEnabledForTenant === false) {
      msalInstance.getConfiguration().auth.authority = `${authorityBase}/${environment.auth.policyName}`
    }
  }, [mfaEnabled?.mfaEnabledForTenant])

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_FAILURE || event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      msalInstance.loginRedirect()
    }
    const payload = event.payload as AuthenticationResult
    if (event.eventType === EventType.LOGIN_SUCCESS && payload) {
      msalInstance.setActiveAccount(payload.account)
    }
    if (payload && payload.accessToken) {
      setFetchHeader('Authorization', `Bearer ${payload.accessToken}`)
      setIsAuthenticated(true)
    }
  })

  useEffect(() => {
    if (msalInstance.getActiveAccount()) {
      acquireToken().then(token => {
        setFetchHeader('Authorization', `Bearer ${token?.accessToken}`)
        setIsAuthenticated(true)
      })
    }
  }, [])

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        loadingComponent={() => <EpixLoadingPage message="Checking authentication..." />}
        errorComponent={() => <EpixLoadingPage message="Reinitializing application..." />}
      >
        {isAuthenticated && children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

export default IsAuthenticted
