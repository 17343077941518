import { useTranslation } from 'react-i18next'
import { Grid, Box, Typography, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import { useGetGeneralAnalyticsQuery } from '@epix-web-apps/core'
import { WorkforceAnalytics, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { GeneralStatistics } from '../general-statistics'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import WorkIcon from '@mui/icons-material/Work'
import CakeIcon from '@mui/icons-material/Cake'

/* eslint-disable-next-line */
export interface AnalyticsPaperProps {}

export function AnalyticsOverview(props: AnalyticsPaperProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { icpFilters } = useGlobalPersistedStore()
  const { data: analytics } = useGetGeneralAnalyticsQuery({
    icps: icpFilters
  })

  return (
    <>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Typography sx={{ paddingX: 2, flex: '1 0 auto' }} variant="h4">
          {t('homepage.analystics')}
        </Typography>
        <Box sx={{ m: 1 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              navigate(WorkforceAnalytics.WORKFORCE_ANALYTICS)
            }}
          >
            <SearchIcon />
            {t('homepage.analytics.viewdetails')}
          </Button>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <Grid container spacing={2}>
          <GeneralStatistics
            image={<AccessibilityIcon />}
            name={t('statistics.headcount')}
            value={analytics?.generalAnalytics.headcount}
          />
          <GeneralStatistics
            image={<AccessibilityIcon />}
            name={t('statistics.fulltimeequivalents')}
            value={analytics?.generalAnalytics.fullTimeEquivalents}
          />
          <GeneralStatistics
            image={<WorkIcon />}
            name={t('statistics.seniority')}
            value={analytics?.generalAnalytics.seniority}
          />
          <GeneralStatistics
            image={<CakeIcon />}
            name={t('statistics.age')}
            value={analytics?.generalAnalytics.averageAge + 'y'}
          />
        </Grid>
      </Box>
    </>
  )
}

export default AnalyticsOverview
