import { Box, TableRow, TableCell, useTheme, IconButton } from '@mui/material'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { TypographyBold } from '@epix-web-apps/ui'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface HomePageTableRowProps {
  title: string
  subTitle: string
  navigateUrl: string
  check?: boolean
  disabled?: boolean
}

export const GreenIcon = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      width: '2rem',
      height: '2rem'
    }
  }
})

export const EmptyIcon = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.MuiSvgIcon-root': {
      color: theme.palette.text.primary,
      width: '2rem',
      height: '2rem'
    }
  }
})

export const DisabledIcon = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.MuiSvgIcon-root': {
      color: theme.palette.divider,
      width: '2rem',
      height: '2rem'
    }
  }
})

export function HomePageTableRow({
  title,
  subTitle,
  navigateUrl,
  check = false,
  disabled = false
}: HomePageTableRowProps) {
  const navigate = useNavigate()
  const theme = useTheme()

  const DisabledTableCell = styled(TableCell)(() => {
    return {
      color: theme.palette.divider
    }
  })

  return (
    <TableRow>
      <TableCell width={'10%'}>
        {check ? (
          <GreenIcon>
            <CheckCircleIcon />
          </GreenIcon>
        ) : disabled ? (
          <DisabledIcon>
            <RadioButtonUncheckedOutlinedIcon />
          </DisabledIcon>
        ) : (
          <EmptyIcon>
            <RadioButtonUncheckedOutlinedIcon />
          </EmptyIcon>
        )}
      </TableCell>
      {disabled ? (
        <DisabledTableCell>
          <TypographyBold>{title}</TypographyBold>
          {subTitle}
        </DisabledTableCell>
      ) : (
        <TableCell>
          <TypographyBold>{title}</TypographyBold>
          {subTitle}
        </TableCell>
      )}
      <TableCell sx={{ fontSize: '1rem' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <IconButton disabled={disabled} onClick={() => navigate(navigateUrl)}>
            <ArrowForwardIosOutlinedIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default HomePageTableRow
