import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import { CalendarContainer, Event } from '../../schedule-components/calendar-year/calendar-year'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Box, Paper, useTheme, Button, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import { useFlyIn } from '@epix-web-apps/ui'
import EditEmployerWorkSchedule from '../edit-employer-work-schedule'
import {
  ABSENCE_REQUEST_STATUS_TYPE,
  CalendarDayModel,
  FirstContrastThreshold,
  hexToRGB,
  PersonalCalendarDayViewModel,
  ToBackendFormatedDate,
  useGetCalendarForDateRangeQuery,
  useGetContractByIdQuery,
  useGetMeQuery,
  useGetWorkScheduleHistoriesQuery
} from '@epix-web-apps/core'
import AddEditCalendarEntry from '../add-edit-calendar-entry'
import { Children, useEffect, useState } from 'react'
import DropdownMenu from '../../dropdown-menu'
import OverviewCalendarDay from '../overview-calendar-day'
import ResetWorkSchedule from '../reset-work-schedule'
import ChangeWorkSchedule from '../change-work-schedule'
import { startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns'
import CalendarDistribution from '../calendar-distribution'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import Year from '../../schedule-components/calendar-year/year'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import { AddEditCalendarEntries } from '../add-edit-calendar-entries'

/* eslint-disable-next-line */
export interface ContractCalendarTabProps {
  contractId: string | undefined
}

interface ClickedEvent {
  start: Date
  alternateWorkScheduleDayId: any
  title: string | undefined
}

interface SlotEventData {
  start: Date
  end: Date
}

interface WorkScheduleData {
  id: string
  date: Date
  title: string
  dayEntries: any
  overwritten: boolean
  alternateWorkScheduleDayId: any
}

function getDateRange(date: Date) {
  const startDate = startOfMonth(date)
  const endDate = endOfMonth(date)
  return { startDate, endDate }
}

function getYearDateRange(date: Date) {
  const startDateYear = startOfYear(date)
  const endDateYear = endOfYear(date)
  return { startDateYear, endDateYear }
}

function isDateInRange(date: Date, startDate: Date, endDate: Date | null): boolean {
  const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  const startWithoutTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
  const endWithoutTime = endDate ? new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) : 0
  return (
    dateWithoutTime >= startWithoutTime &&
    (endWithoutTime === 0 ||
      dateWithoutTime <= endWithoutTime ||
      dateWithoutTime.getTime() === startWithoutTime.getTime())
  )
}

function checkDayInCurrentMonth(date: Date, dateStart: Date, dateEnd: Date) {
  return date.getTime() <= dateEnd.getTime() && date.getTime() >= dateStart.getTime()
}

export function ContractCalendarTab({ contractId }: ContractCalendarTabProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [workSchedule, setWorkSchedule] = useState<WorkScheduleData[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [view, setView] = useState<View | undefined>('month')
  const [clickedEvent, setClickedEvent] = useState<ClickedEvent>()
  const [events, setEvents] = useState<Event[]>([])
  const [yearEvents, setYearEvents] = useState<Event[]>([])
  const { openFlyIn } = useFlyIn()
  const { data: me } = useGetMeQuery()
  moment.locale(me?.me.locale.locale)
  const localizer = momentLocalizer(moment)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  localizer.formats.yearHeaderFormat = 'YYYY'

  const { data: contract } = useGetContractByIdQuery({
    contractId: contractId || ''
  })

  const [contractPeriod, setContractPeriod] = useState({
    start: new Date(new Date(contract?.contractById.startDate).toDateString()),
    end: contract?.contractById.endDate ? new Date(new Date(contract?.contractById.endDate).toDateString()) : null
  })

  const checkFuture = (startDate: Date): boolean => {
    return new Date(startDate) > new Date()
  }
  const checkHistory = (endDate: Date): boolean => {
    if (endDate === null) return false
    return new Date(endDate) < new Date()
  }

  const selectDate = (): Date => {
    if (contract?.contractById.currentPayPeriod) {
      return new Date(contract?.contractById.currentPayPeriod)
    }
    if (checkFuture(contract?.contractById.startDate)) {
      return new Date(contract?.contractById.startDate)
    }
    if (checkHistory(contract?.contractById.endDate)) {
      return new Date(contract?.contractById.endDate)
    }
    return new Date()
  }

  const [dateRange, setDateRange] = useState(getDateRange(selectDate()))
  const [dateYearRange, setDateYearRange] = useState(getYearDateRange(selectDate()))
  const [date, setDate] = useState(selectDate())

  const { data: calendarData, refetch: refetchCalendarData } = useGetCalendarForDateRangeQuery({
    contractId: contractId || '',
    startDate: dateRange.startDate,
    endDate: dateRange.endDate
  })

  const { data: calendarDataYear, refetch: refetchCalendarDataYear } = useGetCalendarForDateRangeQuery({
    contractId: contractId || '',
    startDate: dateYearRange.startDateYear,
    endDate: dateYearRange.endDateYear
  })

  useEffect(() => {
    setWorkSchedule(
      calendarData?.calendarDaysByContractId.map(item => {
        return {
          id: item.workScheduleDay?.workScheduleDayId || '',
          date: item.date,
          title: item.workScheduleDay?.labelOnCalendar || '',
          dayEntries: item.workScheduleDay?.dayDefinitionEntries,
          overwritten: item.workScheduleDay?.overwrittenByAlternate ?? false,
          alternateWorkScheduleDayId: item.workScheduleDay?.alternateWorkScheduleDayId || ''
        }
      }) || []
    )

    setEvents(
      calendarData?.calendarDaysByContractId.flatMap((item: any) => {
        return item.calculatedEntries.map((item2: any, index: any) => {
          return {
            id: index,
            start: item.date,
            end: item.date,
            title: item2.numberOfHours + ' ' + item2.payrollCodeUserFriendlyDescription ?? item2.payrollCodeDescription,
            colorCodeHex: item2.colourCodeHex,
            absenceRequestType: item2.absenceRequestCalendarModel?.absenceRequestType?.key
          }
        })
      }) || []
    )
  }, [calendarData])

  useEffect(() => {
    setYearEvents(
      calendarDataYear?.calendarDaysByContractId.flatMap((item: any) => {
        return item.calculatedEntries.map((item2: any, index: number) => {
          return {
            id: index,
            start: item.date,
            end: item.date,
            title: item2.numberOfHours + ' ' + item2.payrollCodeUserFriendlyDescription ?? item2.payrollCodeDescription,
            colorCodeHex: item2.colourCodeHex,
            absenceRequestType: item2.absenceRequestCalendarModel?.absenceRequestType?.key
          }
        })
      }) || []
    )
  }, [calendarDataYear])

  const { data: workScheduleHistories, refetch: refetchWorkScheduleHistories } = useGetWorkScheduleHistoriesQuery({
    contractId: contractId || ''
  })

  const checkIfOneHasComment = (schedule: CalendarDayModel | undefined): boolean => {
    if (!schedule) {
      return false
    }
    if (schedule.personalCalendarDays.length > 0) {
      return schedule.personalCalendarDays.some(pcd => !!pcd.comment)
    }
    return false
  }

  const checkIfOneHasDocument = (schedule: any): boolean | undefined => {
    if (schedule.personalCalendarDays) {
      return (
        schedule.personalCalendarDays.find((item: PersonalCalendarDayViewModel) => item.documents.length > 0) !==
        undefined
      )
    }
    return undefined
  }

  const eventWrapper = useMemo(
    () =>
      ({ children, value }: any) => {
        let date = new Date()

        const noWorkSchedule = workScheduleHistories?.workScheduleHistories.find(schedule => {
          return (
            isDateInRange(
              new Date(value),
              new Date(schedule.validFrom),
              schedule.validTo ? new Date(schedule.validTo) : null
            ) && isDateInRange(value, contractPeriod.start, contractPeriod.end)
          )
        })
        const matching = workSchedule.find(schedule => {
          date = new Date(schedule?.date)
          return (
            ToBackendFormatedDate(new Date(value)) === ToBackendFormatedDate(new Date(date)) &&
            (schedule?.dayEntries?.length > 0 || schedule.overwritten)
          )
        })
        const checkInCurrentMonth = checkDayInCurrentMonth(new Date(value), dateRange.startDate, dateRange.endDate)
        const hasComment = calendarData?.calendarDaysByContractId.find(schedule => {
          date = new Date(schedule?.date)
          return (
            ToBackendFormatedDate(new Date(value)) === ToBackendFormatedDate(new Date(date)) &&
            checkIfOneHasComment(schedule as CalendarDayModel)
          )
        })
        const injuryDate = calendarData?.calendarDaysByContractId.find(schedule => {
          date = new Date(schedule?.date)
          return (
            ToBackendFormatedDate(new Date(value)) === ToBackendFormatedDate(new Date(date)) && schedule.isInjuryDate
          )
        })
        const isInjured = calendarData?.calendarDaysByContractId.find(schedule => {
          date = new Date(schedule?.date)
          return ToBackendFormatedDate(new Date(value)) === ToBackendFormatedDate(new Date(date)) && schedule.isInjured
        })
        const hasDocument = calendarData?.calendarDaysByContractId.find(schedule => {
          date = new Date(schedule?.date)
          return (
            ToBackendFormatedDate(new Date(value)) === ToBackendFormatedDate(new Date(date)) &&
            checkIfOneHasDocument(schedule)
          )
        })
        const allComponents = (
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              left: 0
            }}
          >
            {((injuryDate && isInjured) || (injuryDate && isInjured)) && (
              <Box
                sx={{
                  textDecoration: 'underline',
                  color: theme.palette.warning.dark,
                  fontSize: '0.5em',
                  paddingRight: 1
                }}
              >
                <LocalHospitalOutlinedIcon fontSize="small" />
              </Box>
            )}
            {!injuryDate && isInjured && (
              <Box
                sx={{
                  textDecoration: 'underline',
                  color: theme.palette.primary.main,
                  fontSize: '0.5em',
                  paddingRight: 1
                }}
              >
                <LocalHospitalOutlinedIcon fontSize="small" />
              </Box>
            )}
            {hasDocument && (
              <Box
                sx={{
                  textDecoration: 'underline',
                  color: theme.palette.text.secondary,
                  fontSize: '0.5em',
                  paddingRight: 1
                }}
              >
                <AttachFileOutlinedIcon fontSize="small" />
              </Box>
            )}
            {hasComment && (
              <Box
                sx={{
                  textDecoration: 'underline',
                  color: theme.palette.text.secondary,
                  fontSize: '0.5em',
                  paddingRight: 1
                }}
              >
                <CommentOutlinedIcon fontSize="small" />
              </Box>
            )}
          </Box>
        )

        return React.cloneElement(
          Children.only(children),
          {
            style: {
              position: 'relative',
              gridRowStart: 1,
              backgroundColor: !noWorkSchedule ? 'lightgrey' : !checkInCurrentMonth ?? children.backgroundColor,
              gridColumnStart: 1
            }
          },
          noWorkSchedule && checkInCurrentMonth && (
            <Button
              onClick={event => {
                setAnchorEl(event.currentTarget)
                setClickedEvent({
                  start: value,
                  alternateWorkScheduleDayId: matching?.alternateWorkScheduleDayId,
                  title: matching?.title
                })
              }}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: theme.zIndex.drawer - 10,
                height: '100%',
                width: '100%',
                fontSize: '0.7em'
              }}
            ></Button>
          ),
          matching && (
            <Box
              sx={{
                textDecoration: 'underline',
                color: theme.palette.text.secondary,
                position: 'absolute',
                bottom: 0,
                right: 0,
                fontSize: '0.7em',
                paddingRight: 1
              }}
            >
              <p>{matching.title}</p>
            </Box>
          ),
          allComponents
        )
      },
    [calendarData, workSchedule, dateRange]
  )

  const eventStyleGetter = (event: any) => {
    const textColor = FirstContrastThreshold(event.colorCodeHex, 7, ['#FFFFFF', '#000000'])
    const backgroundColor = event.colorCodeHex
    const isPendingAbsenceRequest = event.absenceRequestType === ABSENCE_REQUEST_STATUS_TYPE.REQUESTED

    const rgb = hexToRGB(backgroundColor)

    const backgroundOpacity = isPendingAbsenceRequest
      ? `repeating-linear-gradient(45deg, rgba(${rgb.r},${rgb.g},${rgb.b},0.5), rgba(${rgb.r},${rgb.g},${rgb.b}, 0.3) 2px, rgba(255,255,255) 2px, rgba(255,255,255) 3px)`
      : backgroundColor

    const style = {
      backgroundColor: backgroundColor,
      background: backgroundOpacity,
      borderRadius: '2px',
      opacity: 0.8,
      fontSize: '0.9em',
      color: isPendingAbsenceRequest ? theme.palette.text.primary : textColor,
      border: '0px',
      display: 'block'
    }

    return {
      style: style
    }
  }

  function handleNavigate(date: Date) {
    const { startDate, endDate } = getDateRange(date)
    const { startDateYear, endDateYear } = getYearDateRange(date)
    setDate(date)
    setDateRange({ startDate, endDate })
    setDateYearRange({ startDateYear, endDateYear })
  }

  const handleSelectSlot = ({ start, end }: SlotEventData) => {
    const end2 = new Date(end.setDate(end.getDate() - 1))
    if (
      start.toLocaleDateString() !== end2.toLocaleDateString() &&
      isDateInRange(start, contractPeriod.start, contractPeriod.end)
    )
      openFlyIn({
        content: (
          <AddEditCalendarEntries
            contractId={contractId}
            start={new Date(start) || new Date()}
            end={end2 || new Date()}
          />
        ),
        callbackAfterClose: () => () => {
          refetchCalendarData()
          refetchCalendarDataYear()
        }
      })
  }

  const { views } = useMemo(() => {
    Year.handleClick = (date: Date) => {
      setView('month')
      setDate(date)
      setDateRange(getDateRange(new Date(date)))
    }
    Year.isPersonal = true
    Year.personalDays = yearEvents
    return {
      views: {
        month: true,
        agenda: Year
      }
    }
  }, [yearEvents])

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        border: 'none'
      }}
    >
      <Box
        sx={{
          textAlign: 'right',
          paddingBottom: 0,
          marginBottom: 2
        }}
      >
        <Button
          sx={{ marginRight: 1 }}
          variant="outlined"
          onClick={() =>
            openFlyIn({
              content: (
                <CalendarDistribution
                  contractId={contractId}
                  dateRange={dateRange}
                  dateYearRange={{
                    startDate: dateYearRange.startDateYear,
                    endDate: dateYearRange.endDateYear
                  }}
                  view={view}
                />
              )
            })
          }
        >
          <StackedBarChartIcon fontSize="small" />
          {t('employercalendarpage.button.viewcounters')}
        </Button>
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <EditEmployerWorkSchedule contractId={contractId} />,
              callbackAfterClose: () => () => {
                refetchWorkScheduleHistories()
                refetchCalendarData()
                refetchCalendarDataYear()
              }
            })
          }
        >
          <EditIcon fontSize="small" />
          {t('employercalendarpage.button.editworkschedule')}
        </Button>
      </Box>

      <Box
        style={{
          display: 'flex',
          flexGrow: 1,
          maxHeight: '100%',
          paddingBottom: 2
        }}
      >
        <Paper sx={{ flexGrow: 1, minHeight: '800px', border: 'none' }}>
          <CalendarContainer>
            <Calendar
              localizer={localizer}
              events={events}
              date={date}
              messages={{ agenda: 'Year' }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              views={views}
              view={view}
              onView={setView}
              startAccessor="start"
              endAccessor="end"
              min={dateRange.startDate}
              max={dateRange.endDate}
              onNavigate={handleNavigate}
              eventPropGetter={eventStyleGetter}
              onSelectSlot={handleSelectSlot}
              components={{
                dateCellWrapper: eventWrapper
              }}
              selectable
            />
          </CalendarContainer>
          <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
            <MenuItem
              onClick={() =>
                openFlyIn({
                  content: (
                    <AddEditCalendarEntry
                      contractId={contractId}
                      start={clickedEvent?.start || new Date()}
                      end={clickedEvent?.start || new Date()}
                    />
                  ),
                  callbackAfterClose: () => () => {
                    refetchCalendarData()
                    refetchCalendarDataYear()
                  }
                })
              }
            >
              {t('contractsalarypage.list.row.menu.add-edit-calendar-entry')}
            </MenuItem>

            <MenuItem
              onClick={() =>
                openFlyIn({
                  content: <OverviewCalendarDay contractId={contractId} start={clickedEvent?.start || new Date()} />
                })
              }
            >
              {t('contractsalarypage.list.row.menu.overview-calendar-day')}
            </MenuItem>

            <MenuItem
              onClick={() =>
                openFlyIn({
                  content: (
                    <ChangeWorkSchedule
                      contractId={contractId}
                      start={clickedEvent?.start || new Date()}
                      alternateWorkScheduleDayId={clickedEvent?.alternateWorkScheduleDayId}
                    />
                  ),
                  callbackAfterClose: () => () => {
                    refetchCalendarData()
                    refetchCalendarDataYear()
                  }
                })
              }
            >
              {t('contractsalarypage.list.row.menu.change-work-schedule')}
            </MenuItem>

            {clickedEvent?.alternateWorkScheduleDayId && (
              <MenuItem
                onClick={() =>
                  openFlyIn({
                    content: (
                      <ResetWorkSchedule
                        start={clickedEvent?.start || new Date()}
                        alternateWorkScheduleDayId={clickedEvent?.alternateWorkScheduleDayId}
                        alternateWorkScheduleDayDescription={clickedEvent?.title}
                      />
                    ),
                    callbackAfterClose: () => () => {
                      refetchCalendarData()
                      refetchCalendarDataYear()
                    }
                  })
                }
              >
                {t('contractsalarypage.list.row.menu.reset-work-schedule-to-default')}
              </MenuItem>
            )}
          </DropdownMenu>
        </Paper>
      </Box>
    </Paper>
  )
}

export default ContractCalendarTab
