import { Box, Grid, Typography, useTheme, Table, TableBody } from '@mui/material'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import HomePageTableRow from '../../components/onboarding-components/home-page-table-row/home-page-table-row'
import {
  OrderDirection,
  useGetAllEmployersByIcpIdsQuery,
  useGetAllIcpsQuery,
  useGetAllPayGroupsQuery,
  useGetAllPayrollCodesQuery,
  useGetAllPersonsQuery,
  useGetAllTeamsQuery,
  useGetAllUsersQuery,
  useGetAllWorkSchedulesQuery
} from '@epix-web-apps/core'
import { Configuration, People } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface HomePageOnboardingProps {}

export const HeaderRow = styled(Box)(() => {
  const theme = useTheme()
  return {
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      width: '2rem',
      height: '2rem'
    }
  }
})

export function HomePageOnboarding(props: HomePageOnboardingProps) {
  const { t } = useTranslation()

  const { data: icps } = useGetAllIcpsQuery({
    offset: 0,
    limit: 0,
    orderDirection: OrderDirection.Asc
  })

  const { data: employers } = useGetAllEmployersByIcpIdsQuery({
    icps: null
  })

  const { data: payGroups } = useGetAllPayGroupsQuery({})

  const { data: teams } = useGetAllTeamsQuery()

  const { data: payrollCodes } = useGetAllPayrollCodesQuery({
    offset: 0,
    limit: 0,
    orderDirection: OrderDirection.Asc
  })

  const { data: workSchedules } = useGetAllWorkSchedulesQuery({
    offset: 0,
    limit: 0,
    orderDirection: OrderDirection.Asc
  })

  const { data: people } = useGetAllPersonsQuery({
    offset: 0,
    limit: 0,
    selectedDate: new Date(0),
    showInactive: false,
    showWithoutContract: false,
    sortByProperty: 'lastName',
    orderDirection: OrderDirection.Asc
  })

  const { data: usersWithPayBixAccount } = useGetAllUsersQuery({
    offset: 0,
    limit: 2,
    emailExtension: '@paybix.eu'
  })

  const { data: users } = useGetAllUsersQuery({
    offset: 0,
    limit: 2
  })

  const getIds = (arr: any) => {
    return arr?.map((item: any) => item.id)
  }

  const { data: allTeams } = useGetAllTeamsQuery({})

  const rootNodeId = allTeams?.allTeams ? getIds(allTeams.allTeams)[0] : ''

  return (
    <Box sx={{ p: 3 }}>
      <Grid container>
        <Grid p={0.5} item xs={0.8}>
          <HeaderRow>
            <ConstructionOutlinedIcon />
          </HeaderRow>
        </Grid>
        <Grid item xs={11.2}>
          <Typography m={0} variant="h2">
            {t('homepage-onboarding.title')}
          </Typography>
          <Typography paddingBottom={4} m={0}>
            {t('homepage-onboarding.subtitle')}
          </Typography>
          <Grid container>
            <Grid item xs={3}>
              <Typography m={0} variant="h4">
                {t('homepage-onboarding.configuationsetup')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Table
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '30px',
                  mb: 1
                }}
              >
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addprovider.title')}
                    subTitle={t('homepage.onboarding.addprovider.subtitle')}
                    check={icps?.icps && icps.icps.totalCount > 0}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                  />
                </TableBody>
              </Table>
              {icps?.icps && icps.icps.totalCount === 0 && (
                <Box mt={2}>
                  <Typography>{t('homepage-onboarding.prerequisites.icp')}</Typography>
                </Box>
              )}
              <Table
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '30px',
                  mb: 1
                }}
              >
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addemployer.title')}
                    subTitle={t('homepage.onboarding.addemployer.subtitle')}
                    check={employers?.employersByIcpIds && employers.employersByIcpIds.length > 0}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={icps?.icps && icps.icps.totalCount === 0}
                  />
                </TableBody>
              </Table>
              {employers?.employersByIcpIds && employers.employersByIcpIds.length === 0 && (
                <Box mt={2}>
                  <Typography>{t('homepage-onboarding.prerequisites.employer')}</Typography>
                </Box>
              )}
              <Table
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '30px',
                  mb: 1
                }}
              >
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addpaygroup.title')}
                    subTitle={t('homepage.onboarding.addpaygroup.subtitle')}
                    check={payGroups?.allPayGroups && payGroups.allPayGroups.length > 0}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={employers?.employersByIcpIds && employers.employersByIcpIds.length === 0}
                  />
                  <HomePageTableRow
                    title={t('homepage.onboarding.addpayrollcode.title')}
                    subTitle={t('homepage.onboarding.addpayrollcode.subtitle')}
                    check={payrollCodes?.allPayrollCodes && payrollCodes?.allPayrollCodes.totalCount > 0}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={employers?.employersByIcpIds && employers.employersByIcpIds.length === 0}
                  />
                  <HomePageTableRow
                    title={t('homepage.onboarding.addworkschedule.title')}
                    subTitle={t('homepage.onboarding.addworkschedule.subtitle')}
                    check={workSchedules?.workSchedules && workSchedules.workSchedules.totalCount > 0}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={employers?.employersByIcpIds && employers.employersByIcpIds.length === 0}
                  />
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={3}>
              <Typography m={0} variant="h4">
                {t('homepage-onboarding.teamandusers')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Table sx={{ border: '1px solid lightgrey', borderRadius: '30px' }}>
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addcolleague.title')}
                    subTitle={t('homepage.onboarding.addcolleague.subtitle')}
                    check={
                      users?.allUsers &&
                      usersWithPayBixAccount?.allUsers &&
                      users?.allUsers.data.length - usersWithPayBixAccount?.allUsers.data.length > 1
                    }
                    navigateUrl={Configuration.USERS()}
                  />
                  <HomePageTableRow
                    title={t('homepage.onboarding.addteam.title')}
                    subTitle={t('homepage.onboarding.addteam.subtitle')}
                    check={teams?.allTeams && teams.allTeams.length > 1}
                    navigateUrl={Configuration.TEAMS_ID(`${rootNodeId}`)}
                  />
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={3}>
              <Typography m={0} variant="h4">
                {t('homepage-onboarding.person')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Table sx={{ border: '1px solid lightgrey', borderRadius: '30px' }}>
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addpopulation.title')}
                    subTitle={t('homepage.onboarding.addpopulation.subtitle')}
                    check={people?.persons && people.persons.totalCount > 0}
                    navigateUrl={People.PEOPLE()}
                    disabled={
                      (icps?.icps && icps.icps.totalCount === 0) ||
                      (employers?.employersByIcpIds && employers.employersByIcpIds.length === 0)
                    }
                  />
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HomePageOnboarding
