import { useTranslation } from 'react-i18next'
import { StepParams } from './step-props'
import {
  ExportJobModel,
  GetExportJobsByJobIdsQuery,
  useCompleteProcessMutation,
  useGetExportJobsByJobIdsQuery,
  ProcessingStatusType,
  PROCESS_TYPE,
  useGetProcessByIdQuery,
  useGetAvailablePayrollClosureProcessContractsQuery,
  isProcessOfType,
  GetProcessByIdQuery
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Grid, LinearProgress, List, Typography, useTheme } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useEffect, useState } from 'react'
import { FinishPayrollClosureOverview } from '../payroll-closure/finish-payroll-closure-overview'
import FinishPaycomponentUpdateOverview from '../paycomponent-update/finish-paycomponent-update-overview/finish-paycomponent-update-overview'
import { useFormContext } from 'react-hook-form'
import ExportJobListItem from '../finish-step-components/export-job-download-link/export-job-download-link'

export type exportJobModelWithoutProcessingJobType = Omit<ExportJobModel, 'processingJobType'>

const allExportJobsFinished = (exportJobs: Array<exportJobModelWithoutProcessingJobType>) => {
  return exportJobs.every(job => job.statusType === ProcessingStatusType.Finished)
}

const someFinishedExportJobsHaveErrors = (exportJobs: exportJobModelWithoutProcessingJobType[] | undefined) => {
  if (!exportJobs) return false
  return allExportJobsFinished(exportJobs) && exportJobs.some(job => !job.success && job.errorMessages.length > 0)
}

const someFinishedExportJobsHaveWarnings = (exportJobs: Array<exportJobModelWithoutProcessingJobType>) => {
  return allExportJobsFinished(exportJobs) && exportJobs.some(job => !job.success && job.warningMessages.length > 0)
}

export interface FinishProps {
  processId: string
  processType: PROCESS_TYPE
}

export function Finish({ processId, processType }: FinishProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<StepParams>()
  const [exportJobIds, setExportJobIds] = useState<Array<string>>([])

  const {
    data: getProcess,
    refetch: refetchProcess,
    isFetching: isFetchingProcess
  } = useGetProcessByIdQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id,
      refetchInterval: processPolling,
      suspense: false
    }
  )

  function processPolling(data: GetProcessByIdQuery | undefined) {
    if (data && data.processById) {
      if (data.processById.completed && !data.processById.areExportJobsInitialized) {
        return 1000 * 2
      }

      if (data.processById.exportJobIds) {
        setExportJobIds(data.processById.exportJobIds)
      }
    }
    return false
  }

  const [contractsWithErrorsCount, setContractsWithErrorsCount] = useState(0)
  const { getValues } = useFormContext()
  const { data: getAvailableProcessContracts } = useGetAvailablePayrollClosureProcessContractsQuery(
    {
      id: processId,
      offset: 0,
      limit: -1
    },
    {
      enabled: isProcessOfType(PROCESS_TYPE.PAYROLL_CLOSURE, getProcess?.processById?.type.key)
    }
  )

  useEffect(() => {
    if (getAvailableProcessContracts && getAvailableProcessContracts.availablePayrollClosureProcessContracts) {
      const selectedContractIds = getValues('contractIds')
      const selectedContracts = getAvailableProcessContracts.availablePayrollClosureProcessContracts.data.filter(c =>
        selectedContractIds.includes(c.contractId)
      )
      const contractsWithErrors = selectedContracts.filter(c => c.validationErrorCount > 0)
      setContractsWithErrorsCount(contractsWithErrors.length)
    }
  }, [getAvailableProcessContracts])

  function exportJobPolling(data: GetExportJobsByJobIdsQuery | undefined) {
    if (data && data.allExportJobsByJobIds) {
      if (allExportJobsFinished(data.allExportJobsByJobIds)) {
        return false
      }
    }
    return 1000 * 5
  }

  const { data: getExportJobsByIds } = useGetExportJobsByJobIdsQuery(
    {
      jobIds: exportJobIds
    },
    {
      enabled: !!exportJobIds.length,
      refetchIntervalInBackground: true,
      refetchInterval: exportJobPolling,
      suspense: false
    }
  )

  const mutation = useCompleteProcessMutation()

  function handleCompleteProcess() {
    mutation
      .mutateAsync({
        completeProcessCommand: {
          processId: params?.id || ''
        }
      })
      .then(response => setExportJobIds(response.completeProcess))
      .finally(() => refetchProcess())
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={6}>
          {processType === PROCESS_TYPE.PAYROLL_CLOSURE && (
            <FinishPayrollClosureOverview contractsWithErrors={contractsWithErrorsCount} />
          )}
          {processType === PROCESS_TYPE.PAYCOMPONENT_UPDATE && <FinishPaycomponentUpdateOverview />}
          {!getProcess?.processById.completed && (
            <Box>
              <Button
                sx={{ mb: 2 }}
                variant="contained"
                onClick={handleCompleteProcess}
                disabled={mutation.isLoading || isFetchingProcess || contractsWithErrorsCount > 0}
              >
                {t('common.finish')}
                {mutation.isLoading && (
                  <LinearProgress
                    color="primary"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%',
                      opacity: 0.6
                    }}
                  />
                )}
              </Button>
              <Typography color={theme.palette.text.secondary} sx={{ display: 'flex', gap: 1, mb: 0.5 }}>
                <InfoOutlinedIcon /> {t('processpage.finishoverview.completeprocesswarning')}
              </Typography>
              <Typography color={theme.palette.text.secondary} sx={{ display: 'flex', gap: 1, mb: 0.5 }}>
                <InfoOutlinedIcon /> {t('processpage.finishoverview.downloadinfo')}
              </Typography>
            </Box>
          )}
          {getProcess?.processById.completed &&
            getProcess?.processById.areExportJobsInitialized &&
            someFinishedExportJobsHaveErrors(getExportJobsByIds?.allExportJobsByJobIds) && (
              <>
                <Typography color={theme.palette.error.light} variant="h6">
                  {t('processpage.finishoverview.notalljobsfinishedsuccessfully')}
                </Typography>
                <Button
                  sx={{ mb: 2 }}
                  variant="contained"
                  onClick={handleCompleteProcess}
                  disabled={
                    mutation.isLoading ||
                    (getProcess?.processById.completed && !getProcess?.processById.areExportJobsInitialized)
                  }
                >
                  {t('common.retry')}
                  {mutation.isLoading ||
                    (getProcess?.processById.completed && !getProcess?.processById.areExportJobsInitialized && (
                      <LinearProgress
                        color="primary"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          height: '100%',
                          opacity: 0.6,
                          borderRadius: 1
                        }}
                      />
                    ))}
                </Button>
              </>
            )}
          {getExportJobsByIds && someFinishedExportJobsHaveWarnings(getExportJobsByIds.allExportJobsByJobIds) && (
            <Typography color={theme.palette.warning.light} variant="h6">
              {t('processpage.finishoverview.somejobsfinishedwithwarnings')}
            </Typography>
          )}
        </Grid>
        <Grid item sm={12} lg={6}>
          {getProcess?.processById.completed && (
            <>
              <Typography variant="h3">{t('processpage.finishoverview.jobstates.title')}</Typography>
              {!getProcess?.processById.areExportJobsInitialized && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <CircularProgress color="inherit" size={30} />
                  <Typography>{t('processpage.finishoverview.jobstates.preparing')}</Typography>
                </Box>
              )}

              {getProcess?.processById.areExportJobsInitialized && exportJobIds.length > 0 && (
                <List>
                  {getExportJobsByIds?.allExportJobsByJobIds.map(
                    (exportJob: exportJobModelWithoutProcessingJobType) => (
                      <ExportJobListItem
                        key={exportJob.id}
                        exportJob={exportJob}
                        payrollProviderCode={getProcess.processById.payrollProviderCode}
                      />
                    )
                  )}
                </List>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default Finish
