import { createTheme, ThemeProvider, CssBaseline } from '@mui/material'
import { isOnlySelfServiceUser, isSelfServiceUser, loadTranslations, useGetMeQuery } from '@epix-web-apps/core'
import { NotMobileOptimized, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { environment } from '../environments/environment'
import { useMyEpixRouting } from './myepix-routing'
import { useSelfServiceRouting } from './selfservice-routing'
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom'
import IsAuthenticted from './myepix/components/is-authenticted/is-authenticted'
import { useGeneralRoutes } from './general-routing'
import { DrawerFlyinLayout, DrawerMobileLayout } from './myepix/layouts'
import { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundryHandler } from './myepix/components/error-boundry-handler'

const theme = createTheme({
  palette: {
    primary: {
      main: '#00044E',
      light: '#F5F5FF',
      contrastText: '#dee1e6'
    },
    secondary: {
      main: '#00C291',
      light: '#7fe1c8',
      contrastText: '#FFF'
    },
    background: {
      default: '#FFFFFF'
    },
    text: {
      primary: '#171A1F',
      secondary: '#878787'
    },
    success: {
      main: '#306D32',
      light: '#96be25'
    },
    error: {
      main: '#D32F2F',
      light: '#db5858',
      dark: '#8B0000',
      contrastText: '#FFF'
    }
  },
  shape: {
    borderRadius: 6
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
      color: '#00044E',
      margin: '1rem 0'
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      color: '#00044E',
      margin: '1rem 0'
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      color: '#00044E',
      margin: '1rem 0'
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 700,
      color: '#00044E',
      margin: '1rem 0'
    },
    h5: {
      fontSize: '1.2rem',
      color: '#00044E',
      margin: '0.75rem 0'
    },
    h6: {
      fontSize: '0.95rem',
      fontWeight: 700,
      color: '#00044E',
      margin: '0.5rem 0'
    }
  }
})

theme.components = {
  MuiLink: {
    styleOverrides: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: 4,
        '&:hover': {
          boxShadow: 'none'
        },
        gap: 8
      }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        border: 'none'
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        elevation: 0,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2
      }
    }
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        elevation: 0,
        boxShadow: 'none',
        border: 'none'
      }
    }
  },
  MuiCssBaseline: {
    styleOverrides: `
      .bold {
        font-weight: bold;
      },
      p {
        margin: 0.25rem 0;
      },
    `
  }
}

loadTranslations(environment.i18n)

// fadein and remove the initial loading html
const rootEl = document.getElementById('root')
let intervalID: NodeJS.Timer
function loadingApp() {
  const loading = document.getElementById('loadingpage')
  if (loading && rootEl) {
    let opacityLoadingPage = Number(window.getComputedStyle(loading).getPropertyValue('opacity'))
    if (opacityLoadingPage > 0) {
      opacityLoadingPage = opacityLoadingPage - 0.01
      loading.style.opacity = opacityLoadingPage.toString()
      rootEl.style.opacity = (1 - opacityLoadingPage).toString()
    } else {
      clearInterval(intervalID)
      loading?.remove()
    }
  }
}
if (rootEl) {
  rootEl.style.opacity = '0'
  intervalID = setInterval(loadingApp, 1)
}

const AppLayout = () => {
  const { selfServiceMode, setSelfServiceMode } = useGlobalPersistedStore()
  const { data: me } = useGetMeQuery()
  const navigate = useNavigate()
  const matchedSelfServiceRoute = window.location.href.match(/\/selfservice/)
  const isSelfServiceRouteAndHasAccess = isSelfServiceUser(me?.me.roles) && matchedSelfServiceRoute
  const [locale, setLocale] = useState<Locale>()

  useEffect(() => {
    if (!isSelfServiceUser(me?.me.roles) && matchedSelfServiceRoute) {
      navigate('/not-authorized', {})
    }
    if (isOnlySelfServiceUser(me?.me.roles)) {
      setSelfServiceMode(true)
    }

    const importLocaleFile = async () => {
      // Import only needed translations and fallback if not found.
      await import(`date-fns/locale/${me?.me.locale.locale.substring(0, 2)}/index.js`)
        .then(localeToSet => setLocale(localeToSet.default))
        .catch(async () => {
          await import(`date-fns/locale/${me?.me.locale.locale}/index.js`).then(localeToSet =>
            setLocale(localeToSet.default)
          )
        })
    }

    // If the locale has not yet been loaded.
    if (locale?.code !== me?.me.locale.locale.substring(0, 2)) {
      importLocaleFile()
    }
  }, [locale?.code, me?.me.locale.locale, me?.me.roles])

  let layout = <DrawerFlyinLayout />
  if (selfServiceMode || isSelfServiceRouteAndHasAccess) {
    layout = <DrawerMobileLayout />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {layout}
    </LocalizationProvider>
  )
}

const useAppRoutes = () => {
  const myEpixRouting = useMyEpixRouting()
  const selfServiceRouting = useSelfServiceRouting()
  const { selfServiceMode } = useGlobalPersistedStore()
  const matchedSelfServiceRoute = window.location.href.match(/\/selfservice/)

  if (selfServiceMode || matchedSelfServiceRoute) {
    return selfServiceRouting
  }
  return myEpixRouting
}

export function App() {
  const generalRoutes = useGeneralRoutes()
  const appRoutes = useAppRoutes()
  const { selfServiceMode, setSelfServiceMode } = useGlobalPersistedStore()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorBoundryHandler}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <IsAuthenticted>
                  <AppLayout />
                  {!selfServiceMode && <NotMobileOptimized setSelfServiceMode={setSelfServiceMode} />}
                </IsAuthenticted>
              }
            >
              {appRoutes}
            </Route>
            {generalRoutes}
          </Routes>
        </Router>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
