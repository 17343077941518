import EmployersWidgetTitle from '../employers-widget-title'
import { Box, IconButton, Link, useTheme } from '@mui/material'
import { ReadMoreText, TypographySmall, WidgetRowItem } from '../employer-overview/employer-overview'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import AddEditCollectiveSchedule from '../../schedule-components/add-edit-collective-schedule'
import {
  OrderDirection,
  useGetAllDayDefinitionsByEmployerIdQuery,
  useGetAllPayrollCodesByEmployerIdQuery,
  useGetCollectiveSchedulesByEmployerIdQuery
} from '@epix-web-apps/core'
import { environment } from '../../../../../environments/environment'
import { useEffect } from 'react'

/* eslint-disable-next-line */
export interface EmployersWidgetCollectivecalendarsProps {
  employerId: string
  setTabComplete: (newValue: boolean) => void
}

export function EmployersWidgetCollectivecalendars({
  employerId,
  setTabComplete
}: EmployersWidgetCollectivecalendarsProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<DetailPageBaseQueryParams>()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()

  const { data: payrollCodes } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  const { data: collectiveSchedules, refetch: refetechCollectiveSchedules } =
    useGetCollectiveSchedulesByEmployerIdQuery({
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      orderDirection: OrderDirection.Asc
    })

  const { data: dayDefinitions } = useGetAllDayDefinitionsByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (collectiveSchedules?.collectiveSchedules) {
      setTabComplete(collectiveSchedules?.collectiveSchedules.data.length > 0)
    }
  }, [collectiveSchedules?.collectiveSchedules])

  const disabled = !payrollCodes?.allPayrollCodesByEmployerId.data.some(x => x.isCalendarEntry)

  return (
    payrollCodes?.allPayrollCodesByEmployerId &&
    collectiveSchedules?.collectiveSchedules &&
    dayDefinitions?.dayDefinitions && (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.collectivecalendars.title')}
          url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR(`${params.id}`, `${employerId}`)}
          icon={<EventRepeatOutlinedIcon />}
          hasData={collectiveSchedules.collectiveSchedules.data.length > 0}
          disabled={disabled}
        />

        {disabled && (
          <TypographySmall
            sx={{
              pb: '0.25rem',
              color: disabled ? theme.palette.text.secondary : ''
            }}
          >
            {t('employerspage.widget.collectivecalendars.nocollectivecalendars')}
          </TypographySmall>
        )}

        {collectiveSchedules?.collectiveSchedules.data.slice(0, 3).map(collectiveSchedule => (
          <WidgetRowItem key={collectiveSchedule.id}>
            <TypographySmall>
              {collectiveSchedule.description} ({collectiveSchedule.code})
            </TypographySmall>
            <Box>
              <IconButton
                sx={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() =>
                  openFlyIn({
                    content: (
                      <AddEditCollectiveSchedule employerId={employerId} collectiveScheduleId={collectiveSchedule.id} />
                    )
                  })
                }
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                sx={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() =>
                  navigate(
                    Configuration.ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR_ID(
                      `${params.id}`,
                      employerId,
                      collectiveSchedule.id
                    )
                  )
                }
              >
                <KeyboardArrowRightOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
          </WidgetRowItem>
        ))}

        <Box>
          {collectiveSchedules?.collectiveSchedules.data.length > 3 && (
            <Link
              onClick={() =>
                navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR(`${params.id}`, employerId))
              }
            >
              <TypographySmall>
                {t('employerspage.widget.collectivecalendars.and')} {dayDefinitions.dayDefinitions.data.length - 3}{' '}
                {t('employerspage.widget.collectivecalendars.more')}
              </TypographySmall>
            </Link>
          )}
          {collectiveSchedules?.collectiveSchedules.data.length < 4 && !disabled && (
            <Link
              onClick={() =>
                openFlyIn({
                  content: <AddEditCollectiveSchedule employerId={employerId} />,
                  callbackAfterClose: () => refetechCollectiveSchedules
                })
              }
            >
              <TypographySmall>{t('employerspage.widget.collectivecalendars.addcollectivecalendar')}</TypographySmall>
            </Link>
          )}
        </Box>

        <ReadMoreText
          text={
            collectiveSchedules?.collectiveSchedules.data.length === 0
              ? t('employerspage.widget.collectivecalendars.info')
              : ''
          }
          readMoreLink={
            environment.docs.domainName + '/Epix/epix-configuration/provider/employer/calendar/collectivecalendar'
          }
          alignRight={collectiveSchedules?.collectiveSchedules.data.length > 0}
        />
      </>
    )
  )
}

export default EmployersWidgetCollectivecalendars
