import { Box, Link } from '@mui/material'
import EmployersWidgetTitle from '../employers-widget-title'
import { useTranslation, Trans } from 'react-i18next'
import { ReadMoreText, TypographySmall } from '../employer-overview/employer-overview'
import { useNavigate, useParams } from 'react-router-dom'
import { OrderDirection, useGetAllPayrollCodesByEmployerIdQuery } from '@epix-web-apps/core'
import { environment } from '../../../../../environments/environment'
import { useEffect } from 'react'
import { Configuration, Import } from '@epix-web-apps/ui'
import { CompanyCarDetailPageParams } from '../../../pages/company-car-detail-page/company-car-detail-page'

/* eslint-disable-next-line */
export interface EmployersWidgetPayrollcodesProps {
  employerId: string
  setTabComplete: (newValue: boolean) => void
}

export function EmployersWidgetPayrollcodes({ employerId, setTabComplete }: EmployersWidgetPayrollcodesProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<CompanyCarDetailPageParams>()

  const { data: payrollCodes } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (payrollCodes?.allPayrollCodesByEmployerId) {
      setTabComplete(payrollCodes?.allPayrollCodesByEmployerId.data.length > 0)
    }
  }, [payrollCodes?.allPayrollCodesByEmployerId])

  return (
    payrollCodes?.allPayrollCodesByEmployerId.data && (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.payrollcodes.title')}
          url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_PAYROLLCODES(`${params.id}`, `${employerId}`)}
          hasData={payrollCodes?.allPayrollCodesByEmployerId.data.length > 0}
        />

        {payrollCodes && payrollCodes?.allPayrollCodesByEmployerId.data.length > 0 ? (
          <TypographySmall>
            {payrollCodes?.allPayrollCodesByEmployerId.data.length}{' '}
            {t('employerspage.widget.payrollcodes.payrollcodesfound')}
          </TypographySmall>
        ) : (
          <Box>
            <TypographySmall sx={{ pb: '0.25rem' }}>
              <Trans
                i18nKey="employerspage.widget.payrollcodes.importaddpayrollcodes"
                values={{
                  linktoimport: t('employerspage.widget.payrollcodes.importlink'),
                  linktoadd: t('employerspage.widget.payrollcodes.addlink')
                }}
                components={{
                  tt: <Link underline="always" onClick={() => navigate(Import.IMPORT)} />,
                  ttt: (
                    <Link
                      underline="always"
                      onClick={() =>
                        navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_PAYROLLCODES(`${params.id}`, employerId))
                      }
                    />
                  )
                }}
              />
            </TypographySmall>
          </Box>
        )}

        <ReadMoreText
          text={
            payrollCodes?.allPayrollCodesByEmployerId.data.length === 0 ? (
              <Trans
                i18nKey="employerspage.widget.payrollcodes.info"
                values={{
                  here: t('employerspage.widget.payrollcodes.here'),
                  readmorelink: t('employerspage.widget.readmore')
                }}
                components={{
                  tt: (
                    <Link
                      href={
                        environment.docs.domainName +
                        '/Import/import-csv/import-csv-payrollcode#minimalistic-list-to-get-started'
                      }
                      target="_blank"
                    />
                  )
                }}
              />
            ) : (
              ''
            )
          }
          readMoreLink={environment.docs.domainName + '/Import/import-csv/import-csv-payrollcode'}
          alignRight={payrollCodes?.allPayrollCodesByEmployerId.data.length > 0}
        />
      </>
    )
  )
}

export default EmployersWidgetPayrollcodes
