import { environment } from '../../../../environments/environment'
import { PORT, SUBDOMAIN_STATE_PREFIX } from '../../components/is-authenticted/is-authenticted'
import { EpixLoadingPage } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AuthRedirectPageProps {}

export function AuthRedirectPage(props: AuthRedirectPageProps) {
  const urlParams = new URLSearchParams(window.location.search)
  const stateQueryParam = urlParams.get('state')

  const subdomain =
    window.location.hash.split(SUBDOMAIN_STATE_PREFIX)?.[1]?.split('&')?.[0] ||
    stateQueryParam?.replace(SUBDOMAIN_STATE_PREFIX, '') ||
    ''
  let redirectUrl = `${window.location.protocol}//`
  if (subdomain !== '') {
    redirectUrl += `${subdomain}.${environment.auth.redirectDomain}${PORT}${window.location.hash}`
  } else {
    redirectUrl += `${environment.auth.redirectDomain}${PORT}${window.location.hash}`
  }
  window.location.replace(redirectUrl)

  return <EpixLoadingPage message="Redirecting to your epix environment..." />
}

export default AuthRedirectPage
