import {
  DriversEndingSoonModel,
  EVENT_ANNIVERSARY,
  EVENT_BIRTHDAY,
  EVENT_CAR,
  EVENT_LEAVERS,
  EVENT_PROBATION,
  EVENT_STARTERS,
  LocaleModel,
  UpcomingAnniversariesModel,
  UpcomingBirthdaysModel,
  UpcomingEndProbationPeriodModel,
  UpcomingLeaversModel,
  UpcomingStartersModel,
  FormatDateToDDMM
} from '@epix-web-apps/core'
import { Avatar, Box, useTheme } from '@mui/material'
import { useGlobalStore } from '@epix-web-apps/ui'
import { EpixAvatarInfoBlock, useFlyIn } from '@epix-web-apps/ui'
import OverviewEventStarters from '../overview-event-starters/overview-event-starters'
import OverviewEventLeavers from '../overview-event-leavers/overview-event-leavers'
import OverviewEventProbation from '../overview-event-probation/overview-event-probation'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { OverviewEventBirthdays } from '../overview-event-birthdays'
import { OverviewEventAnniversaries } from '../overview-event-anniversaries'
import { OverviewDriversEndingSoon } from '../drivers-soon-ending-component'

/* eslint-disable-next-line */
export interface HomepageEventWidgetProps {
  locale?: LocaleModel
  data?:
    | Array<UpcomingStartersModel>
    | Array<UpcomingLeaversModel>
    | Array<UpcomingEndProbationPeriodModel>
    | Array<UpcomingAnniversariesModel>
    | Array<UpcomingBirthdaysModel>
    | Array<UpcomingEndProbationPeriodModel>
    | Array<DriversEndingSoonModel>
  length: number
  dataType: string
  onClick: (...args: string[]) => void
}

export function HomepageEventWidget({ data, length, dataType, onClick }: HomepageEventWidgetProps) {
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()

  const calculateAll = () => {
    return length ? `+${length - 3}` : 0
  }

  function showFlyin() {
    if (dataType === EVENT_STARTERS)
      openFlyIn({
        content: <OverviewEventStarters />
      })
    if (dataType === EVENT_LEAVERS)
      openFlyIn({
        content: <OverviewEventLeavers />
      })
    if (dataType === EVENT_PROBATION)
      openFlyIn({
        content: <OverviewEventProbation />
      })
    if (dataType === EVENT_BIRTHDAY)
      openFlyIn({
        content: <OverviewEventBirthdays />
      })
    if (dataType === EVENT_ANNIVERSARY)
      openFlyIn({
        content: <OverviewEventAnniversaries />
      })
    if (dataType === EVENT_CAR)
      openFlyIn({
        content: <OverviewDriversEndingSoon data={data as Array<DriversEndingSoonModel>} />
      })
  }

  function showSubText(item: any) {
    const date = new Date(item.upcomingDate)
    if (dataType === EVENT_STARTERS) return `(${item.icpCode} ${FormatDateToDDMM(date)})`
    if (dataType === EVENT_LEAVERS) return `(${item.icpCode} ${FormatDateToDDMM(date)})`
    if (dataType === EVENT_PROBATION) return `(${item.icpCode})`
    if (dataType === EVENT_BIRTHDAY) return `(${item.age}y ${FormatDateToDDMM(date)})`
    if (dataType === EVENT_ANNIVERSARY) return `(${item.yearsOfService}y ${FormatDateToDDMM(date)})`
    if (dataType === EVENT_CAR) {
      return `(${FormatDateToDDMM(date)})`
    } else return ''
  }

  return (
    <Box sx={{ display: 'flex', padding: 1, justifyContent: 'space-around' }}>
      {data?.map((item, index) => {
        return (
          <Fragment key={`${item.personId}-${index}-${dataType}`}>
            {(index < 3 || (index === 3 && length === 4)) && (
              <EpixAvatarInfoBlock
                linkText={`${item.firstName} ${item.lastName.substring(0, 1) + '.'}`}
                subText={
                  `${new Date(item.upcomingDate).toLocaleDateString(me?.locale.locale, {
                    day: 'numeric',
                    month: 'numeric'
                  })}` && showSubText(item)
                }
                onClick={() => onClick(item.personId, (item as DriversEndingSoonModel).contractId)}
                personId={item.personId}
              />
            )}
            {index === 3 && length > 4 && (
              <EpixAvatarInfoBlock
                sx={{ backgroundColor: theme.palette.secondary.main }}
                linkText={t('common.viewall')}
                onClick={showFlyin}
              >
                {calculateAll()}
              </EpixAvatarInfoBlock>
            )}
          </Fragment>
        )
      })}
      {length < 4 &&
        [...Array(4 - length)].map((_, index) => (
          <Avatar key={index} sx={{ width: '3rem', height: '3rem', backgroundColor: 'white' }} />
        ))}
    </Box>
  )
}

export default HomepageEventWidget
