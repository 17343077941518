import { Box, SxProps, Theme } from '@mui/material'
import { FormEvent, FormEventHandler, ReactNode } from 'react'
import { FormProvider } from 'react-hook-form'

export interface FormContainerProps {
  form: any
  sx?: SxProps<Theme> | undefined
  onSubmit: FormEventHandler<HTMLFormElement>
  children?: ReactNode
}

export const FormContainer = ({ form, sx = { width: '25rem' }, onSubmit, children }: FormContainerProps) => {
  return (
    <FormProvider {...form}>
      <Box
        component="form"
        sx={sx}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          onSubmit(e)
        }}
      >
        {children}
      </Box>
    </FormProvider>
  )
}

export default FormContainer
