import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, IconButton, Typography, Divider, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface HeaderTitleNavigationProps {
  backToLink?: string
  title?: string
  showDivider?: boolean
  mobileSizeTitle?: boolean
}

export function HeaderTitleNavigation({
  title,
  backToLink,
  showDivider = true,
  mobileSizeTitle = false
}: HeaderTitleNavigationProps) {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '-0.5rem' }}>
        <IconButton
          sx={{ mr: 1, color: theme.palette.primary.main }}
          onClick={() => (backToLink ? navigate(backToLink) : navigate(-1))}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography m={1} variant={mobileSizeTitle ? 'h4' : 'h2'}>
          {title}
        </Typography>
      </Box>
      {showDivider && <Divider sx={{ mt: 0.5 }} />}
    </>
  )
}

export default HeaderTitleNavigation
