import { OrderDirection, useGetAllPersonsQuery } from '@epix-web-apps/core'
import HomePageOnboarding from '../home-page-onboarding/home-page-onboarding'
import HomePageWidgets from '../home-page-widgets'
import { usePeopleFilterStore } from '../../stores/people-page-filter-store'

/* eslint-disable-next-line */
export interface HomePageProps {}

export function HomePage(props: HomePageProps) {
  const { selectedDate } = usePeopleFilterStore()

  const { data } = useGetAllPersonsQuery(
    {
      offset: 0,
      limit: 0,
      selectedDate: selectedDate,
      showInactive: false,
      showWithoutContract: false,
      sortByProperty: 'lastName',
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: true
    }
  )

  return data?.persons && data?.persons.totalCount > 0 ? <HomePageWidgets /> : <HomePageOnboarding />
}

export default HomePage
