import { Paper, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import {
  DataGrid,
  GridSortModel,
  GridColumns,
  GridCallbackDetails,
  GridRowsProp,
  MuiEvent,
  GridRowParams,
  GridColumnVisibilityModel,
  GridInputSelectionModel,
  GridSelectionModel
} from '@mui/x-data-grid'
import { OrderDirection } from '@epix-web-apps/core'
import DataTableEmpty from './data-table-empty/data-table-empty'

export interface ISortModel {
  field: string
  orderDirection: OrderDirection
}

export class SortModel implements ISortModel {
  field: string
  orderDirection: OrderDirection

  constructor(field: string, orderDirection: OrderDirection) {
    this.field = field
    this.orderDirection = orderDirection
  }
}

/* eslint-disable-next-line */
export interface DataTableProps {
  data: GridRowsProp
  totalRowCount?: number
  columns: GridColumns
  defaultSortModel: ISortModel
  density?: 'standard' | 'compact' | 'comfortable'
  autoHeight?: boolean
  onSortChange?: ((model: SortModel) => void) | undefined
  onPageChange?: ((page: number, details: GridCallbackDetails<any>) => void) | undefined
  onPageSizeChange?: ((pageSize: number, details: GridCallbackDetails<any>) => void) | undefined
  onRowClick?: ((params: GridRowParams) => void) | undefined
  page?: number
  pageSize?: number
  isLoading: boolean
  checkboxSelection?: boolean
  onSelectionModelChange?: ((selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void) | undefined
  selectionModel?: GridInputSelectionModel | undefined
  filterBarElement?: React.ReactNode | null
  hideFooter?: boolean
  columnsToHide?: GridColumnVisibilityModel | undefined
  emptyStateElement?: React.ReactNode
  isRowSelectable?: (params: GridRowParams) => boolean
  disableRowSelectionOnClick?: boolean
}

export function DataTable({
  data,
  columns,
  totalRowCount,
  defaultSortModel,
  density = 'standard',
  autoHeight = false,
  onSortChange,
  onPageChange,
  onPageSizeChange,
  onRowClick,
  page = 0,
  pageSize = totalRowCount ? 25 : 100,
  isLoading,
  checkboxSelection = false,
  onSelectionModelChange,
  selectionModel,
  filterBarElement,
  hideFooter = false,
  columnsToHide,
  emptyStateElement,
  isRowSelectable,
  disableRowSelectionOnClick = false
}: DataTableProps) {
  const dataGridInitialHeight = '250px'
  const [dataGridWrapperRef, setDataGridWrapperRef] = useState<HTMLDivElement | undefined>(undefined)
  const [dataGridWrapperHeight, setDataGridWrapperHeight] = useState<string>(dataGridInitialHeight)
  const dataGridWrapper = useCallback((ref: HTMLDivElement) => {
    setDataGridWrapperRef(ref)
  }, [])
  const theme = useTheme()

  useEffect(() => {
    setDataGridWrapperHeight(`${dataGridWrapperRef?.clientHeight}` || dataGridInitialHeight)
  }, [dataGridWrapperRef?.clientHeight])

  const emptyStateView = emptyStateElement ? emptyStateElement : <DataTableEmpty />

  const dataGridView = (
    <div
      ref={dataGridWrapper}
      style={{
        display: 'flex',
        color: theme.palette.primary.main,
        flexGrow: 1,
        minHeight: '50%',
        paddingBottom: 15
      }}
    >
      <Paper sx={{ flexGrow: 1, height: dataGridWrapperHeight, border: 'none' }}>
        {isLoading || data.length !== 0 ? (
          <DataGrid
            density={density}
            isRowSelectable={params => (isRowSelectable ? isRowSelectable(params) : true)}
            disableSelectionOnClick={disableRowSelectionOnClick}
            rows={data}
            columns={columns}
            autoHeight={autoHeight}
            sortingOrder={['desc', 'asc']}
            sortingMode="server"
            onSortModelChange={(model: GridSortModel, details: GridCallbackDetails<any>) => {
              onSortChange &&
                onSortChange(
                  new SortModel(
                    model?.[0]?.field,
                    model?.[0]?.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc
                  )
                )
            }}
            page={page}
            pageSize={pageSize}
            paginationMode={totalRowCount ? 'server' : 'client'}
            rowCount={totalRowCount}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRowClick={(params: GridRowParams, event: MuiEvent<React.MouseEvent>) => {
              event.defaultMuiPrevented = true
              onRowClick && onRowClick(params)
            }}
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectionModel}
            disableColumnMenu={true}
            loading={isLoading}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: defaultSortModel?.field,
                    sort: defaultSortModel?.orderDirection === OrderDirection.Asc ? 'asc' : 'desc'
                  }
                ]
              }
            }}
            sx={{
              '& .MuiDataGrid-columnHeadersInner': {
                color: 'primary.main'
              },
              border: 'none'
            }}
            hideFooter={hideFooter}
            columnVisibilityModel={columnsToHide}
            experimentalFeatures={{ newEditingApi: true }}
          />
        ) : (
          emptyStateView
        )}
      </Paper>
    </div>
  )

  if (!filterBarElement) return dataGridView

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: autoHeight ? '0%' : '100%',
        p: [0, 1, 1, 1],
        mb: 3,
        border: 'none'
      }}
    >
      {filterBarElement}
      {dataGridView}
    </Paper>
  )
}

export default DataTable
