import { useGetUserByIdQuery, ROLE, useGetMeQuery, GraphqlError, useUpdateUserRolesMutation } from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { boolean, object, string, TypeOf } from 'zod'
import { DetailPageBaseQueryParams, FormGridLayout } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useEffect, useMemo, useState } from 'react'
import { UserCountryPolicies } from './user-country-policies'
import { FormActionButtons, FormContainer, FormErrorList, FormRadioGroup, FormRadioOption } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface UserConfigurationProps {}

export function UserConfiguration(props: UserConfigurationProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const userRolesConfigurationSchema = object({
    role: string(),
    manageUsersAndPolicies: boolean(),
    importData: boolean(),
    manageLists: boolean(),
    createPerson: boolean()
  })

  type UserConfigurationForm = TypeOf<typeof userRolesConfigurationSchema>

  const { refetch: refetchMe } = useGetMeQuery()
  const { data: getUserById, refetch: refetchUserById } = useGetUserByIdQuery(
    {
      userId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const [currentUserRoles, setCurrentUserRoles] = useState(getUserById?.userById.roles.map(x => x.toUpperCase()) || [])
  const userRoleOptions = [
    new FormRadioOption('', t('user.role.noaccess')),
    new FormRadioOption(ROLE.HRMANAGER, t('user.role.hrmanager')),
    new FormRadioOption(ROLE.ADMIN, t('user.role.admin'))
  ]

  const defaultFormValues = useMemo(
    () => ({
      role: !currentUserRoles.some(x => [ROLE.ADMIN, ROLE.HRMANAGER].map(x => x.toUpperCase()).includes(x))
        ? ''
        : currentUserRoles.includes(ROLE.ADMIN)
        ? ROLE.ADMIN
        : ROLE.HRMANAGER,
      manageUsersAndPolicies: currentUserRoles.includes(ROLE.MANAGEUSERSANDPOLICIES),
      importData: currentUserRoles.includes(ROLE.IMPORTDATA),
      manageLists: currentUserRoles.includes(ROLE.MANAGELISTS),
      createPerson: currentUserRoles.includes(ROLE.CREATEPERSON)
    }),
    [currentUserRoles]
  )

  useEffect(() => reset(defaultFormValues), [currentUserRoles, defaultFormValues])
  const form = useForm<UserConfigurationForm>({
    resolver: zodResolver(userRolesConfigurationSchema),
    defaultValues: defaultFormValues
  })
  const { control, reset } = form
  const updateUserRolesmutation = useUpdateUserRolesMutation()
  const selectedRole = useWatch({ control, name: 'role' })

  const handleOnSubmit = async (user: UserConfigurationForm) => {
    const selectedUserRoles = [
      { key: ROLE.HRMANAGER, value: user.role === ROLE.HRMANAGER.toString() },
      { key: ROLE.ADMIN, value: user.role === ROLE.ADMIN.toString() },
      {
        key: ROLE.MANAGEUSERSANDPOLICIES,
        value: selectedRole === ROLE.HRMANAGER ? user.manageUsersAndPolicies : false
      },
      {
        key: ROLE.IMPORTDATA,
        value: selectedRole === ROLE.HRMANAGER ? user.importData : false
      },
      {
        key: ROLE.MANAGELISTS,
        value: selectedRole === ROLE.HRMANAGER ? user.manageLists : false
      },
      {
        key: ROLE.CREATEPERSON,
        value: selectedRole === ROLE.HRMANAGER ? user.createPerson : false
      }
    ]
    const userRolesToAdd = selectedUserRoles.filter(x => x.value).map(x => x.key.toString())
    const userRolesToDelete = selectedUserRoles.filter(x => !x.value).map(x => x.key.toString())
    const newUserRoles = [
      ...new Set(
        currentUserRoles
          .map(x => x.toUpperCase())
          .filter(x => !userRolesToDelete.includes(x))
          .concat(userRolesToAdd.filter(x => !currentUserRoles.includes(x)))
      )
    ]
    await updateUserRolesmutation
      .mutateAsync({
        updateUserRolesCommand: {
          id: params.id || '',
          roles: newUserRoles
        }
      })
      .then(() => {
        setCurrentUserRoles(newUserRoles)
        refetchMe()
        refetchUserById()
        setBackendErrors([])
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer sx={{ width: '100%', height: '100%' }} form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <FormGridLayout>
        <Grid item xs={6}>
          <FormRadioGroup sx={12} row name="role" options={userRoleOptions} />

          {selectedRole === ROLE.HRMANAGER && (
            <>
              <FormSwitch
                sx={12}
                name="manageUsersAndPolicies"
                label={t('userconfiguration.form.field.manageusersandpolicies')}
              />

              <FormSwitch sx={12} name="importData" label={t('userconfiguration.form.field.importdata')} />

              <FormSwitch sx={12} name="manageLists" label={t('userconfiguration.form.field.managelists')} />

              <FormSwitch sx={12} name="createPerson" label={t('userconfiguration.form.field.createperson')} />
            </>
          )}

          <FormErrorList customErrors={backendErrors} />
          <FormActionButtons isMutating={updateUserRolesmutation.isLoading} />
        </Grid>

        {selectedRole === ROLE.HRMANAGER && (
          <Grid item xs={6}>
            <UserCountryPolicies />
          </Grid>
        )}
      </FormGridLayout>
    </FormContainer>
  )
}

export default UserConfiguration
