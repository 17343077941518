import { Button, Box, Switch, IconButton, MenuItem, useTheme, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { GridRowsProp, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { EpixAvatar, People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { AddPerson } from '../../components/people-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  isAuthorized,
  useNavigateWithParams,
  OrderDirection,
  ROLE,
  useGetAllEmployersByIcpIdsQuery,
  useGetAllIcpsQuery,
  useGetAllPersonsQuery,
  useGetMeQuery
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { DataTable, SortModel } from '../../components/data-table'
import { useSearchParams } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import DropdownMenu from '../../components/dropdown-menu'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import FilterButton from '../../components/filter-button/filter-button'
import { IcpCodeBadge } from '../../components/icp-components'
import { usePeopleFilterStore } from '../../stores/people-page-filter-store'
import SettingsButton from '../../components/settings-button'
import OnboardingPersonLayout from '../../components/onboarding-components/onboarding-person-layout'
import RemovePersonFlyin from '../../components/people-components/remove-person/remove-person-flyin'

/* eslint-disable-next-line */
export interface PeoplePageProps {}

export function PeoplePage(props: PeoplePageProps) {
  const { icpFilters } = useGlobalPersistedStore()
  const { data: me } = useGetMeQuery()
  const navigateWithParams = useNavigateWithParams()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { openFlyIn, closeFlyIn } = useFlyIn()
  const filters = usePeopleFilterStore()
  const [pageSize, setPageSize] = useState(25)
  const [rowCount, setRowCount] = useState(0)
  const theme = useTheme()
  const [clickedPersonId, setClickedPersonId] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [tableData, setTableData] = useState<GridRowsProp>([])

  function handleFilterChange(filter: (value: any) => void, value: any) {
    filter(value)
    if (filter.name !== filters.setPage.name) {
      searchParams.set('page', '0')
      setSearchParams(searchParams)
    }
  }

  const { data, isLoading, refetch } = useGetAllPersonsQuery(
    {
      offset: parseInt(searchParams.get('page')!) * pageSize,
      limit: pageSize,
      selectedDate: filters.selectedDate,
      showInactive: filters.showInactive,
      showWithoutContract: filters.showWithoutContract,
      showValidations: filters.showValidations,
      icps: icpFilters,
      sortByProperty: filters.sortByProperty,
      orderDirection: filters.sortDirection
    },
    {
      suspense: false
    }
  )

  const { data: employers } = useGetAllEmployersByIcpIdsQuery({
    icps: null
  })

  const { data: icps } = useGetAllIcpsQuery({
    offset: 0,
    limit: 0,
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (data && data?.persons) {
      const personData = data.persons
      setTableData(
        personData.data.map((row, index) => {
          return {
            index: index,
            id: row.personId,
            firstName: row.firstName,
            lastName: row.preferredLastName,
            personNumber: row.company.personNumber,
            startedOn: row.startedOn ? new Date(row.startedOn).toLocaleDateString() : '',
            contract: row.icpCodes,
            validations: {
              errors: row.validationErrorCount,
              warnings: row.validationWarningCount
            }
          }
        })
      )
      setRowCount(personData.totalCount)
    }
  }, [data])

  const rowActions = useCallback(
    (rowParams: GridRenderCellParams) => (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            if (rowParams.row.id) {
              setClickedPersonId(rowParams.row.id)
            }
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton aria-label="row details" aria-controls="details-row">
          <KeyboardArrowRightIcon />
        </IconButton>
      </>
    ),
    []
  )

  const validationRowData = useCallback((rowParams: GridRenderCellParams) => {
    const errors = rowParams.value.errors
    const warnings = rowParams.value.warnings

    if (errors === 0 && warnings === 0) {
      return (
        <Typography color={theme.palette.success.main}>
          <CheckIcon />
        </Typography>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {warnings > 0 && (
            <Typography color={theme.palette.warning.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <WarningAmberOutlinedIcon sx={{ fontSize: 18 }} />
              {warnings}
            </Typography>
          )}
          {errors > 0 && (
            <Typography color={theme.palette.error.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ErrorOutlineOutlinedIcon sx={{ fontSize: 18 }} />
              {errors}
            </Typography>
          )}
        </Box>
      )
    }
  }, [])

  const contractRowData = useCallback((rowParams: GridRenderCellParams) => {
    const icpsCodes = rowParams.value
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        {icpsCodes.map((icpCode: string, index: number) => (
          <IcpCodeBadge key={index} icpCode={icpCode} />
        ))}
      </Box>
    )
  }, [])

  const RenderAvatar = useCallback((rowParams: GridRenderCellParams) => {
    return <EpixAvatar personId={rowParams.id.toString()} />
  }, [])

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'avatar', headerName: '', width: 50, renderCell: RenderAvatar },
      {
        field: 'firstName',
        headerName: t('peoplepage.datatable.column.firstname'),
        flex: 1
      },
      {
        field: 'lastName',
        headerName: t('peoplepage.datatable.column.lastname'),
        flex: 1
      },
      {
        field: 'personNumber',
        headerName: t('peoplepage.datatable.column.personnumber'),
        flex: 1
      },
      {
        field: 'startedOn',
        headerName: t('peoplepage.datatable.column.startedon'),
        flex: 1
      },
      {
        field: 'contract',
        headerName: t('peoplepage.datatable.column.contracts'),
        sortable: false,
        renderCell: contractRowData
      },
      {
        field: 'validations',
        sortable: false,
        headerName: t('peoplepage.datatable.column.validations'),
        renderCell: validationRowData
      },
      { field: '', sortable: false, editable: false, renderCell: rowActions }
    ],
    [RenderAvatar, contractRowData, rowActions, t, validationRowData]
  )

  useEffect(() => {
    filters.setSortByProperty(columns?.[2]?.field)
  }, [])

  function goToPersonDetailPage(id: string) {
    navigateWithParams(People.PEOPLE_ID_DETAILS(id))
  }

  const handleLinkClick = () => {
    openFlyIn({
      content: <AddPerson />,
      callbackAfterClose: () => refetch
    })
  }

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('peoplepage.title')}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FilterButton
            filters={{
              showInactive: filters.showInactive,
              showWithoutContract: filters.showWithoutContract,
              showValidations: filters.showValidations
            }}
          >
            <p>
              <Switch
                checked={filters.showInactive}
                onChange={e => handleFilterChange(filters.setShowInactive, e.target.checked)}
                aria-label={t('peoplepage.filters.showinactivepeople')}
              />
              {t('peoplepage.filters.showinactivepeople')}
            </p>
            <p>
              <Switch
                checked={filters.showWithoutContract}
                onChange={e => handleFilterChange(filters.setShowWithoutContract, e.target.checked)}
                aria-label={t('peoplepage.filters.showwithoutcontract')}
              />
              {t('peoplepage.filters.showwithoutcontract')}
            </p>
            <p>
              <Switch
                checked={filters.showValidations}
                onChange={e => handleFilterChange(filters.setShowValidations, e.target.checked)}
                aria-label={t('peoplepage.filters.showvalidations')}
              />
              {t('peoplepage.filters.showvalidations')}
            </p>
          </FilterButton>
          {isAuthorized(me?.me.roles, [ROLE.MANAGELISTS, ROLE.ADMIN]) && (
            <SettingsButton
              menuItems={[
                {
                  label: t('peoplepage.settings.functionareas'),
                  to: '/configuration/function-areas'
                },
                {
                  label: t('peoplepage.settings.functionlevels'),
                  to: '/configuration/function-levels'
                }
              ]}
            />
          )}
        </Box>

        {isAuthorized(me?.me.roles, [ROLE.CREATEPERSON, ROLE.ADMIN]) && (
          <Button
            variant="contained"
            disabled={
              (icps?.icps && icps?.icps.totalCount === 0) ||
              (employers?.employersByIcpIds && employers?.employersByIcpIds.length === 0)
            }
            onClick={() =>
              openFlyIn({
                content: <AddPerson />,
                callbackAfterClose: () => refetch
              })
            }
          >
            <AddIcon />
            {t('peoplepage.button.addperson')}
          </Button>
        )}
      </Box>
    </Box>
  )

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={sortModel => {
          filters.setSortByProperty(sortModel.field)
          filters.setSortDirection(sortModel.orderDirection)
        }}
        page={parseInt(searchParams.get('page')!)}
        onPageChange={(page, _) => {
          // onPageChange runs on initial render with page being NaN
          searchParams.set('page', `${page ? page : 0}`)
          setSearchParams(searchParams)
        }}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onRowClick={params => params.row.id && goToPersonDetailPage(params.row.id)}
        isLoading={isLoading}
        defaultSortModel={new SortModel(filters.sortByProperty, filters.sortDirection)}
        filterBarElement={filterBar}
        emptyStateElement={
          <OnboardingPersonLayout
            onClick={handleLinkClick}
            icpsPresent={(icps?.icps && icps.icps.totalCount > 0) ?? false}
            employersPresent={(employers?.employersByIcpIds && employers?.employersByIcpIds.length > 0) ?? false}
          />
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => clickedPersonId && goToPersonDetailPage(clickedPersonId)}>
          {t('peoplepage.list.row.menu.viewdetails')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemovePersonFlyin personId={clickedPersonId ?? ''} closeFlyIn={closeFlyIn} />
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default PeoplePage
