import {
  EMPLOYEEACCESSLEVELTYPES,
  GraphqlError,
  useGetDocumentTypeByIdQuery,
  useUpdateDocumentTypeMutation
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { boolean, object, TypeOf } from 'zod'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, useFlyIn } from '@epix-web-apps/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { FormSwitch } from '../../form-components/form-switch'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface EditAccessCompanyDocumenttypeProps {
  documentTypeId?: string
}

export function EditAccessCompanyDocumenttype({ documentTypeId }: EditAccessCompanyDocumenttypeProps) {
  const { t } = useTranslation()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editCompanyDocumentTypeSchema = object({
    employeeAccessLevel: boolean()
  })

  const updateMutation = useUpdateDocumentTypeMutation()

  const { data: getDocumentType, refetch } = useGetDocumentTypeByIdQuery({
    id: documentTypeId || ''
  })
  const { closeFlyIn } = useFlyIn()

  type EditCompanyDocumentTypeForm = TypeOf<typeof editCompanyDocumentTypeSchema>

  const form = useForm<EditCompanyDocumentTypeForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      employeeAccessLevel:
        getDocumentType?.documentTypeById.employeeAccessLevel.key === EMPLOYEEACCESSLEVELTYPES.CAN_VIEW ? true : false
    },
    resolver: zodResolver(editCompanyDocumentTypeSchema)
  })

  const handleOnSubmit = async (editedCompanyDocumentType: EditCompanyDocumentTypeForm) => {
    if (getDocumentType?.documentTypeById)
      await updateMutation
        .mutateAsync({
          updateDocumentTypeCommand: {
            id: getDocumentType?.documentTypeById.id,
            active: getDocumentType?.documentTypeById.active,
            name: getDocumentType?.documentTypeById.name,
            employeeAccessLevelTypeKey: editedCompanyDocumentType.employeeAccessLevel
              ? EMPLOYEEACCESSLEVELTYPES.CAN_VIEW
              : EMPLOYEEACCESSLEVELTYPES.NO_ACCESS,
            translations: getDocumentType?.documentTypeById?.nameTranslations.map(({ locale, value }) => ({
              locale,
              value
            }))
          }
        })
        .then(() => {
          refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))

  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcompanydocumenttype.editaccess')}</Typography>
      <FormGridLayout>
        <FormSwitch sx={12} name="employeeAccessLevel" label={t('form.field.employeeaccesslevel')} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditAccessCompanyDocumenttype
