import { Box, Tab, Tabs } from '@mui/material'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DetailPageBaseQueryParams, LoadingOverlay } from '@epix-web-apps/ui'
import { TabPanel, a11yProps } from '@epix-web-apps/ui'
import EditUser from '../edit-user'
import { UserConfiguration } from '../user-configuration'
import SelfserviceAccess from '../selfservice-access'
import { useGlobalStore } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'

/* eslint-disable-next-line */
export interface UserTabGeneralProps {
  userId: string
}

export function UserTabGeneral({ userId }: UserTabGeneralProps) {
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const params = useParams<DetailPageBaseQueryParams>()
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(e, tabIndex) => setTabIndex(tabIndex)}>
          <Tab label={t('userdetailpage.tab.userinfo')} {...a11yProps(0)} />
          {me?.id !== params.id && <Tab label={t('userdetailpage.tab.accesslevel')} {...a11yProps(1)} />}
          {me?.id !== params.id && <Tab label={t('userdetailpage.tab.selfservice')} {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TabPanel value={tabIndex} index={0}>
          <Suspense fallback={<LoadingOverlay />}>
            <EditUser />
          </Suspense>
        </TabPanel>
        {me?.id !== params.id && (
          <TabPanel value={tabIndex} index={1}>
            <Suspense fallback={<LoadingOverlay />}>
              <UserConfiguration />
            </Suspense>
          </TabPanel>
        )}
        {me?.id !== params.id && (
          <TabPanel value={tabIndex} index={2}>
            <Suspense fallback={<LoadingOverlay />}>
              <SelfserviceAccess />
            </Suspense>
          </TabPanel>
        )}
      </Box>
    </Box>
  )
}

export default UserTabGeneral
