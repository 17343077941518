import { Box, Typography } from '@mui/material'
import { FieldValues, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useUpdatePersonIdentificationDetailsMutation,
  useGetPersonByIdQuery,
  DEFAULT_UNDEFINED_KEY,
  DEFAULT_LASTNAMETYPE_KEY,
  useGetAllContractsQuery,
  useGetSubGendersQuery,
  numericString,
  formTypeSelectOptions,
  FormSelectOption
} from '@epix-web-apps/core'
import { date, boolean, object, string, TypeOf, number } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFlyIn } from '@epix-web-apps/ui'
import { DetailPageBaseQueryParams, FormGridLayout } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useEffect } from 'react'

/* eslint-disable-next-line */
export interface EditPersonIdentificationProps {}

export interface SubIcpGenderDropdownProps<T extends FieldValues> {
  icp:
    | {
        __typename?: 'IcpModel' | undefined
        id: string
        code: string
        configurationKey: string
      }
    | null
    | undefined
  selectedSubGenderIds: Array<string> | undefined
  index: number
}

const SubIcpGenderDropdown = ({ icp, selectedSubGenderIds, index }: SubIcpGenderDropdownProps<any>) => {
  const { t } = useTranslation()
  const { control, setValue, getFieldState } = useFormContext()
  const watchGenderDropdown = useWatch({ control, name: 'genderType' })

  const { data: getIcpSubGenders, refetch } = useGetSubGendersQuery(
    {
      configurationKey: icp?.configurationKey || '',
      genderTypeKey: watchGenderDropdown
    },
    {
      enabled: !!watchGenderDropdown,
      suspense: false
    }
  )
  const subGenders = getIcpSubGenders?.subGenders
  const error = getFieldState(`subGenders.${index}.id`).error?.message

  useEffect(() => {
    if (!error) {
      refetch()
      const selectedSubGenderId = subGenders?.find(x => selectedSubGenderIds?.includes(x.id))?.id
      if (subGenders && !selectedSubGenderId) {
        setValue(`subGenders.${index}.id`, subGenders?.[0]?.id)
      } else {
        setValue(`subGenders.${index}.id`, selectedSubGenderId)
        if (subGenders?.length === 0) setValue(`subGenders.${index}.id`, undefined)
      }
    }
  }, [selectedSubGenderIds, subGenders, index, error])

  if (subGenders && subGenders.length !== 0) {
    return (
      <FormSelect
        sx={6}
        name={`subGenders.${index}.id`}
        label={`${t('form.field.subgender', { icpcode: icp?.code })} *`}
        options={subGenders.map(x => new FormSelectOption(x.id, x.name))}
      />
    )
  }

  return null
}

export function EditPersonIdentification(props: EditPersonIdentificationProps) {
  const { t } = useTranslation()
  const editPersonSubGenderSchema = object({
    id: string({
      required_error: t('form.validation.subgenderrequired'),
      invalid_type_error: t('form.validation.subgenderrequired')
    })
      .min(1, t('form.validation.subgenderrequired'))
      .optional()
  })

  const editPersonIdentificationSchema = object({
    titleType: string().nullable(),
    firstName: string({
      required_error: t('form.validation.firstnamerequired'),
      invalid_type_error: t('form.validation.firstnamerequired')
    }).min(1, t('form.validation.firstnamerequired')),
    lastName: string({
      required_error: t('form.validation.lastnamerequired'),
      invalid_type_error: t('form.validation.lastnamerequired')
    }).min(1, t('form.validation.lastnamerequired')),
    subGenders: editPersonSubGenderSchema.array(),
    lastNamePrefix: string().nullable(),
    otherNames: string().nullable(),
    partnerName: string().nullable(),
    partnerLastNamePrefix: string().nullable(),
    preferredLastNameType: string().nullable(),
    preferredLocale: string().optional().nullable(),
    genderType: string().nullable(),
    birthDate: date().optional().nullable(),
    birthPlace: string().nullable(),
    birthCountry: string().optional().nullable(),
    nationality: string().optional().nullable(),
    socialSecurityNumber: string().nullable(),
    disabled: boolean(),
    disabledPercentage: numericString(
      number({
        required_error: t('form.validation.percentagerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .gte(0, { message: t('form.validation.percentagegreaterthan0') })
        .lte(100, { message: t('form.validation.percentagelessthan100') })
        .optional()
    )
  })

  type EditPersonIdentificationForm = TypeOf<typeof editPersonIdentificationSchema>
  const params = useParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { genderOptions, countryOptions, titleOptions, lastNameOptions, nationalityOptions, localeOptions } =
    formTypeSelectOptions
  const { data: getPersonById, refetch: refetchPersonById } = useGetPersonByIdQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const { data: getAllContracts } = useGetAllContractsQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const contracts = getAllContracts?.allContracts
  const mutation = useUpdatePersonIdentificationDetailsMutation()
  const form = useForm<EditPersonIdentificationForm>({
    resolver: zodResolver(editPersonIdentificationSchema),
    defaultValues: {
      titleType: getPersonById?.personById.titleType?.key || DEFAULT_UNDEFINED_KEY,
      firstName: getPersonById?.personById.firstName,
      otherNames: getPersonById?.personById.otherNames,
      lastName: getPersonById?.personById.lastName,
      lastNamePrefix: getPersonById?.personById.lastNamePrefix,
      preferredLastNameType: getPersonById?.personById.preferredLastNameType?.key || DEFAULT_LASTNAMETYPE_KEY,
      partnerName: getPersonById?.personById.partnerName,
      partnerLastNamePrefix: getPersonById?.personById.partnerLastNamePrefix,
      preferredLocale: getPersonById?.personById.preferredLanguage?.locale,
      genderType: getPersonById?.personById.genderType?.key || DEFAULT_UNDEFINED_KEY,
      subGenders: getPersonById?.personById.icpSubGenders || [],
      birthDate: getPersonById?.personById.birthDate ? new Date(getPersonById?.personById?.birthDate) : null,
      birthPlace: getPersonById?.personById.placeOfBirth,
      birthCountry: getPersonById?.personById.countryOfBirth?.code,
      nationality: getPersonById?.personById.nationality?.code,
      socialSecurityNumber: getPersonById?.personById.nationalSocialSecurityNumber,
      disabled: getPersonById?.personById.disabled,
      disabledPercentage: getPersonById?.personById.disabledPercentage || null
    }
  })
  const { control } = form
  const watchDisabled = useWatch({ control, name: `disabled` })

  const handleOnSubmit = async (personIdentificationDetails: EditPersonIdentificationForm) => {
    await mutation
      .mutateAsync({
        personIdentificationCommand: {
          personId: params.id || '',
          titleTypeKey: personIdentificationDetails.titleType || DEFAULT_UNDEFINED_KEY,
          firstName: personIdentificationDetails.firstName,
          otherNames: personIdentificationDetails.otherNames,
          lastName: personIdentificationDetails.lastName,
          lastNamePrefix: personIdentificationDetails.lastNamePrefix,
          partnerName: personIdentificationDetails.partnerName,
          partnerNamePrefix: personIdentificationDetails.partnerLastNamePrefix,
          preferredLastNameTypeKey: personIdentificationDetails.preferredLastNameType || DEFAULT_LASTNAMETYPE_KEY,
          preferredLocale: personIdentificationDetails.preferredLocale || '',
          genderTypeKey: personIdentificationDetails.genderType || DEFAULT_UNDEFINED_KEY,
          icpSubGenderIds:
            personIdentificationDetails.subGenders?.filter(x => x.id !== undefined).map(x => x.id || '') ?? [],
          birthDate: personIdentificationDetails.birthDate,
          placeOfBirth: personIdentificationDetails.birthPlace || '',
          countryOfBirth: personIdentificationDetails.birthCountry || '',
          nationality: personIdentificationDetails.nationality || '',
          nationalSocialSecurityNumber: personIdentificationDetails.socialSecurityNumber,
          disabled: personIdentificationDetails.disabled,
          disabledPercentage: personIdentificationDetails.disabledPercentage || null
        }
      })
      .then(() => {
        refetchPersonById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.identification.title')}</Typography>

      <FormGridLayout>
        <FormSelect sx={6} name="titleType" label={t('form.field.title')} options={titleOptions} />

        <FormInput sx={6} name="firstName" label={`${t('form.field.firstname')} *`} />

        <FormInput sx={6} name="otherNames" label={t('form.field.othernames')} />

        <FormSelect
          sx={6}
          name="preferredLastNameType"
          label={t('form.field.lastnametype')}
          options={lastNameOptions}
        />

        <FormInput sx={6} name="lastNamePrefix" label={t('form.field.lastnameprefix')} />

        <FormInput sx={6} name="lastName" label={`${t('form.field.lastname')} *`} />

        <FormInput sx={6} name="partnerLastNamePrefix" label={t('form.field.partnernameprefix')} />

        <FormInput sx={6} name="partnerName" label={t('form.field.partnername')} />

        <FormSelect sx={6} name="preferredLocale" label={t('form.field.language')} options={localeOptions} />

        <FormSelect
          sx={6}
          name="genderType"
          label={t('form.field.gender')}
          onChange={() => {
            form.resetField('subGenders')
          }}
          options={genderOptions}
        />

        {[...new Map(contracts?.map(item => [item.icp?.id, item])).values()].map((contract, index) => (
          <SubIcpGenderDropdown
            key={index}
            selectedSubGenderIds={getPersonById?.personById.icpSubGenders?.map(x => x.id)}
            icp={contract.icp}
            index={index}
          />
        ))}
      </FormGridLayout>

      <Box sx={{ marginTop: '2rem' }}>
        <FormGridLayout>
          <FormDatepicker sx={6} name="birthDate" label={t('form.field.birthdate')} />

          <FormInput sx={6} name="birthPlace" label={t('form.field.birthplace')} />

          <FormSelect sx={6} name="birthCountry" label={t('form.field.birthcountry')} options={countryOptions} />

          <FormSelect sx={6} name="nationality" label={t('form.field.nationality')} options={nationalityOptions} />

          <FormInput sx={6} name="socialSecurityNumber" label={t('form.field.socialsecuritynumber')} />

          <FormSwitch
            sx={12}
            name="disabled"
            onChange={() => {
              form.resetField('disabledPercentage', {
                defaultValue: undefined
              })
            }}
            label={t('form.field.disabled')}
          />

          {watchDisabled && <FormInput sx={6} name="disabledPercentage" label={t('form.field.disabled-percentage')} />}
        </FormGridLayout>
      </Box>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPersonIdentification
