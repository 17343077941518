import {
  OrderDirection,
  PROCESSINGIMPORTJOBTYPES,
  TypeModel,
  useGetAllEmployersByIcpIdsQuery,
  useGetAllIcpsQuery,
  useGetProcessingImportJobTypesQuery,
  useImportCalculatedDataMutation,
  useImportCompanyCarsMutation,
  useImportContractsMutation,
  useImportManualEventsMutation,
  useImportPayComponentsMutation,
  useImportPayrollCodesDataMutation,
  useImportPeopleMutation,
  useImportPersonalCalendarDaysMutation
} from '@epix-web-apps/core'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import { ChangeEvent, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { HeaderTitleNavigation, Import } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface ImportDataPageProps {}

export function ImportDataPage(props: ImportDataPageProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [importDataType, setImportDataType] = useState<{
    value: TypeModel | string | undefined
    error: boolean
  }>({ value: '', error: false })
  const [importEmployerId, setImportEmployerId] = useState<{
    value: string
    error: boolean
  }>({ value: '', error: false })

  const { data: getAllIcps } = useGetAllIcpsQuery(
    {
      activeOnly: true,
      offset: 0,
      limit: -1,
      sortByProperty: 'Code',
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )
  const { data: getAllEmployersByIcpIds } = useGetAllEmployersByIcpIdsQuery(
    {
      icps: getAllIcps?.icps.data.map(x => x.id),
      active: true
    },
    {
      suspense: false,
      enabled: !!getAllIcps
    }
  )

  const { data: processingImportJobTypes } = useGetProcessingImportJobTypesQuery()

  const {
    mutateAsync: importCalculatedDataMutationAsync,
    isLoading: importCalculatedDataIsLoading,
    reset: resetImportCalculatedDataMutation
  } = useImportCalculatedDataMutation()
  const {
    mutateAsync: importPayrollCodesDataMutationAsync,
    isLoading: importPayrollCodesDataIsLoading,
    reset: resetImportPayrollCodesDataMutation
  } = useImportPayrollCodesDataMutation()
  const {
    mutateAsync: importPeopleMutationAsync,
    isLoading: importPeopleIsLoading,
    reset: resetImportPeopleMutation
  } = useImportPeopleMutation()
  const {
    mutateAsync: importContractsMutationAsync,
    isLoading: importContractsMutationIsLoading,
    reset: resetImportContractsMutation
  } = useImportContractsMutation()
  const {
    mutateAsync: importPayComponentsMutationAsync,
    isLoading: importPayComponentsIsLoading,
    reset: resetImportPayComponentsMutation
  } = useImportPayComponentsMutation()
  const {
    mutateAsync: importCompanyCarsMutationAsync,
    isLoading: importCompanyCarsIsLoading,
    reset: resetImportCompanyCarsMutationAsync
  } = useImportCompanyCarsMutation()
  const {
    mutateAsync: importPersonalCalendarDaysMutationAsync,
    isLoading: importPersonalCalendarDaysIsLoading,
    reset: resetImportPersonalCalendarDaysMutationAsync
  } = useImportPersonalCalendarDaysMutation()
  const {
    mutateAsync: importManualEventsAsync,
    isLoading: importManualEventsIsLoading,
    reset: resetImportManualEventsMutationAsync
  } = useImportManualEventsMutation()

  const anyImportMutationIsLoading = () => {
    return (
      importCalculatedDataIsLoading ||
      importPayrollCodesDataIsLoading ||
      importPeopleIsLoading ||
      importContractsMutationIsLoading ||
      importPayComponentsIsLoading ||
      importCompanyCarsIsLoading ||
      importPersonalCalendarDaysIsLoading ||
      importManualEventsIsLoading
    )
  }

  const resetAllImportMutations = () => {
    resetImportCalculatedDataMutation()
    resetImportPayrollCodesDataMutation()
    resetImportPeopleMutation()
    resetImportContractsMutation()
    resetImportPayComponentsMutation()
    resetImportCompanyCarsMutationAsync()
    resetImportPersonalCalendarDaysMutationAsync()
    resetImportManualEventsMutationAsync()
  }

  const handleNext = useCallback(() => {
    setImportDataType({
      value: importDataType.value,
      error: !processingImportJobTypes?.allProcessingImportJobTypes
        .map(x => x.key)
        .some(x => x === importDataType.value)
    })
    if (importDataType.value === PROCESSINGIMPORTJOBTYPES.PAYROLL_CODES) {
      setImportEmployerId({
        value: importEmployerId.value,
        error: !getAllEmployersByIcpIds?.employersByIcpIds.some(x => x.id === importEmployerId.value)
      })
    }
    if (
      processingImportJobTypes?.allProcessingImportJobTypes.map(x => x.key).some(x => x === importDataType.value) &&
      (importDataType.value !== PROCESSINGIMPORTJOBTYPES.PAYROLL_CODES ||
        getAllEmployersByIcpIds?.employersByIcpIds.some(x => x.id === importEmployerId.value))
    ) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }, [getAllEmployersByIcpIds?.employersByIcpIds, importDataType.value, importEmployerId.value])

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    resetAllImportMutations()
    setImportDataType({ value: '', error: false })
    setImportEmployerId({ value: '', error: false })
    setActiveStep(0)
  }

  const uploadFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.validity) {
        if (!e.target.files) return

        const file = e.target.files[0]
        if (file) {
          switch (importDataType.value) {
            case processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.CALCULATED_DATA
            )?.key:
              await importCalculatedDataMutationAsync({
                file: file
              }).then(handleNext)
              break
            case processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.PAYROLL_CODES
            )?.key:
              await importPayrollCodesDataMutationAsync({
                file: file,
                employerId: importEmployerId.value
              }).then(handleNext)
              break
            case processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.PEOPLE
            )?.key:
              await importPeopleMutationAsync({
                file: file
              }).then(handleNext)
              break
            case processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.CONTRACTS
            )?.key:
              await importContractsMutationAsync({
                file: file
              }).then(handleNext)
              break
            case processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.PAYCOMPONENTS
            )?.key:
              await importPayComponentsMutationAsync({
                file: file
              }).then(handleNext)
              break
            case processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.COMPANY_CARS
            )?.key:
              await importCompanyCarsMutationAsync({
                file: file
              }).then(handleNext)
              break
            case processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.PERSONAL_CALENDAR_DAYS
            )?.key:
              await importPersonalCalendarDaysMutationAsync({
                file: file
              }).then(handleNext)
              break
            case processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.MANUAL_EVENTS
            )?.key:
              await importManualEventsAsync({
                file: file
              }).then(handleNext)
              break
          }
        }
      }
    },
    [
      handleNext,
      importCalculatedDataMutationAsync,
      importContractsMutationAsync,
      importCompanyCarsMutationAsync,
      importDataType.value,
      importEmployerId.value,
      importPayrollCodesDataMutationAsync,
      importPeopleMutationAsync,
      importPersonalCalendarDaysMutationAsync,
      importManualEventsAsync
    ]
  )

  const steps = [
    {
      label: t('configurationimport.step1.label'),
      description: t('configurationimport.step1.description')
    },
    {
      label: t('configurationimport.step2.label'),
      description: t('configurationimport.step2.description')
    }
  ]

  return (
    <Box
      sx={{
        p: [3]
      }}
    >
      <HeaderTitleNavigation backToLink={Import.IMPORT} title={t('configurationimport.title')} />

      <Stepper activeStep={activeStep} orientation="vertical" sx={{ mt: '1rem' }}>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent sx={{ py: 1 }}>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: -1 }}>
                {index === 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Grid container paddingY={1}>
                      <Grid item md={6} sm={12} lg={4}>
                        <FormControl fullWidth size="small" error={importDataType.error}>
                          <InputLabel id="import-datatype-select-label">{t('form.field.datatype')}</InputLabel>
                          <Select
                            labelId="import-datatype-select-label"
                            id="import-datatype-select"
                            value={importDataType.value}
                            label={t('form.field.datatype')}
                            error={importDataType.error}
                            onChange={e => {
                              setImportDataType({
                                value: e.target.value,
                                error: !processingImportJobTypes?.allProcessingImportJobTypes.some(
                                  x => x.key === e.target.value
                                )
                              })
                              setImportEmployerId({ value: '', error: false })
                            }}
                          >
                            {processingImportJobTypes?.allProcessingImportJobTypes.map((x, i) => (
                              <MenuItem key={i} value={x.key}>
                                {x.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {importDataType.value === PROCESSINGIMPORTJOBTYPES.PAYROLL_CODES && (
                      <Grid container paddingY={1}>
                        <Grid item md={6} sm={12} lg={4}>
                          <FormControl fullWidth size="small" error={importEmployerId.error}>
                            <InputLabel id="import-employerid-select-label">{t('form.field.employer')}</InputLabel>
                            <Select
                              labelId="import-employerid-select-label"
                              id="import-datatype-select"
                              value={importEmployerId.value}
                              label={t('form.field.employer')}
                              error={importEmployerId.error}
                              onChange={e =>
                                setImportEmployerId({
                                  value: e.target.value,
                                  error: !getAllEmployersByIcpIds?.employersByIcpIds.some(x => x.id === e.target.value)
                                })
                              }
                            >
                              {getAllEmployersByIcpIds?.employersByIcpIds.map((x, i) => (
                                <MenuItem
                                  key={i}
                                  value={x.id}
                                >{`${x.companyName} (${x.icpCode} - ${x.number})`}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, py: 1 }}>
                  {index !== steps.length - 1 && (
                    <Button variant="contained" onClick={handleNext}>
                      {t('common.continue')}
                    </Button>
                  )}
                  {index === steps.length - 1 && (
                    <Button variant="contained" component="label">
                      {t('configurationimport.importbtn')}
                      <input accept=".csv,text/csv" type="file" onChange={uploadFile} hidden />
                      {anyImportMutationIsLoading() && (
                        <LinearProgress
                          color="primary"
                          sx={{
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0.6,
                            borderRadius: 2
                          }}
                        />
                      )}
                    </Button>
                  )}
                  <Button disabled={index === 0} onClick={handleBack}>
                    {t('common.back')}
                  </Button>
                </Box>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>
            <Trans
              i18nKey="configurationimport.importstarted"
              components={{
                tt: <Link onClick={() => navigate(Import.IMPORT)} />
              }}
            />
          </Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            {t('configurationimport.importanotherfile')}
          </Button>
        </Paper>
      )}
    </Box>
  )
}

export default ImportDataPage
