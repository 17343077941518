import { FormLabel, GridSize, Switch, useTheme } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { OptionalGridWrapper } from '@epix-web-apps/ui'

export interface FormSwitchProps {
  name: string
  label: string
  sx?: GridSize
  disabled?: boolean
  onChange?: (checked: boolean) => void
}

export function FormSwitch({ name, label, sx, disabled = false, onChange }: FormSwitchProps) {
  const form = useFormContext()
  const theme = useTheme()
  if (!form) throw Error('Form elements can only be used inside the react hook forms provider')
  const { ref, ...register } = form.register(name)

  return (
    <OptionalGridWrapper sx={sx}>
      <>
        <Controller
          {...register}
          render={({ field }) => (
            <Switch
              {...register}
              aria-label={label}
              ref={ref}
              id={register.name}
              checked={form?.getValues(name) ?? false}
              disabled={disabled}
              onChange={(e, checked) => {
                field.onChange(checked)
                onChange && onChange(checked)
              }}
            />
          )}
        />
        <FormLabel sx={{ color: theme.palette.text.primary }}>{label}</FormLabel>
      </>
    </OptionalGridWrapper>
  )
}

export default FormSwitch
