import {
  CalendarDayModel,
  DEFAULT_BASED_ON_WORKSCHEDULE,
  PersonalCalendarDayViewModel,
  useDeletePersonalCalendarDayMutation,
  useGetCalendarForDateRangeQuery,
  useUploadPersonalCalendarDayFileMutation
} from '@epix-web-apps/core'
import {
  ACCEPTED_UPLOAD_TYPES,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormFileUpload,
  FormGridLayout,
  useFlyIn
} from '@epix-web-apps/ui'
import PersonIdentificationComponent from '../../person-identification-component'
import { Box, Button, Checkbox, Divider, FormGroup, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { any, object, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ChangeEvent, useState } from 'react'
import AddEditCalendarEntry from '../add-edit-calendar-entry'
import { useQueries } from 'react-query'

/* eslint-disable-next-line */
export interface AddEditCalendarEntriesProps {
  start: Date
  end: Date
  contractId: string | undefined
}

export const useUploadFiles = (documentFiles: Array<{ file: File }> | undefined, calendarDayIds: Array<string>) => {
  const queriesConfig =
    documentFiles?.map(doc => ({
      queryKey: ['upload', doc.file.name],
      queryFn: () =>
        useUploadPersonalCalendarDayFileMutation.fetcher({
          personalCalendarDayIds: calendarDayIds,
          file: doc.file
        })
    })) || []

  const uploadQueries = useQueries(queriesConfig)

  const isLoadingUpload = uploadQueries.some(result => result.isLoading)

  return {
    uploadQueries,
    isLoadingUpload
  }
}

export function AddEditCalendarEntries({ contractId, start, end }: AddEditCalendarEntriesProps) {
  const { closeFlyIn, openFlyIn } = useFlyIn()
  const { t } = useTranslation()
  const [selectedCalendarEntries, setSelectedCalendarEntries] = useState<PersonalCalendarDayViewModel[]>([])
  const [selectAll, setSelectAll] = useState(false)

  const { data: calendarData, refetch: refetchCalendarData } = useGetCalendarForDateRangeQuery({
    contractId: contractId || '',
    startDate: start,
    endDate: end
  })
  const deletePersonalCalendarDayMutation = useDeletePersonalCalendarDayMutation()

  const addCalendarEntrySchema = object({
    documents: any().optional()
  })

  const form = useForm<CreateCalendarEntriesForm>({
    resolver: zodResolver(addCalendarEntrySchema)
  })

  type CreateCalendarEntriesForm = TypeOf<typeof addCalendarEntrySchema>

  const handleSelectAll = (e: any) => {
    const checked = e.target.checked
    setSelectAll(checked)

    if (checked) {
      setSelectedCalendarEntries(personalCalendarDays)
    } else {
      setSelectedCalendarEntries([])
    }
  }

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    calendarEntry: PersonalCalendarDayViewModel
  ) => {
    let updatedSelection
    if (checked) {
      updatedSelection = [...selectedCalendarEntries, calendarEntry]
    } else {
      updatedSelection = selectedCalendarEntries.filter(
        x => x.personalCalendarDayId !== calendarEntry.personalCalendarDayId
      )
    }
    setSelectedCalendarEntries(updatedSelection)
  }

  const { isLoadingUpload } = useUploadFiles(
    form.getValues('documents'),
    selectedCalendarEntries?.map(x => x.personalCalendarDayId)
  )

  const handleOnSubmit = async () => {
    if (selectedCalendarEntries.length > 0) {
      closeFlyIn()
      refetchCalendarData()
    }
  }

  const extractAllPersonalCalendarDays = (data: CalendarDayModel[]): PersonalCalendarDayViewModel[] => {
    const personalCalendarDays: PersonalCalendarDayViewModel[] = []
    if (data) {
      data.forEach((day: CalendarDayModel) => {
        personalCalendarDays.push(...day.personalCalendarDays)
      })
    }
    return personalCalendarDays
  }

  const handleDeletePersonalCalendarDays = () => {
    selectedCalendarEntries.forEach(x => handleDelete(x.personalCalendarDayId))
  }

  const handleDelete = async (personalCalendarDayId: string) => {
    await deletePersonalCalendarDayMutation
      .mutateAsync({
        deletePersonalCalendarDayCommand: {
          personalCalendarDayId: personalCalendarDayId || ''
        }
      })
      .finally(() => {
        refetchCalendarData()
        closeFlyIn()
      })
  }

  const personalCalendarDays = extractAllPersonalCalendarDays(calendarData?.calendarDaysByContractId || [])

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <PersonIdentificationComponent title={t('flyin.calendarentries.title')} />
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h4" color={'gray'}>
          {start?.toLocaleDateString() === end?.toLocaleDateString()
            ? start?.toLocaleDateString()
            : start?.toLocaleDateString() + ' - ' + end?.toLocaleDateString()}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            openFlyIn({
              content: (
                <AddEditCalendarEntry
                  contractId={contractId}
                  start={new Date(start)}
                  end={new Date(end)}
                  addMultipleCalendarEntries={true}
                />
              ),
              callbackAfterClose: () => () => {
                refetchCalendarData()
              }
            })
          }
        >
          {t('flyin.calendarentries.addcalendarentries')}
        </Button>
      </Box>
      {personalCalendarDays && personalCalendarDays.length > 0 && (
        <Grid className="bold" container>
          <Grid xs={3.5} item>
            <p>
              <Checkbox sx={{ p: 0, mr: 0.8 }} checked={selectAll} onChange={handleSelectAll} size="small" />
              {t('form.field.date')}
            </p>
          </Grid>
          <Grid xs={4} item>
            <p>{t('form.field.payrollCode')}</p>
          </Grid>
          <Grid xs={3} item>
            <p>{t('form.field.timeframetypekey')}</p>
          </Grid>
          <Grid xs={1.5} item>
            <p>{t('form.field.numberofhours')}</p>
          </Grid>
        </Grid>
      )}

      <FormGridLayout>
        <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
          {personalCalendarDays && (
            <FormGroup>
              {personalCalendarDays.map((calendarEntry: PersonalCalendarDayViewModel, index: number) => (
                <label
                  key={index}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '3rem'
                  }}
                >
                  <Grid xs={3.5} item>
                    <p>
                      <Checkbox
                        sx={{
                          padding: '0 0.25rem 0 0',
                          marginTop: '-0.2rem'
                        }}
                        size="small"
                        checked={selectedCalendarEntries.includes(calendarEntry)}
                        value={calendarEntry.personalCalendarDayId}
                        onChange={(e, checked) => handleCheckboxChange(e, checked, calendarEntry)}
                      />
                      <span>{new Date(calendarEntry?.date).toLocaleDateString()}</span>
                    </p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>
                      <span>{calendarEntry.payrollCodeUserFriendlyDescription}</span>
                    </p>
                  </Grid>
                  <Grid xs={3} item>
                    <p>{calendarEntry.timeFrameType.value}</p>
                  </Grid>
                  <Grid xs={1.5} item>
                    {calendarEntry.timeOrderedType.key === DEFAULT_BASED_ON_WORKSCHEDULE &&
                    calendarEntry.numberOfHours === 0 ? (
                      <Tooltip title={t('flyin.calendar-entry.basedonworkschedule')}>
                        <IconButton sx={{ m: 0, p: 0 }}>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <p>{calendarEntry.numberOfHours}</p>
                    )}
                  </Grid>
                </label>
              ))}
            </FormGroup>
          )}

          {personalCalendarDays && personalCalendarDays.length > 0 && selectedCalendarEntries.length > 0 && (
            <Grid xs={12} mt={2} container justifyContent="space-between">
              <Box>
                <FormFileUpload
                  sx={12}
                  name="documents"
                  buttonText={t('documentcomponent.button.title')}
                  accept={[ACCEPTED_UPLOAD_TYPES.JPEG, ACCEPTED_UPLOAD_TYPES.PNG, ACCEPTED_UPLOAD_TYPES.PDF]}
                />
              </Box>

              <Box>
                <Button
                  variant="outlined"
                  disabled={deletePersonalCalendarDayMutation.isLoading}
                  color="error"
                  size="small"
                  onClick={handleDeletePersonalCalendarDays}
                >
                  {t('flyin.calendarentries.deleteentries')}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={isLoadingUpload} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddEditCalendarEntries
