import {
  useGetAllContractsQuery,
  useGetCompanyCarHistoriesByContractIdQuery,
  useGetContractByIdQuery,
  useGetPersonByIdQuery
} from '@epix-web-apps/core'
import { Box, Divider, IconButton, Link, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { CompanyCar, DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import {
  EditContractAdditionalInformation,
  EditContractMobility,
  EditContractPaymentInformation,
  EditContractPeriod,
  EditContractType
} from '../../contract-components'
import { useFlyIn } from '@epix-web-apps/ui'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface ContractsOverviewTabProps {}

export function ContractsOverviewTab(props: ContractsOverviewTabProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<ContractDetailPageParams>()
  const { openFlyIn } = useFlyIn()
  const navigate = useNavigate()
  const { data: getPersonById } = useGetPersonByIdQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const person = getPersonById?.personById
  const { data: getContractById } = useGetContractByIdQuery(
    {
      contractId: params?.contractid || ''
    },
    {
      enabled: !!params?.contractid
    }
  )
  const { refetch: refetchAllContracts } = useGetAllContractsQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const { data: getCompanyCarHistories, refetch: refetchAllCompanyCarHistories } =
    useGetCompanyCarHistoriesByContractIdQuery(
      {
        contractId: params.contractid || ''
      },
      {
        enabled: !!params.contractid
      }
    )
  const contract = getContractById?.contractById
  const contractPaymentInfoOverride =
    contract?.iban || contract?.bic || contract?.bankSortCode || contract?.bankAccount || contract?.accountHolder
  const companyCarHistories = getCompanyCarHistories?.companyCarHistories.filter(
    c => new Date(c.endDate) > new Date() || c.endDate === null
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        <Box sx={{ flex: 1 }}>
          <DetailBox
            title={t('contractdetailpage.contractperiod.title')}
            actionIcon={
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditContractPeriod />,
                    callbackAfterClose: () => refetchAllContracts
                  })
                }
              >
                <EditIcon />
              </IconButton>
            }
          >
            {contract?.durationType?.value && (
              <DetailBoxRow>
                <p>
                  {t('form.field.duration')}:<span className="bold"> {contract?.durationType?.value}</span>{' '}
                  {contract?.icpSubContractDuration?.name && `(${contract?.icpSubContractDuration?.name})`}
                </p>
              </DetailBoxRow>
            )}
            <DetailBoxRow>
              <p>
                {t('form.field.period')}:
                <span className="bold">
                  {' '}
                  {new Date(contract?.startDate).toLocaleDateString()} -{' '}
                  {contract?.endDate ? new Date(contract?.endDate).toLocaleDateString() : '...'}
                </span>
              </p>
            </DetailBoxRow>
            {contract?.fixedTermEndDate && (
              <DetailBoxRow>
                <p>
                  {t('form.field.fixedtermenddate')}:
                  <span className="bold"> {new Date(contract.fixedTermEndDate).toLocaleDateString()}</span>
                </p>
              </DetailBoxRow>
            )}
            {contract?.probationEndDate && (
              <DetailBoxRow>
                <p>
                  {t('form.field.probationaryperiodenddate')}:
                  <span className="bold"> {new Date(contract.probationEndDate).toLocaleDateString()}</span>
                </p>
              </DetailBoxRow>
            )}
            {contract?.endReasonType?.value && (
              <DetailBoxRow>
                <p>
                  {t('form.field.endreason')}:<span className="bold"> {contract?.endReasonType.value}</span>{' '}
                  {contract?.icpSubContractEndReason?.name && `(${contract?.icpSubContractEndReason?.name})`}
                </p>
              </DetailBoxRow>
            )}
          </DetailBox>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '2rem'
        }}
      >
        <Box sx={{ flex: 1 }}>
          <DetailBox
            title={t('contractdetailpage.contracttype.title')}
            actionIcon={
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditContractType />
                  })
                }
              >
                <EditIcon />
              </IconButton>
            }
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <DetailBoxRow>
                  <p>
                    {t('form.field.employer')}: <span className="bold">{contract?.employer?.companyName}</span> (
                    {contract?.employer?.number})
                  </p>
                </DetailBoxRow>
                {contract?.employeeNumber && (
                  <DetailBoxRow>
                    <p>
                      {t('form.field.employeenumber')}: {contract?.employeeNumber}
                    </p>
                  </DetailBoxRow>
                )}
                {contract?.payGroup?.id && (
                  <DetailBoxRow>
                    <p>
                      {t('form.field.paygroup')}: {contract?.payGroup?.name} ({contract?.payGroup?.code})
                    </p>
                  </DetailBoxRow>
                )}
                {contract?.icp.payrollProvider && (
                  <DetailBoxRow>
                    <p>
                      {t('form.field.payrollprovider')}: <span className="bold">{contract?.icp.payrollProvider}</span> (
                      {contract?.icp.code})
                    </p>
                  </DetailBoxRow>
                )}
                {contract?.employeeType && (
                  <DetailBoxRow>
                    <p>
                      {t('contractdetailpage.employeepopulationtype')}:
                      <span className="bold"> {contract?.employeeType?.value}</span>{' '}
                      {contract?.icpSubEmployeeType?.name && `(${contract?.icpSubEmployeeType?.name})`}
                      {contract?.employeeType?.value && contract?.populationType?.value && <span> / </span>}
                      <span className="bold"> {contract?.populationType?.value}</span>{' '}
                      {contract?.icpSubPopulationType?.name && `(${contract?.icpSubPopulationType?.name})`}
                    </p>
                  </DetailBoxRow>
                )}
                {contract?.subjectedToTaxesAbroad && (
                  <DetailBoxRow>
                    <p>
                      {t('contractdetailpage.subjectedtotaxesabroad')}: {contract?.countryOfTaxation?.name}
                    </p>
                  </DetailBoxRow>
                )}
                {contract?.subjectedToSocialSecurityAbroad && (
                  <DetailBoxRow>
                    <p>
                      {t('contractdetailpage.subjectedtosocialsecurityabroad')}:{' '}
                      {contract?.countryForSocialSecurityCalculation?.name}
                    </p>
                  </DetailBoxRow>
                )}
              </Box>
            </Box>
          </DetailBox>

          <DetailBox
            title={t('contractdetailpage.additionalinfo.title')}
            actionIcon={
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditContractAdditionalInformation />
                  })
                }
              >
                <EditIcon />
              </IconButton>
            }
          >
            {contract?.department && (
              <DetailBoxRow>
                <p>
                  {t('form.field.department')}: {contract.department}
                </p>
              </DetailBoxRow>
            )}
            {contract?.costCenter && (
              <DetailBoxRow>
                <p>
                  {t('form.field.costcenter')}: {contract.costCenter}
                </p>
              </DetailBoxRow>
            )}
            {(contract?.fte || contract?.fte === 0) && (
              <DetailBoxRow>
                <p>
                  {t('form.field.fte')}: {contract.fteWorkSchedule ?? contract.fte}
                </p>
              </DetailBoxRow>
            )}
            {contract?.branchUnit && (
              <DetailBoxRow>
                <p>
                  {t('form.field.branchunit')}: {contract.branchUnit.description}
                </p>
              </DetailBoxRow>
            )}
            {contract?.workPermitType && (
              <DetailBoxRow>
                <p>
                  {t('form.field.workpermittype')}: {contract.workPermitType.value}
                </p>
              </DetailBoxRow>
            )}
            {contract?.workPermitStartDate && (
              <DetailBoxRow>
                <p>
                  {t('form.field.workpermitstartdate')}: {new Date(contract?.workPermitStartDate).toLocaleDateString()}
                </p>
              </DetailBoxRow>
            )}
            {contract?.workPermitEndDate && (
              <DetailBoxRow>
                <p>
                  {t('form.field.workpermitenddate')}: {new Date(contract?.workPermitEndDate).toLocaleDateString()}
                </p>
              </DetailBoxRow>
            )}
            {contract?.notes && (
              <DetailBoxRow>
                <p>
                  {t('form.field.notes')}: {contract.notes}
                </p>
              </DetailBoxRow>
            )}
          </DetailBox>
        </Box>

        <Box sx={{ flex: 1 }}>
          <DetailBox
            title={t('contractdetailpage.paymentinfo.title')}
            actionIcon={
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditContractPaymentInformation />
                  })
                }
              >
                <EditIcon />
              </IconButton>
            }
          >
            {((!contractPaymentInfoOverride && person?.accountHolder) || contract?.accountHolder) && (
              <DetailBoxRow>
                <p>
                  {t('form.field.accountholder')}:{' '}
                  {contractPaymentInfoOverride ? contract?.accountHolder : person?.accountHolder}
                </p>
              </DetailBoxRow>
            )}
            {((!contractPaymentInfoOverride && person?.bankAccount) || contract?.bankAccount) && (
              <DetailBoxRow>
                <p>
                  {t('form.field.bankaccount')}:{' '}
                  {contractPaymentInfoOverride ? contract?.bankAccount : person?.bankAccount}
                </p>
              </DetailBoxRow>
            )}
            {((!contractPaymentInfoOverride && person?.iban) || contract?.iban) && (
              <DetailBoxRow>
                <p>
                  {t('form.field.iban')}: {contractPaymentInfoOverride ? contract?.iban : person?.iban}
                </p>
              </DetailBoxRow>
            )}
            {((!contractPaymentInfoOverride && person?.bic) || contract?.bic) && (
              <DetailBoxRow>
                <p>
                  {t('form.field.bic')}: {contractPaymentInfoOverride ? contract?.bic : person?.bic}
                </p>
              </DetailBoxRow>
            )}
            {((!contractPaymentInfoOverride && person?.bankSortCode) || contract?.bankSortCode) && (
              <DetailBoxRow>
                <p>
                  {t('form.field.sortcode')}:{' '}
                  {contractPaymentInfoOverride ? contract?.bankSortCode : person?.bankSortCode}
                </p>
              </DetailBoxRow>
            )}
          </DetailBox>

          {contract?.employer?.hasCompanyCars && (
            <DetailBox
              title={t('contractdetailpage.mobility.title')}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: <EditContractMobility />,
                      callbackAfterClose: () => refetchAllCompanyCarHistories
                    })
                  }
                >
                  <EditIcon />
                </IconButton>
              }
            >
              {companyCarHistories?.length === 0 && (
                <DetailBoxRow>
                  <p>
                    {person?.firstName} {t('contractdetailpage.nocar')}{' '}
                  </p>
                </DetailBoxRow>
              )}

              {companyCarHistories?.map((companyCarHistory, index) => (
                <Box key={index}>
                  <DetailBoxRow>
                    <p>
                      {`${person?.firstName} ${t('contractdetailpage.usesa')} `}
                      <Link
                        sx={{
                          color: theme.palette.common.black,
                          fontWeight: 'bold'
                        }}
                        onClick={() => navigate(CompanyCar.ID(`${companyCarHistory.companyCar.id}`))}
                      >
                        <span>
                          {`${companyCarHistory.companyCar.carBrandType} `}
                          {companyCarHistory.companyCar.model && (
                            <span>{`${companyCarHistory.companyCar.model} `}</span>
                          )}
                          {`(${companyCarHistory.companyCar.licensePlate})`}
                        </span>
                      </Link>
                      {` ${t('contractdetailpage.since')} `}
                      {new Date(companyCarHistory.startDate).toLocaleDateString()}
                    </p>
                  </DetailBoxRow>

                  <DetailBoxRow>
                    <p>
                      <Trans
                        i18nKey="contractdetailpage.cartype"
                        values={{ cartype: companyCarHistory.carType.value }}
                        components={{ bold: <span className="bold" /> }}
                      />
                      {companyCarHistory.companyCar.endDate && (
                        <span>
                          {` ${t('contractdetailpage.leaseendson')} ${new Date(
                            companyCarHistory.companyCar.endDate
                          ).toLocaleDateString()}`}
                        </span>
                      )}
                    </p>
                  </DetailBoxRow>

                  {(companyCarHistory.companyCar.co2 || companyCarHistory.companyCar.co2 === 0) && (
                    <p>
                      {t('contractdetailpage.co2')}{' '}
                      <span className="bold">{` ${companyCarHistory.companyCar.co2}`}</span>
                    </p>
                  )}

                  {companyCarHistory.companyCar.carCategory && (
                    <DetailBoxRow>
                      <p>
                        <Trans
                          i18nKey="contractdetailpage.carcategory"
                          values={{
                            carcategorydescription: companyCarHistory.companyCar.carCategoryDescription
                          }}
                          components={{ bold: <span className="bold" /> }}
                        />
                      </p>
                    </DetailBoxRow>
                  )}

                  {companyCarHistory.distanceHomeOffice && (
                    <DetailBoxRow>
                      <p>
                        <Trans
                          i18nKey="contractdetailpage.distancehomeoffice"
                          values={{
                            distancehomeoffice: companyCarHistory.distanceHomeOffice
                          }}
                          components={{ bold: <span className="bold" /> }}
                        />
                      </p>
                    </DetailBoxRow>
                  )}

                  {companyCarHistory.notes && (
                    <>
                      <span>&nbsp;</span>
                      <DetailBoxRow>
                        <p>
                          <span className="bold">{t('contractdetailpage.notes')}: </span>
                          {companyCarHistory.notes}
                        </p>
                      </DetailBoxRow>
                    </>
                  )}
                  <Divider sx={{ my: '0.5rem' }} />
                </Box>
              ))}
            </DetailBox>
          )}
        </Box>
      </Box>
    </>
  )
}

export default ContractsOverviewTab
