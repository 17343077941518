import { Box, Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  BOOLEAN_STRING_KEYS,
  useGetContractByIdQuery,
  useGetSubContractDurationTypesQuery,
  useGetSubEndReasonTypesQuery,
  useUpdateContractPeriodMutation,
  FormSelectOption,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import {
  FormContainer,
  FormSelect,
  FormGridLayout,
  FormRadioGroup,
  IFormRadioOption,
  useFlyIn,
} from '@epix-web-apps/ui'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditContractPeriodProps {}

export function EditContractPeriod(props: EditContractPeriodProps) {
  const { t } = useTranslation()
  const editContractPeriodSchema = object({
    durationId: string().optional().nullable(),
    subDurationId: string().optional().nullable(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    probationEndDate: date().optional().nullable(),
    fixedTermEndDate: date().optional().nullable(),
    reasonEndDateId: string().optional().nullable(),
    subReasonEndDateId: string().optional().nullable(),
    countedAsStarter: boolean(),
    countedAsLeaver: boolean(),
    contractEndInitiatedByCompany: string()
  })
    .refine(data => (data.endDate ? data.endDate >= data.startDate : !data.endDate), {
      message: t('form.validation.effectiveenddateafterstartdate'),
      path: ['endDate']
    })
    .refine(data => (data.fixedTermEndDate ? data.fixedTermEndDate >= data.startDate : !data.fixedTermEndDate), {
      message: t('form.validation.fixedtermenddateafterstartdate'),
      path: ['fixedTermEndDate']
    })
  type EditContractPeriodForm = TypeOf<typeof editContractPeriodSchema>
  const params = useParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const { data: getContractById, refetch: refetchContractById } = useGetContractByIdQuery(
    {
      contractId: params?.contractid || ''
    },
    {
      enabled: !!params?.contractid
    }
  )
  const contract = getContractById?.contractById
  const { contractDurationOptions, contractEndReasonOptions } = formTypeSelectOptions
  const mutation = useUpdateContractPeriodMutation()

  const contractEndInitiatedByOptions: Array<IFormRadioOption> = [
    {
      id: BOOLEAN_STRING_KEYS.FALSE,
      label: t('form.label.employee')
    },
    {
      id: BOOLEAN_STRING_KEYS.TRUE,
      label: t('form.label.company')
    }
  ]

  const form = useForm<EditContractPeriodForm>({
    resolver: zodResolver(editContractPeriodSchema),
    defaultValues: {
      durationId: contract?.durationType?.key,
      subDurationId: contract?.icpSubContractDuration?.id,
      startDate: contract?.startDate ? new Date(contract.startDate) : undefined,
      endDate: contract?.endDate ? new Date(contract.endDate) : null,
      probationEndDate: contract?.probationEndDate ? new Date(contract.probationEndDate) : null,
      fixedTermEndDate: contract?.fixedTermEndDate ? new Date(contract.fixedTermEndDate) : null,
      reasonEndDateId: contract?.endReasonType?.key,
      subReasonEndDateId: contract?.icpSubContractEndReason?.id,
      countedAsStarter: contract?.countedAsStarter,
      countedAsLeaver: contract?.countedAsLeaver,
      contractEndInitiatedByCompany: contract?.contractEndInitiatedByCompany
        ? BOOLEAN_STRING_KEYS.TRUE
        : BOOLEAN_STRING_KEYS.FALSE
    }
  })
  const { control } = form
  const watchedDurationId = useWatch({ control, name: `durationId` })
  const watchedReasonEndDateId = useWatch({ control, name: `reasonEndDateId` })
  const watchedCountedAsLeaver = useWatch({ control, name: 'countedAsLeaver' })

  const { data: getSubContractDurationTypes } = useGetSubContractDurationTypesQuery(
    {
      configurationKey: contract?.icp?.configurationKey || '',
      contractDurationTypeKey: watchedDurationId
    },
    {
      enabled: !!watchedDurationId && !!contract?.icp?.id,
      suspense: false
    }
  )
  const { data: getSubEndReasonTypes } = useGetSubEndReasonTypesQuery(
    {
      configurationKey: contract?.icp?.configurationKey || '',
      endReasonTypeKey: watchedReasonEndDateId
    },
    {
      enabled: !!watchedReasonEndDateId && !!contract?.icp?.id,
      suspense: false
    }
  )

  const handleOnSubmit = async (contractPeriod: EditContractPeriodForm) => {
    await mutation
      .mutateAsync({
        updateContractPeriodCommand: {
          contractId: params.contractid || '',
          contractDurationTypeKey: contractPeriod.durationId && contractPeriod.durationId,
          icpSubContractDurationId: (contractPeriod.subDurationId && contractPeriod.subDurationId) || undefined,
          startDate: contractPeriod.startDate,
          endDate: contractPeriod.endDate,
          probationEndDate: contractPeriod.probationEndDate,
          fixedTermEndDate: contractPeriod.fixedTermEndDate,
          contractEndReasonTypeKey: contractPeriod.reasonEndDateId && contractPeriod.reasonEndDateId,
          icpSubContractEndReasonId:
            (contractPeriod.subReasonEndDateId && contractPeriod.subReasonEndDateId) || undefined,
          countedAsStarter: contractPeriod.countedAsStarter,
          countedAsLeaver: contractPeriod.countedAsLeaver,
          contractEndInitiatedByCompany: contractPeriod.countedAsLeaver
            ? contractPeriod.contractEndInitiatedByCompany === BOOLEAN_STRING_KEYS.TRUE
            : null
        }
      })
      .then(() => {
        refetchContractById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontractperiod.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="durationId"
          label={t('form.field.durationtype')}
          options={contractDurationOptions}
          onChange={() => form.resetField('subDurationId', { defaultValue: null })}
        />

        {getSubContractDurationTypes && getSubContractDurationTypes?.subContractDurations.length > 0 && (
          <FormSelect
            sx={12}
            name="subDurationId"
            label={t('form.field.subdurationtype')}
            options={getSubContractDurationTypes?.subContractDurations.map(x => new FormSelectOption(x.id, x.name))}
          />
        )}

        <FormDatepicker sx={6} name="startDate" label={t('form.field.startdate')} />

        <FormDatepicker sx={6} name="probationEndDate" label={t('form.field.probationenddate')} />

        <FormDatepicker sx={6} name="fixedTermEndDate" label={t('form.field.fixedtermenddate')} />

        <FormDatepicker sx={6} name="endDate" label={t('form.field.effectiveenddate')} />

        <FormSelect
          sx={12}
          name="reasonEndDateId"
          label={t('form.field.endreasontype')}
          options={contractEndReasonOptions}
          onChange={e => form.resetField('subReasonEndDateId', { defaultValue: null })}
        />

        {getSubEndReasonTypes && getSubEndReasonTypes?.subContractEndReasons.length > 0 && (
          <FormSelect
            sx={12}
            name="subReasonEndDateId"
            label={t('form.field.subendreasontype')}
            options={getSubEndReasonTypes?.subContractEndReasons.map(x => new FormSelectOption(x.id, x.name))}
          />
        )}

        <FormSwitch sx={12} name="countedAsStarter" label={t('form.field.countasstarter')} />
        <FormSwitch sx={12} name="countedAsLeaver" label={t('form.field.countasleaver')} />

        {watchedCountedAsLeaver && (
          <Box sx={{ mt: 2, mx: 3 }}>
            <FormRadioGroup
              sx={12}
              name="contractEndInitiatedByCompany"
              label={t('form.field.leaveinitiatedby')}
              options={contractEndInitiatedByOptions}
            />
          </Box>
        )}
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditContractPeriod
