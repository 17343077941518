import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormDatepicker } from '@epix-web-apps/ui'
import {
  DEFAULT_VALUE_TYPE,
  DEFAULT_VALUE_TYPE_CHECK,
  FormSelectOption,
  OrderDirection,
  UPDATE_OPERATION_TYPE,
  formTypeSelectOptions,
  getEnumAsKeyValue,
  useGetActiveValueTypesPayComponentByPayrollCodeIdQuery,
  useGetAllPayrollCodesByEmployerIdQuery
} from '@epix-web-apps/core'
import { FormSelect } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormRadioGroup, FormRadioOption } from '@epix-web-apps/ui'
import { FormSwitch } from '../../../form-components/form-switch'
import { Grid, Typography, useTheme } from '@mui/material'

/* eslint-disable-next-line */
export interface PaycomponentParametersProps {
  employerId: string
}

export const DATE_INPUT_FORMAT = 'MMMM yyyy'

export enum DECIMALS {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4'
}

export function PaycomponentParameters({ employerId }: PaycomponentParametersProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  const { affectedContractsParameterTypeOptions } = formTypeSelectOptions

  const { groupOptions, subGroupOptions, salaryChangedReasonOptions, updateOperationTypeOptions } =
    formTypeSelectOptions

  const { data: getPayrollcodes } = useGetAllPayrollCodesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      orderDirection: OrderDirection.Asc,
      payrollCodeFilterModel: {
        showSalaryCodes: true
      }
    },
    {
      suspense: false
    }
  )

  const { control, resetField } = useFormContext()

  const watchedGroupTypeKey = useWatch({ control, name: `groupTypeKey` })
  const watchedSubGroupTypeKey = useWatch({ control, name: `subGroupTypeKey` })
  const watchedPayrollcodeId = useWatch({ control, name: `payrollCodeId` })
  const watchedUpdateOperationTypeKey = useWatch({
    control,
    name: `updateOperationTypeKey`
  })

  const { data: allValueTypePayComponents } = useGetActiveValueTypesPayComponentByPayrollCodeIdQuery(
    {
      payrollCodeId: watchedPayrollcodeId || ''
    },
    {
      enabled: !!watchedPayrollcodeId,
      suspense: false
    }
  )

  return (
    <Grid container spacing={15}>
      <Grid item xs={12} lg={6}>
        <FormGridLayout>
          <Grid item xs={12} lg={12}>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {t('paycomponentprocess.parameters.payrollcodedescription')}
            </Typography>
          </Grid>
          <FormSelect sx={12} name="groupTypeKey" label={`${t('form.field.grouptype')} *`} options={groupOptions} />

          <FormSelect
            sx={12}
            name="subGroupTypeKey"
            label={`${t('form.field.subgrouptype')} *`}
            options={subGroupOptions.filter(s => s.id.includes(watchedGroupTypeKey))}
          />

          <FormSelect
            sx={6}
            name="payrollCodeId"
            onChange={(_, payRollCode) => {
              const payRollCodeValueTypeKey = getPayrollcodes?.allPayrollCodesByEmployerId.data.find(
                x => x.id === payRollCode?.id
              )?.valueType?.key
              const groupTypeKey = getPayrollcodes?.allPayrollCodesByEmployerId.data.find(x => x.id === payRollCode?.id)
                ?.group.key
              const subGroupTypeKey = getPayrollcodes?.allPayrollCodesByEmployerId.data.find(
                x => x.id === payRollCode?.id
              )?.subGroup?.key
              resetField('valueTypeKey', {
                defaultValue:
                  payRollCodeValueTypeKey !== DEFAULT_VALUE_TYPE_CHECK ? payRollCodeValueTypeKey : DEFAULT_VALUE_TYPE
              })
              resetField('subGroupTypeKey', {
                defaultValue: subGroupTypeKey
              })
              resetField('groupTypeKey', {
                defaultValue: groupTypeKey
              })
            }}
            label={`${t('form.field.payrollcode')} *`}
            options={
              watchedGroupTypeKey || watchedSubGroupTypeKey
                ? getPayrollcodes?.allPayrollCodesByEmployerId.data
                    .filter(c => c.group.key.includes(watchedGroupTypeKey))
                    .filter(c => c.subGroup?.key.includes(watchedSubGroupTypeKey))
                    .map(x => new FormSelectOption(x.id, `${x.code} -  ${x.userFriendlyDescription ?? x.description}`))
                : getPayrollcodes?.allPayrollCodesByEmployerId.data.map(
                    x => new FormSelectOption(x.id, `${x.code} -  ${x.userFriendlyDescription ?? x.description}`)
                  )
            }
          />

          <FormSelect
            sx={6}
            name="valueTypeKey"
            label={`${t('form.field.valuetypes')} *`}
            options={allValueTypePayComponents?.activeValueTypePayComponentByPayrollCodeId?.map(option => ({
              id: option.key,
              label: option.value,
              active: true
            }))}
          />
        </FormGridLayout>

        <FormGridLayout>
          <Grid item xs={12} lg={12}>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {t('paycomponentprocess.parameters.reasondescription')}
            </Typography>
          </Grid>

          <FormSelect
            sx={12}
            name="reasonSalaryChangeTypeKey"
            label={t('form.field.reasonsalarychange')}
            options={salaryChangedReasonOptions}
          />

          <FormInput sx={12} name="comment" label={`${t('form.field.comment')}`} multiline />
        </FormGridLayout>
      </Grid>

      <Grid item xs={12} lg={6}>
        <FormGridLayout>
          <Grid item xs={12} lg={12}>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {t('paycomponentprocess.parameters.referencedatedescription')}
            </Typography>
          </Grid>

          <FormDatepicker
            sx={12}
            name="referencePeriod"
            inputFormat={DATE_INPUT_FORMAT}
            label={`${t('form.field.referencedate')} *`}
            views={['year', 'month']}
          />

          <FormRadioGroup
            sx={12}
            name="affectedContractsParameterTypeKey"
            label={t('form.field.affectedcontractsparametertype')}
            options={affectedContractsParameterTypeOptions.map(x => {
              return new FormRadioOption(x.id, x.label)
            })}
          />

          <Grid item xs={12} lg={12}>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {t('paycomponentprocess.parameters.datesdescription')}
            </Typography>
          </Grid>

          <FormDatepicker
            sx={6}
            name="startDate"
            inputFormat={DATE_INPUT_FORMAT}
            label={`${t('form.field.startdate')} *`}
            views={['year', 'month']}
          />

          <FormDatepicker
            sx={6}
            name="endDate"
            inputFormat={DATE_INPUT_FORMAT}
            label={t('form.field.enddate')}
            views={['year', 'month']}
          />

          <FormSwitch
            sx={12}
            name="excludeContractsBasedOnReferenceField"
            label={t('form.field.excludecontractsbasedonreferencefield')}
          />
        </FormGridLayout>

        <FormGridLayout>
          <FormRadioGroup
            sx={12}
            name="updateOperationTypeKey"
            label={t('form.field.updateoperationtype')}
            options={updateOperationTypeOptions.map(x => {
              return new FormRadioOption(x.id, x.label)
            })}
          />

          <FormSwitch
            sx={12}
            name="hasExistingValueOnStartDateRequired"
            label={t('form.field.hasexistingvalueonstartdaterequired')}
          />

          {watchedUpdateOperationTypeKey == UPDATE_OPERATION_TYPE.MULTIPLICATION && (
            <>
              <Grid item container xs={12} lg={12} columnGap={2}>
                <FormInput
                  sx={6}
                  name="multiplicator"
                  placeholder={'0.00'}
                  label={`${t('form.field.multiplicator')} *`}
                />
                <Typography
                  sx={{
                    fontStyle: 'italic',
                    color: theme.palette.text.secondary
                  }}
                >
                  {t('paycomponentprocess.parameters.multiplicatordescription')}
                </Typography>
              </Grid>

              <FormSelect
                sx={12}
                name="decimals"
                label={`${t('form.field.decimals')} *`}
                options={getEnumAsKeyValue(DECIMALS).map(x => new FormSelectOption(x.value, x.value))}
              />
            </>
          )}

          {watchedUpdateOperationTypeKey == UPDATE_OPERATION_TYPE.VALUE && (
            <>
              <FormInput sx={6} name="value" placeholder={'0.00'} label={`${t('form.field.value')} *`} />

              <FormSwitch sx={12} name="useFTE" label={t('form.field.usefte')} />
            </>
          )}
        </FormGridLayout>
      </Grid>
    </Grid>
  )
}

export default PaycomponentParameters
