import { Box, Checkbox, FormControlLabel } from '@mui/material'

export interface CheckboxTreeNode {
  id: string
  key: string | null
  value: string
  checked: boolean
  children: CheckboxTreeNode[]
}

interface CheckBoxTreeProps {
  treeNode: CheckboxTreeNode
  filters: string[]
  handleChange: (e: string[]) => void
}

function CheckBoxTree({ treeNode, filters, handleChange }: CheckBoxTreeProps) {
  const checked = isChecked(treeNode)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
        label={treeNode.value}
        control={
          <Checkbox
            checked={checked}
            indeterminate={!checked && treeNode.children.some(c => c.checked)}
            onChange={e => {
              let result: (string | null)[] = []

              if (e.target.checked) {
                result = [...filters, ...mapTreeNodeToKeys([treeNode])]
              } else {
                result = [...filters.filter(k => !mapTreeNodeToKeys([treeNode]).includes(k))]
              }

              handleChange([...new Set(result)].filter(r => r) as string[])
            }}
          />
        }
      />
      {treeNode.children.map(c => (
        <Box sx={{ ml: 3 }} key={c.id}>
          <CheckBoxTree treeNode={c} handleChange={handleChange} filters={filters} />
        </Box>
      ))}
    </Box>
  )
}

function isChecked(tree: CheckboxTreeNode) {
  return tree.children.length === 0 ? tree.checked : tree.children.every(c => c.checked)
}

function mapTreeNodeToKeys(tree: CheckboxTreeNode[]): (string | null)[] {
  return tree.flatMap(t => [t.key, ...mapTreeNodeToKeys(t.children)])
}

export default CheckBoxTree
