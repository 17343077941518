import { useTranslation } from 'react-i18next'
import {
  FormGridLayout,
  FormInput,
  FormRadioGroup,
  FormRadioOption,
  IFormRadioOption,
  useFlyIn
} from '@epix-web-apps/ui'
import { TypeOf, object, string, date, boolean } from 'zod'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  ABSENCE_TYPE_KEYS,
  BOOLEAN_STRING_KEYS,
  REQUEST_MINIMUM_TYPE_KEYS,
  REQUEST_POLICY_TIME_TYPE_KEYS,
  formTypeSelectOptions,
  regex,
  useGetAllIcsBusyStatusTypesQuery,
  useGetObfuscatedTypesQuery,
  useGetRequestPolicyByIdQuery,
  useUpdateRequestPolicyMutation
} from '@epix-web-apps/core'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography, useTheme } from '@mui/material'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormColorPicker } from '../../form-components/form-color-picker'
import { FormSwitch } from '../../form-components/form-switch'

export const DATE_INPUT_FORMAT_VISIBILITY = 'MMMM'

/* eslint-disable-next-line */
export interface EditRequestPolicyGeneralProps {
  requestPolicyId: string
}

export function EditRequestPolicyGeneral({ requestPolicyId }: EditRequestPolicyGeneralProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const theme = useTheme()

  const { data: getIcsBusyStatusTypes } = useGetAllIcsBusyStatusTypesQuery()

  const isObfuscatedOptions: Array<IFormRadioOption> = [
    {
      id: BOOLEAN_STRING_KEYS.TRUE,
      label: t('editrequestpolicy.form.field.isobfuscated')
    },
    {
      id: BOOLEAN_STRING_KEYS.FALSE,
      label: t('editrequestpolicy.form.field.isnotobfuscated')
    }
  ]

  const editRequestPolicyGeneralSchema = object({
    internalName: string({
      required_error: t('form.validation.internalnamerequired'),
      invalid_type_error: t('form.validation.internalnamerequired')
    }).min(1, t('form.validation.internalnamerequired')),
    userFriendlyName: string({
      required_error: t('form.validation.userfriendlynamerequired'),
      invalid_type_error: t('form.validation.userfriendlynamerequired')
    }).min(1, t('form.validation.userfriendlynamerequired')),
    absenceType: string({
      required_error: t('form.validation.absencetyperequired'),
      invalid_type_error: t('form.validation.absencetyperequired')
    }).min(1, t('form.validation.absencetyperequired')),
    requestMinimumType: string({
      required_error: t('form.validation.requestminimumtyperequired'),
      invalid_type_error: t('form.validation.requestminimumtyperequired')
    }).min(1, t('form.validation.requestminimumtyperequired')),
    requestPolicyTimeType: string({
      required_error: t('form.validation.requestpolicytimetyperequired'),
      invalid_type_error: t('form.validation.requestpolicytimetyperequired')
    }).min(1, t('form.validation.requestpolicytimetyperequired')),
    obfuscated: string(),
    obfuscatedType: string({
      required_error: t('form.validation.obfuscatedtyperequired'),
      invalid_type_error: t('form.validation.obfuscatedtyperequired')
    }).min(1, t('form.validation.obfuscatedtyperequired')),
    colorHex: regex(
      string({
        invalid_type_error: t('form.validation.colorHex')
      })
    ),
    visibilityDate: date({
      required_error: t('form.validation.visibilycounter'),
      invalid_type_error: t('form.validation.visibilycounter')
    }),
    attachIcs: boolean(),
    icsBusyStatusType: string().optional()
  }).refine(
    data =>
      data.attachIcs
        ? data.icsBusyStatusType
        : string({
            required_error: t('form.validation.icsbusystatustyperequired'),
            invalid_type_error: t('form.validation.icsbusystatustyperequired')
          }),
    {
      message: t('form.validation.icsbusystatustyperequired'),
      path: ['icsBusyStatusType']
    }
  )

  const { data: getRequestPolicyById, refetch: refetchRequestPolicyById } = useGetRequestPolicyByIdQuery({
    id: requestPolicyId
  })

  const { data: getObfuscatedOptions } = useGetObfuscatedTypesQuery()

  type EditRequestPolicyGeneralForm = TypeOf<typeof editRequestPolicyGeneralSchema>

  const form = useForm<EditRequestPolicyGeneralForm>({
    resolver: zodResolver(editRequestPolicyGeneralSchema),
    defaultValues: {
      internalName: getRequestPolicyById?.requestPolicyById.internalName,
      userFriendlyName: getRequestPolicyById?.requestPolicyById.userFriendlyName,
      absenceType: getRequestPolicyById?.requestPolicyById.absenceType.key,
      requestMinimumType: getRequestPolicyById?.requestPolicyById.requestMinimumType.key,
      obfuscated:
        getRequestPolicyById?.requestPolicyById.obfuscated === true
          ? BOOLEAN_STRING_KEYS.TRUE
          : BOOLEAN_STRING_KEYS.FALSE,
      requestPolicyTimeType: getRequestPolicyById?.requestPolicyById.requestPolicyTimeType.key,
      obfuscatedType: getRequestPolicyById?.requestPolicyById.obfuscatedType?.key,
      colorHex: getRequestPolicyById?.requestPolicyById.colourCodeHex,
      visibilityDate: getRequestPolicyById?.requestPolicyById.visibilityDate
        ? new Date(getRequestPolicyById?.requestPolicyById.visibilityDate)
        : undefined,
      attachIcs: getRequestPolicyById?.requestPolicyById.attachIcsOnApprovalMailToEmployee ?? false,
      icsBusyStatusType: getRequestPolicyById?.requestPolicyById.icsBusyStatusType?.key
    }
  })

  const { control } = form

  const { absenceOptions, requestPolicyTimeOptions, requestMinimumOptions, obfuscatedOptions } = formTypeSelectOptions

  const updateMutation = useUpdateRequestPolicyMutation()

  const handleOnSubmit = async (updatedRequestPolicy: EditRequestPolicyGeneralForm) => {
    await updateMutation
      .mutateAsync({
        updateRequestPolicyCommand: {
          requestPolicyId: requestPolicyId,
          internalName: updatedRequestPolicy?.internalName,
          userFriendlyName: updatedRequestPolicy?.userFriendlyName,
          absenceTypeKey: updatedRequestPolicy?.absenceType,
          requestMinimumTypeKey: updatedRequestPolicy?.requestMinimumType,
          unlimited: getRequestPolicyById?.requestPolicyById.unlimited,
          canBeRequested: getRequestPolicyById?.requestPolicyById.canBeRequested,
          negativeBalance: getRequestPolicyById?.requestPolicyById.negativeBalance,
          negativeBalanceCount: getRequestPolicyById?.requestPolicyById.negativeBalanceCount,
          needsApproval: getRequestPolicyById?.requestPolicyById.needsApproval,
          obfuscated: updatedRequestPolicy?.obfuscated === BOOLEAN_STRING_KEYS.TRUE,
          requestPolicyTimeTypeKey: updatedRequestPolicy?.requestPolicyTimeType,
          obfuscatedTypeKey: updatedRequestPolicy.obfuscated ? updatedRequestPolicy?.obfuscatedType : null,
          colourCodeHex: updatedRequestPolicy.obfuscated ? updatedRequestPolicy.colorHex : null,
          visibilityCounter: new Date(1, updatedRequestPolicy.visibilityDate.getMonth(), 1),
          cancellationAllowedTypeKey: getRequestPolicyById?.requestPolicyById.cancellationAllowedType?.key,
          attachIcsOnApprovalMailToEmployee: updatedRequestPolicy.attachIcs,
          icsBusyStatusTypeKey: updatedRequestPolicy.icsBusyStatusType
        }
      })
      .then(() => {
        refetchRequestPolicyById()
        closeFlyIn()
      })
  }

  const watchObfuscated = useWatch({ control, name: 'obfuscated' })
  const watchRequestPolicyTimeType = useWatch({
    control,
    name: 'requestPolicyTimeType'
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.requestpolicy.general.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="internalName" label={`${t('editrequestpolicy.form.field.internalname')}*`} />

        <FormInput sx={12} name="userFriendlyName" label={`${t('editrequestpolicy.form.field.userfriendlyname')}*`} />

        <FormRadioGroup
          sx={12}
          name="requestPolicyTimeType"
          label={`${t('editrequestpolicy.form.field.requestpolicytimetype')}`}
          options={requestPolicyTimeOptions.sort((previous, next) =>
            previous.id === REQUEST_POLICY_TIME_TYPE_KEYS.BASED_ON_WORKSCHEDULE ? -1 : 1
          )}
          onChange={(e, value) => {
            if (value === REQUEST_POLICY_TIME_TYPE_KEYS.ADDITIONAL) {
              form.setValue('absenceType', ABSENCE_TYPE_KEYS.HOURS)
            }
          }}
        />

        <FormRadioGroup
          sx={12}
          name="absenceType"
          label={`${t('editrequestpolicy.form.field.absencetype')}`}
          disabled={watchRequestPolicyTimeType === REQUEST_POLICY_TIME_TYPE_KEYS.ADDITIONAL}
          options={absenceOptions}
        />

        <FormRadioGroup
          sx={12}
          name="requestMinimumType"
          label={`${t('editrequestpolicy.form.field.requestminimumtype')}`}
          options={requestMinimumOptions.sort((previous, next) =>
            previous.id === REQUEST_MINIMUM_TYPE_KEYS.LESS_THAN_FULL_DAY ? -1 : 1
          )}
        />

        <FormRadioGroup
          sx={12}
          name="obfuscated"
          label={t('editrequestpolicy.form.field.obfuscatedlabel')}
          options={isObfuscatedOptions}
        />

        {watchObfuscated === BOOLEAN_STRING_KEYS.TRUE && (
          <>
            <Typography color={theme.palette.text.secondary} paddingTop={2} paddingLeft={2}>
              {t('editrequestpolicy.form.field.obfuscatedtype.title')}
            </Typography>
            <FormSelect
              sx={12}
              name="obfuscatedType"
              label={t('editrequestpolicy.form.field.obfuscatedtypes')}
              onChange={(_, obfuscatedTypeKey) => {
                const defaultColor = getObfuscatedOptions?.obfuscatedTypes.find(
                  x => x.key === obfuscatedTypeKey?.id
                )?.colourCodeHex
                form.resetField('colorHex', { defaultValue: defaultColor })
              }}
              options={obfuscatedOptions}
            />

            <FormColorPicker sx={12} name="colorHex" label={t('editrequestpolicy.form.field.colourhexcode')} />
          </>
        )}

        <Typography color={theme.palette.text.secondary} paddingTop={2} paddingLeft={2}>
          {t('editrequestpolicy.form.field.visibility.title')}
        </Typography>
        <FormDatepicker
          sx={12}
          name="visibilityDate"
          openTo="month"
          inputFormat={DATE_INPUT_FORMAT_VISIBILITY}
          label={`${t('form.field.visibilitycounter')} *`}
          views={['month']}
        />

        <FormSwitch
          sx={12}
          name="attachIcs"
          onChange={() => {
            form.resetField('icsBusyStatusType', {
              defaultValue: ''
            })
          }}
          label={t('editrequestpolicy.form.field.attachics')}
        />

        {form.getValues('attachIcs') && (
          <FormRadioGroup
            sx={12}
            name="icsBusyStatusType"
            label={`${t('editrequestpolicy.form.field.icsbusystatustype')}`}
            options={getIcsBusyStatusTypes?.allIcsBusyStatusTypes.map(x => new FormRadioOption(x.key, x.value))}
          />
        )}
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditRequestPolicyGeneral
