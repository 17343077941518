import { Box, Button, Divider, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material'
import { EpixDatePicker } from '../../epix-date-picker'
import { useMemo, useState } from 'react'
import {
  ProductivityAnalyticsTabs,
  ProductivityFilters
} from '../../../pages/productivity-analytics-page/productivity-analytics-page'
import { FilterSelectBox, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import ListComponent from '../../labour-cost-analytics-components/list-component'
import {
  DEFAULT_FLYIN_WIDTH,
  FormSelectOption,
  formTypeSelectOptions,
  OrderDirection,
  ProductivityAnalyticsViewPeriods,
  useGetAllEmployersByIcpIdsQuery,
  useGetAllPayGroupsByEmployerIdQuery,
  useGetGroupTypesQuery,
  useGetSubGroupTypesQuery
} from '@epix-web-apps/core'
import CheckBoxTree, { CheckboxTreeNode } from '../../filter-components/check-box-tree/check-box-tree'

const DEFAULT_GROUP_TYPE_SELECTED = 'E01'

export interface FilterProductivityFlyinProps {
  filters: ProductivityFilters
  updateStore: (filters: ProductivityFilters) => void
  currentTab: ProductivityAnalyticsTabs
}

function FilterProductivityFlyin({ filters: initialFilters, updateStore, currentTab }: FilterProductivityFlyinProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [filters, setFilters] = useState(initialFilters)

  const { productivityViewPeriodTypeOptions } = formTypeSelectOptions
  const { icpFilters } = useGlobalPersistedStore()

  const { data: paygroupData } = useGetAllPayGroupsByEmployerIdQuery(
    { employerId: filters.employer ?? '', orderDirection: OrderDirection.Asc },
    { enabled: !!filters.employer, suspense: false }
  )
  const paygroupOptions = paygroupData?.allPayGroupsByEmployerId.map(p => new FormSelectOption(p.id, p.name))

  const { data: employerData } = useGetAllEmployersByIcpIdsQuery({ icps: icpFilters })
  const employerOptions = employerData?.employersByIcpIds.map(e => new FormSelectOption(e.id, e.companyName))

  const { data: getAllSubGroups } = useGetSubGroupTypesQuery()
  const { data: getAllGroupTypes } = useGetGroupTypesQuery()

  const tree = useMemo(() => {
    const subGroupTypeTree = getAllGroupTypes?.groupTypes.map(g => {
      const children = getAllSubGroups?.subGroupTypes
        .filter(s => s.parentKey === g.typeModel.key)
        .map(s => {
          return {
            id: s.key,
            key: s.key,
            value: s.value,
            checked: filters.subgroups ? filters.subgroups.includes(s.key) : false,
            children: []
          } as CheckboxTreeNode
        })

      return {
        id: g.typeModel.key,
        key: null,
        value: g.typeModel.value,
        checked: false,
        children: children ?? []
      } as CheckboxTreeNode
    })
    return subGroupTypeTree ?? []
  }, [getAllGroupTypes, getAllSubGroups, filters.subgroups])

  function handleFilter() {
    updateStore(filters)
    closeFlyIn()
  }

  function handleReset() {
    updateStore({
      ...filters,
      date: null,
      viewPeriod: ProductivityAnalyticsViewPeriods.MONTH,
      orderDirection: OrderDirection.Desc,
      employer: null,
      paygroup: null,
      subgroups:
        getAllSubGroups?.subGroupTypes.filter(s => s.parentKey === DEFAULT_GROUP_TYPE_SELECTED).map(s => s.key) ?? []
    })
    closeFlyIn()
  }

  return (
    <>
      <Box sx={{ width: DEFAULT_FLYIN_WIDTH }}>
        <Typography color={theme.palette.primary.main} variant="h4">
          {t('analytics.filters.title')}
        </Typography>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <EpixDatePicker
              onChange={e => (e ? setFilters({ ...filters, date: e }) : null)}
              label={t('productivity.filters.viewpoint')}
              value={filters.date ?? new Date()}
              renderInput={params => (
                <TextField sx={{ width: '100%', font: theme.palette.common.white, mb: 2 }} size="small" {...params} />
              )}
              componentsProps={{
                actionBar: {
                  actions: ['today']
                }
              }}
            />

            {(currentTab === ProductivityAnalyticsTabs.OVERVIEW || currentTab === ProductivityAnalyticsTabs.PERSON) && (
              <FilterSelectBox
                id={'viewPeriod'}
                label={t('productivity.filters.viewperiod')}
                keys={productivityViewPeriodTypeOptions}
                value={filters.viewPeriod}
                handleChange={(e: string) => {
                  setFilters({ ...filters, viewPeriod: e as ProductivityAnalyticsViewPeriods })
                }}
              />
            )}

            {(currentTab === ProductivityAnalyticsTabs.PERSON || currentTab === ProductivityAnalyticsTabs.BRADFORD) && (
              <RadioGroup
                sx={{ mb: 2 }}
                onChange={(e, orderDirection) =>
                  setFilters({ ...filters, orderDirection: orderDirection as OrderDirection })
                }
              >
                <span>
                  {currentTab === ProductivityAnalyticsTabs.PERSON
                    ? t('productivity.filters.orderdirection')
                    : t('productivity.filters.factor')}
                </span>
                <Box>
                  <Radio value={OrderDirection.Asc} checked={filters.orderDirection === OrderDirection.Asc} />
                  <span>{t('form.field.ascending')}</span>
                </Box>
                <Box>
                  <Radio value={OrderDirection.Desc} checked={filters.orderDirection === OrderDirection.Desc} />
                  <span>{t('form.field.descending')}</span>
                </Box>
              </RadioGroup>
            )}

            <FilterSelectBox
              id={'employeeId'}
              label={t('form.field.employer')}
              keys={employerOptions}
              value={filters.employer}
              handleChange={(e: string) => {
                setFilters({ ...filters, employer: e, paygroup: null })
              }}
            />

            {!!filters.employer && (
              <FilterSelectBox
                id="paygroup"
                label={t('form.field.paygroup')}
                keys={paygroupOptions}
                value={filters.paygroup}
                handleChange={(e: string) => {
                  setFilters({ ...filters, paygroup: e })
                }}
              />
            )}
          </Box>

          {currentTab !== ProductivityAnalyticsTabs.BRADFORD && (
            <>
              <Divider sx={{ borderColor: theme.palette.divider }} />
              <ListComponent listItemText={t('analytics.sidebar.payroll')}>
                {tree.map(t => (
                  <CheckBoxTree
                    key={t.id}
                    treeNode={t}
                    filters={filters.subgroups}
                    handleChange={(e: string[]) => {
                      setFilters({
                        ...filters,
                        subgroups: e
                      })
                    }}
                  />
                ))}
              </ListComponent>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          py: 2,
          zIndex: 10
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button variant="contained" onClick={handleFilter}>
              {t('analytics.flyin.filter')}
            </Button>
            <Button variant="outlined" onClick={closeFlyIn}>
              {t('analytics.flyin.cancel')}
            </Button>
          </Box>
          <Button variant="text" onClick={handleReset}>
            {t('analytics.flyin.reset')}
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default FilterProductivityFlyin
