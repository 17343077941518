import { Trans, useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import { IconButton } from '@mui/material'
import EditCompanyCarCompanyFinancialInformation from '../../edit-company-car-company-financial-information'
import EditIcon from '@mui/icons-material/Edit'
import { CompanyCarDetailModel } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface CompanyCarCompanyFinancialInformationProps {
  car?: CompanyCarDetailModel
  currencySymbol: string | undefined
  preferredCurrencySymbol: string | undefined
}

export function CompanyCarCompanyFinancialInformation({
  car,
  currencySymbol,
  preferredCurrencySymbol
}: CompanyCarCompanyFinancialInformationProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  return (
    <DetailBox
      title={t('companycardetailpage.companyfinancialinformation.title')}
      actionIcon={
        <IconButton
          size="small"
          onClick={() =>
            openFlyIn({
              content: <EditCompanyCarCompanyFinancialInformation />
            })
          }
        >
          <EditIcon />
        </IconButton>
      }
    >
      {(car?.monthlyCost || car?.monthlyCost === 0) && (
        <DetailBoxRow>
          <p>
            <Trans
              i18nKey="companycardetailpage.monthlycost"
              values={{
                monthlycost: car?.monthlyCost,
                currency: currencySymbol
              }}
              components={{ bold: <span className="bold" /> }}
            />
            {currencySymbol !== preferredCurrencySymbol && (
              <Trans
                i18nKey="companycardetailpage.monthlycostrate"
                values={{
                  monthlycostrate: car?.monthlyCostRate,
                  preferredcurrency: preferredCurrencySymbol
                }}
              />
            )}
          </p>
        </DetailBoxRow>
      )}
      {(car?.tco || car?.tco === 0) && (
        <>
          <DetailBoxRow>
            <p>
              <Trans
                i18nKey="companycardetailpage.tco"
                values={{ tco: car?.tco, currency: currencySymbol }}
                components={{ bold: <span className="bold" /> }}
              />
              {currencySymbol !== preferredCurrencySymbol && (
                <Trans
                  i18nKey="companycardetailpage.tcorate"
                  values={{
                    tcorate: car?.tcoRate,
                    preferredcurrency: preferredCurrencySymbol
                  }}
                />
              )}
            </p>
          </DetailBoxRow>
          <span>&nbsp;</span>
        </>
      )}
      {car?.isPersonalContribution && (
        <>
          <DetailBoxRow>
            <p>
              <Trans
                i18nKey="companycardetailpage.haspersonalcontribution"
                components={{ bold: <span className="bold" /> }}
              />
            </p>
          </DetailBoxRow>
          <DetailBoxRow>
            {(car?.personalContributionPercentage || car?.personalContributionPercentage === 0) && (
              <p>
                <Trans
                  i18nKey="companycardetailpage.personalcontributionpercentage"
                  values={{
                    personalcontributionpercentage: car?.personalContributionPercentage
                  }}
                  components={{ bold: <span className="bold" /> }}
                />
              </p>
            )}
          </DetailBoxRow>
          {(car?.oneTimeContribution || car?.oneTimeContribution === 0) && (
            <DetailBoxRow>
              <p>
                <Trans
                  i18nKey="companycardetailpage.onetimecontribution"
                  values={{
                    onetimecontribution: car?.oneTimeContribution,
                    currency: currencySymbol
                  }}
                  components={{ bold: <span className="bold" /> }}
                />
                {currencySymbol !== preferredCurrencySymbol && (
                  <Trans
                    i18nKey="companycardetailpage.onetimecontributionrate"
                    values={{
                      onetimecontributionrate: car?.oneTimeContributionRate,
                      preferredcurrency: preferredCurrencySymbol
                    }}
                  />
                )}
              </p>
            </DetailBoxRow>
          )}
          <DetailBoxRow>
            {(car?.monthlyContribution || car?.monthlyContribution === 0) && (
              <p>
                <Trans
                  i18nKey="companycardetailpage.monthlycontribution"
                  values={{
                    monthlycontribution: car?.monthlyContribution,
                    currency: currencySymbol
                  }}
                  components={{ bold: <span className="bold" /> }}
                />
                {currencySymbol !== preferredCurrencySymbol && (
                  <Trans
                    i18nKey="companycardetailpage.monthlycontributionrate"
                    values={{
                      monthlycontributionrate: car?.monthlyContributionRate,
                      preferredcurrency: preferredCurrencySymbol
                    }}
                  />
                )}
              </p>
            )}
          </DetailBoxRow>
          <span>&nbsp;</span>
        </>
      )}
      {car?.hasFuelCard && (
        <DetailBoxRow>
          <p>
            <Trans i18nKey="companycardetailpage.hasfuelcard" components={{ bold: <span className="bold" /> }} />
            {car?.fuelCardNumber && (
              <Trans
                i18nKey="companycardetailpage.fuelcardnumber"
                values={{ fuelcardnumber: car?.fuelCardNumber }}
                components={{ bold: <span className="bold" /> }}
              />
            )}
          </p>
        </DetailBoxRow>
      )}
    </DetailBox>
  )
}

export default CompanyCarCompanyFinancialInformation
