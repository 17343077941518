import {
  useGetAsbGroupContractDetailsQuery,
  GraphqlError,
  useUpdateAsbGroupContractDetailTreximaMutation,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import {
  useFlyIn,
  FormContainer,
  FormGridLayout,
  FormSelect,
  FormErrorList,
  FormActionButtons,
  FormInput
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditAsbgroupTreximaProps {
  contractId: string | undefined
}

export function EditAsbgroupTrexima({ contractId }: EditAsbgroupTreximaProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const theme = useTheme()

  const treximaCodesWebsite = 'https://www.obory-vzdelani.cz/'

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } = useGetAsbGroupContractDetailsQuery({
    contractId: contractId || ''
  })

  const { asbEducationTypeOptions, asbContractClassificationTypeOptions } = formTypeSelectOptions
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editASBGroupTreximaSchema = object({
    isSubjectToTreximaReporting: boolean(),
    educationTypeKey: string().optional().nullable(),
    educationCode: string().optional().nullable(),
    workPositionCode: string().optional().nullable(),
    contractClassificationTypeKey: string().optional().nullable(),
    isManagementLevel: boolean()
  })

  type EditASBGroupTreximaForm = TypeOf<typeof editASBGroupTreximaSchema>

  const updateMutation = useUpdateAsbGroupContractDetailTreximaMutation()

  const trexima = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupTreximaModel

  const form = useForm<EditASBGroupTreximaForm>({
    resolver: zodResolver(editASBGroupTreximaSchema),
    defaultValues: {
      isSubjectToTreximaReporting: trexima?.isSubjectToTreximaReporting ?? false,
      educationTypeKey: trexima?.educationType?.key,
      educationCode: trexima?.educationCode,
      workPositionCode: trexima?.workPositionCode,
      contractClassificationTypeKey: trexima?.contractClassificationType?.key,
      isManagementLevel: trexima?.isManagementLevel ?? false
    }
  })

  const handleOnSubmit = async (trexima: EditASBGroupTreximaForm) => {
    await updateMutation
      .mutateAsync({
        asbGroupContractDetailTreximaCommand: {
          id: partnerSpecificDetails?.asbGroupContractDetailByContractId.id || '',
          isSubjectToTreximaReporting: trexima?.isSubjectToTreximaReporting,
          asbGroupEducationTypeKey: trexima?.isSubjectToTreximaReporting ? trexima?.educationTypeKey : null,
          educationCode: trexima?.isSubjectToTreximaReporting ? trexima?.educationCode : null,
          workPosition: trexima?.isSubjectToTreximaReporting ? trexima?.workPositionCode : null,
          asbGroupContractClassificationTypeKey: trexima?.isSubjectToTreximaReporting
            ? trexima?.contractClassificationTypeKey
            : null,
          isManagementLevel: trexima?.isSubjectToTreximaReporting ? trexima?.isManagementLevel : false
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  const { control } = form
  const watchedSubjectToTreximaReporting = useWatch({
    control,
    name: `isSubjectToTreximaReporting`
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editasbgrouptrexima.title')}</Typography>
      <FormGridLayout>
        <FormSwitch sx={12} name="isSubjectToTreximaReporting" label={t('form.field.issubjecttotreximareporting')} />

        {watchedSubjectToTreximaReporting && (
          <>
            <FormSelect
              sx={12}
              name="educationTypeKey"
              label={t('form.field.education')}
              options={asbEducationTypeOptions}
            />
            <FormInput sx={12} name="educationCode" label={t('form.field.educationcode')} />
            <FormInput sx={12} name="workPositionCode" label={t('form.field.workpositioncode')} />
            <Typography
              sx={{
                pl: '1.25rem',
                pt: '0.25rem',
                fontSize: 'small',
                color: theme.palette.text.secondary
              }}
            >
              {t('flyin.editasbgrouptrexima.info')}:&nbsp;
              <Link href={treximaCodesWebsite} target="_blank" rel="noopener">
                {treximaCodesWebsite}
              </Link>
            </Typography>
            <FormSelect
              sx={12}
              name="contractClassificationTypeKey"
              label={t('form.field.contractclassification')}
              options={asbContractClassificationTypeOptions}
            />
            <FormSwitch sx={12} name="isManagementLevel" label={t('form.field.managementlevel')} />
          </>
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditAsbgroupTrexima
