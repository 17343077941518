import { FormSelectOption } from '@epix-web-apps/core'
import { TextField, Autocomplete } from '@mui/material'

/* eslint-disable-next-line */
export interface FilterSelectBoxProps {
  keys: any | null
  value: any
  handleChange: any
  id: string
  label: string
}

export function FilterSelectBox({ keys, value, handleChange, id, label }: FilterSelectBoxProps) {
  return (
    <Autocomplete
      sx={{ marginBottom: '1rem' }}
      disablePortal
      id={id}
      value={(keys && keys.find((item: any) => item.id === value)) || null}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      options={keys}
      onChange={(event: any, newValue: FormSelectOption | null) => handleChange(newValue?.id)}
      renderInput={params => <TextField {...params} size="small" label={label} />}
    />
  )
}

export default FilterSelectBox
