import { useCallback, useEffect, useState } from 'react'
import { Box, Link, Typography, useTheme } from '@mui/material'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import styled from '@emotion/styled'
import { SelfService, useGlobalStore } from '@epix-web-apps/ui'
import {
  ABSENCE_REQUEST_STATUS_TYPE,
  ToBackendFormatedDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  ToRouteDateFormat,
  WithoutTime,
  useGetTeamsForMeForTeamStructureQuery,
  useGetAllTeamsWithRoleForMeQuery,
  useGetCalendarForDateRangeByTeamStructureQuery,
  useGetAllContractsByPersonIdAndTeamStructureQuery,
  useGetCalendarForContractIdsAndTeamStructureQuery,
  useGetWorkScheduleHistoriesByPersonIdAndTeamStructureQuery,
  isInCurrentMonth
} from '@epix-web-apps/core'
import { useQueries } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface MonthlyCalendarProps {
  personId: string
  date: Date
  setDate: (date: Date) => void
  specificPersonPage?: boolean
  isEditorRole?: boolean
}

export type MonthlyCalendarQueryParams = {
  teamid: string
  id: string
}

export const CalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */

  .react-calendar * {
    font-family: 'Poppins', sans-serif;
  }

  .react-calendar {
    width: 100%;
    max-width: 100%;
    background-color: #ffff;
    color: #22222;
    border-color: #f5f5ff;
    min-height: 100%;
  }

  .react-calendar__navigation button {
    color: #00044e;
    min-width: 50px;
    background: none;
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #000;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
  }

  abbr[title] {
    text-decoration: none;
  }

  .react-calendar__month-view__days .react-calendar__tile--active {
    background-color: #ffffff;
  }

  .react-calendar__month-view__days .react-calendar__tile--now {
    background-color: #f5f5ff;
    font-weight: bold;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #00044e;
  }

  .react-calendar__tile--range {
    background: #f5f5ff;
    color: #000;
    border-radius: 0;
  }

  .day-tile {
    height: 5px;
  }

  .day-tile-right {
    height: 5px;
    margin-left: auto;
  }

  .day-box {
    border-radius: 5px;
    overflow: hidden;
    display: flex;
  }

  .react-calendar__tile {
    padding-top: 5%;
    padding-bottom: 5%;
  }
`

export const useCalendarDataQueries = (contractIds: Array<string>, date: Date) => {
  const queryResults = useQueries(
    contractIds?.map((id: string) => {
      const firstDay = getFirstDayOfMonth(date)
      const lastDay = getLastDayOfMonth(date)
      return {
        queryKey: ['contract', id, firstDay, lastDay],
        queryFn: useGetCalendarForDateRangeByTeamStructureQuery.fetcher({
          contractId: id,
          startDate: firstDay,
          endDate: lastDay
        })
      }
    })
  )

  const refetchAll = useCallback(() => {
    queryResults.forEach(result => result.refetch())
  }, [queryResults])

  return {
    queryResults,
    refetchAll
  }
}

export const TileContent = ({ date, events }: any) => {
  const matchingDate = events.filter(
    (item: any) => ToBackendFormatedDate(new Date(item.start)) === ToBackendFormatedDate(new Date(date))
  )

  const dayPart1Events = matchingDate.filter((event: any) => event.timeFrameType === 'DAY_PART_1')
  const dayPart2Events = matchingDate.filter((event: any) => event.timeFrameType === 'DAY_PART_2')
  const fullDayEvents = matchingDate.filter((event: any) => event.timeFrameType === 'FULL_DAY')

  return (
    <div className={'day-box'}>
      {dayPart1Events.map((x: any, index: number) => (
        <div
          key={index}
          style={{
            backgroundColor: x.colorCodeHex,
            background:
              x.absenceRequestType === ABSENCE_REQUEST_STATUS_TYPE.REQUESTED
                ? `repeating-linear-gradient(45deg, ${x.colorCodeHex}, ${x.colorCodeHex} 2px, #FFFFFF 2px, #FFFFFF 3px)`
                : x.colorCodeHex,
            width: `calc(50%/${dayPart1Events.length})`
          }}
          className={'day-tile'}
        ></div>
      ))}

      {fullDayEvents.map((x: any, index: number) => (
        <div
          key={index}
          style={{
            backgroundColor: x.colorCodeHex,
            background:
              x.absenceRequestType === ABSENCE_REQUEST_STATUS_TYPE.REQUESTED
                ? `repeating-linear-gradient(45deg, ${x.colorCodeHex}, ${x.colorCodeHex} 2px, #FFFFFF 2px, #FFFFFF 3px)`
                : x.colorCodeHex,
            width: dayPart1Events.length > 0 || dayPart2Events.length > 0 ? '50%' : '100%'
          }}
          className={'day-tile'}
        ></div>
      ))}
      {dayPart2Events.map((x: any, index: number) => (
        <div
          key={index}
          style={{
            backgroundColor: x.colorCodeHex,
            background:
              x.absenceRequestType === ABSENCE_REQUEST_STATUS_TYPE.REQUESTED
                ? `repeating-linear-gradient(45deg, ${x.colorCodeHex}, ${x.colorCodeHex} 2px, #FFFFFF 2px, #FFFFFF 3px)`
                : x.colorCodeHex,
            width: `calc(50%/${dayPart2Events.length})`
          }}
          className={dayPart1Events.length === 0 ? 'day-tile-right' : 'day-tile'}
        ></div>
      ))}
    </div>
  )
}

export function MonthlyCalendar({
  personId,
  date,
  setDate,
  specificPersonPage = false,
  isEditorRole = false
}: MonthlyCalendarProps) {
  const params = useParams<MonthlyCalendarQueryParams>()
  const theme = useTheme()
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const [events, setEvents] = useState<any[]>([])
  const navigate = useNavigate()

  const { data: getAllContracts } = useGetAllContractsByPersonIdAndTeamStructureQuery(
    {
      personId: personId
    },
    {
      enabled: !!personId
    }
  )

  const { data: calendarData } = useGetCalendarForContractIdsAndTeamStructureQuery({
    contractIds: getAllContracts?.allContractsByPersonIdAndTeamStructure.map(c => c.contractId) || '',
    startDate: ToBackendFormatedDate(date),
    endDate: ToBackendFormatedDate(getLastDayOfMonth(date))
  })

  const { data: getTeamsByPersonId } = useGetTeamsForMeForTeamStructureQuery()

  const { data: getAllTeamsWithRole } = useGetAllTeamsWithRoleForMeQuery()

  const teamsByPersonId = getTeamsByPersonId?.teamsForMeForTeamStructure || []
  const teamWithRole = getAllTeamsWithRole?.allTeamsWithRoleForMe || []

  const allTeams = teamsByPersonId.concat(teamWithRole).filter((team, index, self) => {
    return self.findIndex(t => t.id === team.id) === index
  })

  const teamId = allTeams[0]?.id

  const { data: getWorkScheduleHistoriesByPersonId } = useGetWorkScheduleHistoriesByPersonIdAndTeamStructureQuery()

  const contractIds = getAllContracts?.allContractsByPersonIdAndTeamStructure.map(c => c.contractId)
  const queryResults = useCalendarDataQueries(contractIds || [], date)
  const isFetchingQueryResults = queryResults.queryResults.every(x => !x.isFetching && !x.isPreviousData)

  useEffect(() => {
    if (isFetchingQueryResults) {
      const allData = queryResults.queryResults.map(x => x.data?.calendarDaysByContractIdAndTeamStructure)
      const flattenedArray: any[] = allData.reduce((acc, curr) => acc.concat(curr), [])
      setEvents(
        flattenedArray?.flatMap((day: any) => {
          return day.calculatedEntries.map((calculatedEntry: any, index: number) => {
            return {
              id: index,
              start: day.date,
              end: day.date,
              title:
                calculatedEntry.numberOfHours + ' ' + calculatedEntry.payrollCodeUserFriendlyDescription ??
                calculatedEntry.payrollCodeDescription,
              colorCodeHex: calculatedEntry.colourCodeHex,
              absenceRequestType: calculatedEntry.absenceRequestCalendarModel?.absenceRequestType?.key,
              timeFrameType: calculatedEntry.timeFrameType.key
            }
          })
        }) || []
      )
    }
  }, [isFetchingQueryResults])

  const navigateDayClick = (e: Date) => {
    const calendarDataForClickedDay = calendarData?.calendarDaysByContractIdsAndTeamStructure.flatMap(entry =>
      entry.days.filter(d => WithoutTime(new Date(d.date)) === WithoutTime(new Date(e))).map(day => day)
    )

    const allCollectiveWorkSchedules = calendarDataForClickedDay?.flatMap(entry => entry.collectiveSchedule)
    const hasCollectiveScheduleDate = allCollectiveWorkSchedules?.some(
      (schedule: any) => schedule?.collectiveScheduleDays?.length > 0
    )

    const allPersonalCalendarDays = calendarDataForClickedDay?.flatMap(entry => entry.personalCalendarDays)
    const hasPersonalCalendarDays = allPersonalCalendarDays?.some(schedule => schedule != null)

    const allWorkschedules = calendarDataForClickedDay?.flatMap(entry => entry.workScheduleDay)
    const hasWorkSchedule = allWorkschedules?.some((schedule: any) => schedule?.dayDefinitionEntries?.length > 0)

    if (
      !hasCollectiveScheduleDate &&
      !hasPersonalCalendarDays &&
      hasWorkSchedule &&
      (me?.personId === personId || isEditorRole)
    ) {
      if (me?.personId === personId) {
        navigate(
          SelfService.SELFSERVICE_REQUEST_ABSENCE_DATE(
            `${specificPersonPage ? personId : me?.personId}`,
            `${ToRouteDateFormat(new Date(e))}`
          )
        )
      } else {
        navigate(
          SelfService.REQUEST_ABSENCE_TEAMID_ID_DATE(
            `${params.teamid}`,
            `${specificPersonPage ? personId : me?.personId}`,
            `${ToRouteDateFormat(new Date(e))}`
          )
        )
      }
    } else {
      if (me?.personId === personId) {
        navigate(
          SelfService.DAY_DETAIL_DAY_PERSONID(
            `${ToRouteDateFormat(new Date(e))}`,
            `${specificPersonPage ? personId : me?.personId}`
          )
        )
      } else {
        navigate(
          SelfService.DAY_DETAIL_DAY_ID_TEAMID(
            `${ToRouteDateFormat(new Date(e))}`,
            `${specificPersonPage ? personId : me?.personId}`,
            `${params.teamid}`
          )
        )
      }
    }
  }

  const CalendarMonth = () => {
    if (getWorkScheduleHistoriesByPersonId?.workScheduleHistoriesByPersonIdAndTeamStructure.some(x => x)) {
      return (
        <CalendarContainer>
          <Calendar
            locale={me?.locale.locale}
            onActiveStartDateChange={value => {
              if (value.activeStartDate === null) return
              // drillUp = when center month on calendar navigation is clicked
              if (value.action === 'drillUp' || isInCurrentMonth(value.activeStartDate)) {
                setDate(new Date())
              } else {
                setDate(getLastDayOfMonth(value.activeStartDate))
              }
            }}
            value={date}
            onClickDay={e => navigateDayClick(e)}
            tileContent={({ date }) => <TileContent events={events} date={date} />}
            view="month"
          />
        </CalendarContainer>
      )
    } else {
      return (
        <Typography textAlign="center" color={theme.palette.text.secondary}>
          {t('selfservice.teamcalendar.empty')}
        </Typography>
      )
    }
  }

  return (
    <Box minHeight={'100%'}>
      <CalendarMonth />
      {!specificPersonPage && teamId && (
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: 1 }}>
          <Link
            underline="always"
            onClick={() => {
              navigate(
                SelfService.TEAM_CALENDAR_TEAMID_ID_DATE(`${teamId}`, `${me?.personId}`, `${ToRouteDateFormat(date)}`)
              )
            }}
          >
            <Typography color={theme.palette.primary.main} fontSize="default" fontWeight="bold">
              {t('selfservice.teamcalendar.view')}
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  )
}

export default MonthlyCalendar
