import {
  FormSelectOption,
  formTypeSelectOptions,
  OrderDirection,
  useGetAllCountriesQuery,
  useGetAllPayrollCodesByEmployerIdQuery,
  useGetEmployerByIdQuery,
  useGetIcpByIdQuery
} from '@epix-web-apps/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Button, IconButton, MenuItem, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import { HeaderTitleNavigation } from '@epix-web-apps/ui'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import { useParams } from 'react-router-dom'
import { AddEditPayrollCode } from '../../components/payroll-code-components/add-edit-payroll-code'
import { AddEditSalarySettings } from '../../components/payroll-code-components/add-edit-salary-settings'
import { AddEditCalendarSettings } from '../../components/payroll-code-components/add-edit-calendar-settings'
import {
  FilterPayrollCodesFlyin,
  PayrollCodeFilters
} from '../../components/payroll-code-components/filter-payroll-codes-flyin'
import { usePayrollCodesPersistedStore } from '../../stores/payroll-codes-store'
import FilterChip from '../../components/filter-components/filter-chip/filter-chip'

/* eslint-disable-next-line */
export interface PayrollCodesPageProps {}

export type PayrollCodesDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function PayrollCodesPage(props: PayrollCodesPageProps) {
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const { t } = useTranslation()
  const params = useParams<PayrollCodesDetailPageParams>()
  const employerId = params.employerid!
  const icpId = params.id!
  const { openFlyIn } = useFlyIn()

  const store = usePayrollCodesPersistedStore()

  const filters: PayrollCodeFilters = {
    code: store.codeFilter,
    description: store.descriptionFilter,
    group: store.groupFilter,
    subGroup: store.subGroupFilter,
    showSalaryCodes: store.showSalaryCodesFilter,
    showCalendarCodes: store.showCalendarCodesFilter,
    usableInSelfService: store.usableInSelfServiceFilter
  }

  const { subGroupOptions, groupOptions } = formTypeSelectOptions
  const filterLabels = [
    groupOptions,
    subGroupOptions,
    [new FormSelectOption(filters.code, filters.code)],
    [new FormSelectOption(filters.description, filters.description)],
    [new FormSelectOption('showSalaryCodes', t('payrollcodespage.filters.showsalarycodes'))],
    [new FormSelectOption('showCalendarCodes', t('payrollcodespage.filters.showcalendarcodes'))],
    [new FormSelectOption('usableInSelfService', t('payrollcodespage.filters.availableinselfservice'))]
  ].flat()

  function updateStore(filters: PayrollCodeFilters) {
    store.setCodeFilter(filters.code ?? '')
    store.setDescriptionFilter(filters.description ?? '')
    store.setGroupFilter(filters.group)
    store.setSubGroupFilter(filters.subGroup)
    store.setShowSalaryCodesFilter(filters.showSalaryCodes)
    store.setShowCalendarCodesFilter(filters.showCalendarCodes)
    store.setUsableInSelfServiceFilter(filters.usableInSelfService)
    setPage(0)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [rowCount, setRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [payrollCodeId, setPayrollCodeId] = useState('')
  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setPayrollCodeId(rowParams.row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useGetIcpByIdQuery({
    icpId: icpId
  })
  const { data: getCountries } = useGetAllCountriesQuery()
  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName
  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('payrollcodespage.title')}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: 1,
              flexWrap: 'wrap',
              maxWidth: '35rem'
            }}
          >
            <FilterChip filters={filters} updateFilters={updateStore} filterLabels={filterLabels} />
          </Box>
          <Button
            variant="outlined"
            onClick={() =>
              openFlyIn({
                content: <FilterPayrollCodesFlyin filters={filters} updateStore={updateStore} />
              })
            }
          >
            {t('payrollcodespage.button.filters')}
          </Button>

          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddEditPayrollCode employerId={employerId} />,
                callbackAfterClose: () => refetchAllPayrollCodes
              })
            }
          >
            <AddIcon />
            {t('payrollcodespage.button.add-payrollcode')}
          </Button>
        </Box>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  const showColour = (rowParams: GridRenderCellParams) =>
    rowParams.value ? <CircleTwoToneIcon /> : <CircleTwoToneIcon sx={{ color: rowParams.row.colourCodeHex }} />
  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t('payrollcodespage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    { field: 'color', headerName: '', flex: 1, renderCell: showColour },
    {
      field: 'userfriendlydescription',
      headerName: t('payrollcodespage.datatable.column.description'),
      flex: 1
    },
    {
      field: 'grouptype',
      headerName: t('payrollcodespage.datatable.column.group'),
      flex: 1
    },
    {
      field: 'subgrouptype',
      headerName: t('payrollcodespage.datatable.column.subgroup'),
      flex: 1
    },
    {
      field: 'epixCode',
      headerName: t('payrollcodespage.datatable.column.epixcode'),
      flex: 1
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns?.[0].field, OrderDirection.Asc))
  const [page, setPage] = useState(0)

  const {
    data: payrollCodesData,
    isLoading: isLoadingPayrollCodes,
    refetch: refetchAllPayrollCodes
  } = useGetAllPayrollCodesByEmployerIdQuery(
    {
      employerId: employerId || '',
      payrollCodeFilterModel: {
        code: filters.code,
        description: filters.description,
        groupTypeKey: filters.group,
        subGroupKey: filters.subGroup,
        showCalendarCodes: filters.showCalendarCodes,
        showSalaryCodes: filters.showSalaryCodes,
        usableInSelfService: filters.usableInSelfService
      },
      offset: page * pageSize,
      limit: pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (payrollCodesData && payrollCodesData.allPayrollCodesByEmployerId) {
      const payrollCodes = payrollCodesData.allPayrollCodesByEmployerId
      setRowCount(payrollCodes.totalCount)
      setTableData(
        payrollCodes.data.map(row => {
          return {
            id: row.id,
            code: row.code,
            colourCodeHex: row.colourCodeHex,
            userfriendlydescription: row.userFriendlyDescription,
            grouptype: row.group.value,
            subgrouptype: row.subGroup?.value,
            epixCode: row.subGroup?.key
          }
        })
      )
    }
  }, [payrollCodesData])

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        isLoading={isLoadingPayrollCodes}
        defaultSortModel={sortModel}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditPayrollCode payrollCodeId={payrollCodeId} employerId={employerId} />,
              callbackAfterClose: () => refetchAllPayrollCodes
            })
          }
        >
          {t('payrollcodes.list.row.menu.edit-payrollcode')}
        </MenuItem>

        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditSalarySettings payrollCodeId={payrollCodeId} employerId={employerId} />,
              callbackAfterClose: () => refetchAllPayrollCodes
            })
          }
        >
          {t('payrollcodes.list.row.menu.edit-salary-settings')}
        </MenuItem>

        {getEmployerById?.employerById.hasCalendarManagement && (
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: <AddEditCalendarSettings payrollCodeId={payrollCodeId} employerId={employerId} />,
                callbackAfterClose: () => refetchAllPayrollCodes
              })
            }
          >
            {t('payrollcodes.list.row.menu.edit-calendar-settings')}
          </MenuItem>
        )}
      </DropdownMenu>
    </>
  )
}

export default PayrollCodesPage
