import { Box, Link, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { OnboardingLayout } from '../../../layouts'
import { useNavigate } from 'react-router-dom'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import { environment } from '../../../../../../src/environments/environment'
import { Import } from '@epix-web-apps/ui'
import { ROLE, isAuthorized, useGetMeQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface OnboardingWorkforceAnalyticsLayoutProps {}

export function OnboardingWorkforceAnalyticsLayout(props: OnboardingWorkforceAnalyticsLayoutProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: me } = useGetMeQuery()

  return (
    <>
      <OnboardingLayout title={t('analyticspage.onboarding.title')} icon={<StackedBarChartIcon />} />
      <Box
        sx={{
          marginLeft: '20%',
          marginRight: '20%'
        }}
      >
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          {t('analyticspage.onboarding.description')}
        </Typography>

        {isAuthorized(me?.me.roles, [ROLE.IMPORTDATA, ROLE.ADMIN]) && (
          <>
            <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
              <Trans
                i18nKey={t('analyticspage.onboarding.description2')}
                values={{ linktoimport: t('analyticspage.onboarding.here') }}
                components={{
                  tt: <Link underline="always" onClick={() => navigate(Import.IMPORT)} />
                }}
              />
            </Typography>
            <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
              <Trans
                i18nKey={t('analyticspage.onboarding.description3')}
                values={{
                  linktodocusaurus: t('analyticspage.onboarding.here')
                }}
                components={{
                  tt: (
                    <Link
                      underline="always"
                      target="_blank"
                      href={environment.docs.domainName + '/Import/import-csv/import-csv-people'}
                    />
                  )
                }}
              />
            </Typography>
          </>
        )}
      </Box>
    </>
  )
}

export default OnboardingWorkforceAnalyticsLayout
