import {
  DE_BUROPARTNER,
  NL_GENERIC,
  IT_GENERIC,
  useGetContractByIdQuery,
  DE_GENERIC,
  NL_PHOCUS,
  IT_LDP,
  CZ_GENERIC,
  CZ_ASBGROUP
} from '@epix-web-apps/core'
import DeBuropartner from './de-buropartner/de-buropartner'
import NlPhocus from './nl-phocus/nl-phocus'
import ItLdp from './it-ldp/it-ldp'
import CzAsbgroup from './cz-asbgroup'

/* eslint-disable-next-line */
export interface ProviderSpecificDetailsProps {
  contractId: string | undefined
}

export function ProviderSpecificDetails({ contractId }: ProviderSpecificDetailsProps) {
  const { data: getContractById } = useGetContractByIdQuery(
    {
      contractId: contractId || ''
    },
    {
      enabled: !!contractId
    }
  )
  const contract = getContractById?.contractById

  return (
    <>
      {(contract?.icp.configurationKey === DE_BUROPARTNER || contract?.icp.configurationKey === DE_GENERIC) && (
        <DeBuropartner contractId={contractId} />
      )}
      {(contract?.icp.configurationKey === NL_GENERIC || contract?.icp.configurationKey === NL_PHOCUS) && (
        <NlPhocus contractId={contractId} />
      )}
      {(contract?.icp.configurationKey === IT_GENERIC || contract?.icp.configurationKey === IT_LDP) && (
        <ItLdp contractId={contractId} />
      )}
      {(contract?.icp.configurationKey === CZ_GENERIC || contract?.icp.configurationKey === CZ_ASBGROUP) && (
        <CzAsbgroup contractId={contractId} />
      )}
    </>
  )
}

export default ProviderSpecificDetails
