import styled from '@emotion/styled'
import {
  useTheme,
  ToggleButtonGroup as OriginalToggleButtonGroup,
  ToggleButton as OriginalToggleButton,
  Box,
  Typography,
  IconButton,
  Paper,
  Divider,
  InputBase,
  CircularProgress
} from '@mui/material'
import { useState } from 'react'
import { DetailPageLayout } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import SendIcon from '@mui/icons-material/Send'
import AiCompanionHeader from '../../components/ai-companion-components/ai-companion-header'
import AiCompanionResults from '../../components/ai-companion-components/ai-companion-results'
import { CompanionSearchResponse, useGetSearchModesQuery, useSearchCompanionAiMutation } from '@epix-web-apps/core'

const ToggleButtonGroup = styled(OriginalToggleButtonGroup)<any>(() => {
  return {
    backgroundColor: 'none'
  }
})

const ToggleButton = styled(OriginalToggleButton)<any>(() => {
  const theme = useTheme()
  return {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '50% !important',
    width: '2.75rem',
    margin: '0 0.5rem 0 0 !important',
    padding: '9px',
    border: '0 !important',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.common.white,
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  }
})

const TypographyInfo = styled(Typography)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.text.secondary,
    fontSize: '0.8rem'
  }
})

export function AiCompanionPage() {
  const { t } = useTranslation()
  const countryDefinitions = ['BE', 'NL', 'FR', 'ES']
  const [countryDefinition, setCountryDefinition] = useState<string | undefined>(countryDefinitions[0])
  const [question, setQuestion] = useState<string>()

  const [response, setResponse] = useState<CompanionSearchResponse>()

  const handleSelectionChange = (event: React.MouseEvent<HTMLElement>, newCountryDefinition: string) => {
    setCountryDefinition(newCountryDefinition)
  }

  async function handleSearchClick() {
    await mutation
      .mutateAsync({
        searchAiCompanionCommand: { mode: countryDefinition ?? '', question: question ?? '' }
      })
      .then(r => {
        setResponse({
          answer: r.searchCompanion.answer,
          results: r.searchCompanion.results,
          question: r.searchCompanion.question
        })
      })
  }

  const mutation = useSearchCompanionAiMutation()

  const { data: searchModes } = useGetSearchModesQuery()

  return (
    <DetailPageLayout>
      {/* <DetailPageSidebar>
        <Box>
          <Typography variant="h6">
            {t('aicompanionpage.previous-searches.title')}
          </Typography>
          <Typography sx={{ fontSize: '0.9rem' }}>
            {t('aicompanionpage.previous-searches.today')}
          </Typography>
          <Box sx={{ marginBottom: 1, marginTop: 0.5 }} display={'flex'}>
            <CountryBadge>BE</CountryBadge>
            <Link sx={{ pl: 0.5 }}>
              How many vacation days has an employee?
            </Link>
            <ContentCopyIcon />
            <DeleteOutlineIcon />
          </Box>

          <Typography sx={{ fontSize: '0.9rem' }}>
            {t('aicompanionpage.previous-searches.yesterday')}
          </Typography>
        </Box>
      </DetailPageSidebar> */}
      <Box sx={{ pl: 1.5, pr: 1.5, width: '100%' }}>
        <AiCompanionHeader />
        {searchModes && (
          <ToggleButtonGroup
            value={countryDefinition}
            onChange={handleSelectionChange}
            aria-label="Country mode selection"
            exclusive={true}
            sx={{ mb: 1.5 }}
          >
            {searchModes.searchModes.map((searchMode, index) => (
              <ToggleButton
                key={index}
                value={searchMode.code}
                aria-label={searchMode.code}
                disabled={mutation.isLoading}
              >
                {searchMode.code}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}

        <Paper
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderRadius: 1
          }}
        >
          <InputBase
            id="companion-ai-search"
            placeholder={t('aicompanionpage.search.label')}
            multiline
            minRows={2}
            value={question}
            onChange={event => setQuestion(event.target.value)}
            fullWidth
            inputProps={{ 'aria-label': 'search companion ai' }}
            sx={{ padding: 2 }}
            disabled={mutation.isLoading}
          />
          <Divider sx={{ height: 50, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: '20px' }}
            onClick={handleSearchClick}
            disabled={mutation.isLoading || countryDefinition === null}
          >
            <SendIcon />
          </IconButton>
        </Paper>

        <TypographyInfo sx={{ mt: 0.75 }}>{t('aicompanionpage.search.disclaimer')}</TypographyInfo>

        {mutation.isLoading ? (
          <Box sx={{ m: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <AiCompanionResults response={response} />
        )}
      </Box>
    </DetailPageLayout>
  )
}

export default AiCompanionPage
