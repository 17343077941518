import { colorContrastRatioCalculator } from '@mdhnpm/color-contrast-ratio-calculator'

export type HEX = string

export const colors = [
  '#00044E',
  '#00C291',
  '#7AB4C2',
  '#008398',
  '#00B2A6',
  '#145A80',
  '#707070',
  '#324B51',
  '#0076B1',
  '#7fe1c8',
  '#f5f5ff'
]

export const isValidHexColor = (hex: string) => {
  const colorRegex = new RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$|^$')
  return hex.length !== 0 && colorRegex.test(hex)
}

export const FirstContrastThreshold = (baseColor: HEX, threshold: number, hexColors: HEX[]): HEX => {
  let highestRatio = 0
  let highestRatioColor = hexColors[0]

  if (!isValidHexColor(baseColor)) {
    baseColor = colors[0]
  }

  for (const other of hexColors) {
    const ratio = colorContrastRatioCalculator(baseColor, other)
    if (ratio >= threshold) {
      return other
    }
    if (ratio > highestRatio) {
      highestRatio = ratio
      highestRatioColor = other
    }
  }

  return highestRatioColor
}

export const homePageColors = ['#00044E', '#7AB4C2', '#008398', '#272D3B']

export const nationalityBarchartColor = ['#145A80']

export const getNumberOfColors = (numberOfColors: number) => {
  return colors.slice(0, numberOfColors)
}

export const hexToRGB = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return { r, g, b }
}
