import { styled } from '@mui/system'
import { HeaderTitleNavigation, SelfService } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { Box, Typography, IconButton, useTheme } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useGlobalStore } from '@epix-web-apps/ui'
import { useGetRequestPolicyContractsByPersonIdByTeamStructureQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface BalanceOverviewProps {}

export function BalanceOverview(props: BalanceOverviewProps) {
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const theme = useTheme()
  const navigate = useNavigate()
  const [click, setClicked] = useState(false)

  const { data } = useGetRequestPolicyContractsByPersonIdByTeamStructureQuery(
    {},
    {
      suspense: false
    }
  )

  const TypographyNote = styled(Typography)(() => {
    return {
      color: theme.palette.text.secondary,
      fontStyle: 'italic'
    }
  })

  return (
    <>
      <HeaderTitleNavigation title={t('selfservice.balanceoverview.title')} mobileSizeTitle />
      {data?.allRequestPolicyContractsByPersonIdByTeamStructure.map(contract => (
        <Box key={contract.icpCode}>
          <Typography variant="h5">
            {contract.icpCode}: {new Date(contract.contractStartDate).toLocaleDateString(me?.locale.locale)} -{' '}
            {contract.contractEndDate
              ? new Date(contract.contractEndDate).toLocaleDateString(me?.locale.locale)
              : '...'}
          </Typography>
          {contract.requestPolicyContractModels?.map(requestPolicyContract => (
            <Box
              key={requestPolicyContract.id}
              display="flex"
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
              mt={0.5}
              pl={1}
              border="1.5px solid"
              borderRadius={'2px'}
              borderColor={theme.palette.divider}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(SelfService.BALANCE_CALCULATION_ID(`${requestPolicyContract.id}`))
              }}
            >
              <Typography key={requestPolicyContract.id}>
                {requestPolicyContract.requestPolicyUserFriendlyName}
              </Typography>
              <IconButton>
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      ))}
      <Box mt={3}>
        <TypographyNote onClick={() => setClicked(!click)} sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
          {t('selfservice.balanceoverview.note.title')}
        </TypographyNote>
        {click && (
          <>
            <TypographyNote>{t('selfservice.balanceoverview.note.description-start')}</TypographyNote>
            <TypographyNote>{t('selfservice.balanceoverview.note.description-1')}</TypographyNote>
            <TypographyNote>{t('selfservice.balanceoverview.note.description-2')}</TypographyNote>
            <TypographyNote>{t('selfservice.balanceoverview.note.description-3')}</TypographyNote>
            <Box mt={2}>
              <TypographyNote>{t('selfservice.balanceoverview.note.description-end')}</TypographyNote>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default BalanceOverview
