import { DATE_INPUT_FORMAT } from '@epix-web-apps/ui'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'

/* eslint-disable-next-line */
export interface EpixDatePickerProps<TInputDate, TDate> extends Omit<DatePickerProps<TInputDate, TDate>, 'onChange'> {
  onChange?: (value: TDate | null, keyboardInputValue?: string | undefined) => void
}

export function EpixDatePicker(props: EpixDatePickerProps<Date, Date>) {
  const { onChange, inputFormat } = props
  return (
    <DatePicker
      {...props}
      onChange={(value: Date | null, keyboardInputValue?: string | undefined) => {
        if (value !== null && !isNaN(value?.getTime()) && new Date(value)?.getFullYear() < 1900) {
          return
        }
        onChange && onChange(value, keyboardInputValue)
      }}
      minDate={new Date('01/01/1900')}
      inputFormat={inputFormat ? inputFormat : DATE_INPUT_FORMAT}
    />
  )
}

export default EpixDatePicker
