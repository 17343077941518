import { Route, Navigate } from 'react-router-dom'
import LoginRedirectPage from './myepix/pages/auth-redirect-page'
import NotFoundPage from './myepix/pages/404-page/notfound-page'
import NotAuthorizedPage from './myepix/pages/not-authorized-page'

export const useGeneralRoutes = () => {
  return (
    <>
      <Route path="/auth-redirect" element={<LoginRedirectPage />} />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/not-authorized" element={<NotAuthorizedPage />} />
    </>
  )
}
