import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useGetMeQuery,
  GraphqlError,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { boolean, object, string, TypeOf } from 'zod'
import { DetailPageBaseQueryParams, FormActionButtons, FormErrorList, FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface EditUserProps {}

export function EditUser(props: EditUserProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editUserSchema = object({
    firstName: string({
      required_error: t('form.validation.firstnamerequired'),
      invalid_type_error: t('form.validation.firstnamerequired')
    }).min(1, t('form.validation.firstnamerequired')),
    lastName: string({
      required_error: t('form.validation.lastnamerequired'),
      invalid_type_error: t('form.validation.lastnamerequired')
    }).min(1, t('form.validation.lastnamerequired')),
    userName: string().email(t('form.validation.invalidemail')),
    localeCode: string({
      required_error: t('form.validation.languagerequired'),
      invalid_type_error: t('form.validation.languagerequired')
    }).min(1, t('form.validation.languagerequired')),
    currency: string({
      required_error: t('form.validation.currencyrequired'),
      invalid_type_error: t('form.validation.currencyrequired')
    }).min(1, t('form.validation.currencyrequired')),
    isActive: boolean()
  })

  type EditUserForm = TypeOf<typeof editUserSchema>
  const { currencyOptions, localeOptions } = formTypeSelectOptions
  const { data: me, refetch: refetchMe } = useGetMeQuery()
  const { data: getUserById, refetch: refetchUserById } = useGetUserByIdQuery(
    {
      userId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const form = useForm<EditUserForm>({
    resolver: zodResolver(editUserSchema),
    defaultValues: {
      firstName: getUserById?.userById.firstName || '',
      lastName: getUserById?.userById.lastName || '',
      userName: getUserById?.userById.email || '',
      localeCode: getUserById?.userById.locale.locale || me?.me.locale.locale,
      currency: getUserById?.userById.currency || me?.me.currency,
      isActive: getUserById?.userById.isActive
    }
  })

  const editmutation = useUpdateUserMutation()

  const handleOnSubmit = async (user: EditUserForm) => {
    await editmutation
      .mutateAsync({
        updateUserCommand: {
          id: params.id ?? '',
          firstName: user.firstName,
          lastName: user.lastName,
          currency: user.currency,
          locale: user.localeCode,
          isActive: user.isActive,
          personId: getUserById?.userById.personId
        }
      })
      .then(() => {
        refetchMe()
        refetchUserById()
        setBackendErrors([])
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Box>
        <FormGridLayout>
          <FormInput sx={6} name="firstName" label={`${t('form.field.firstname')} *`} />
          <FormInput sx={6} name="lastName" label={`${t('form.field.lastname')} *`} />

          <FormInput sx={12} name="userName" label={`${t('form.field.username')} (work email) *`} disabled={true} />

          <FormSelect sx={6} name="localeCode" label={`${t('form.field.defaultlanguage')} *`} options={localeOptions} />

          <FormSelect sx={6} name="currency" label={`${t('form.field.defaultcurrency')} *`} options={currencyOptions} />

          {me?.me.id !== params.id && <FormSwitch sx={12} name="isActive" label={t('form.field.isActive')} />}
        </FormGridLayout>
      </Box>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={editmutation.isLoading} />
    </FormContainer>
  )
}

export default EditUser
