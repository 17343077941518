import {
  useGetPayrollClosureProcessQuery,
  useGetSelectedPayrollClosureProcessContractsQuery,
  useUpdateProcessMutation,
  useUpdatePayrollClosureProcessContractsMutation,
  useUpdateProcessStepMutation,
  PROCESS_PAYROLLCLOSURE_PARAMETER,
  PROCESS_TYPE
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Paper, Step, StepLabel, Stepper, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { date, object, string, TypeOf } from 'zod'
import { EmptyState, FormContainer, Process } from '@epix-web-apps/ui'
import { HeaderTitleNavigation } from '@epix-web-apps/ui'
import { Parameters, Population, PersonalContractData, SalaryData, CalendarData } from './steps'
import { StepParams, Start, Finish } from '../generic-steps'

/* eslint-disable-next-line */
export interface PayrollClosureProps {}

export function PayrollClosure(props: PayrollClosureProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<StepParams>()
  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )
  const [activeStep, setActiveStep] = useState(getPayrollClosureProcess?.payrollClosureProcess.currentStep || 1)
  const steps = [
    t('wizard.step.start'),
    t('wizard.step.parameters'),
    t('wizard.step.population'),
    t('wizard.step.personalandcontractdata'),
    t('wizard.step.salarycomponents'),
    t('wizard.step.calendarcomponents'),
    t('wizard.step.finish')
  ]

  if (
    !getPayrollClosureProcess?.payrollClosureProcess.hasCorrections &&
    !getPayrollClosureProcess?.payrollClosureProcess.hasOriginalData &&
    steps.length === 7
  ) {
    steps.splice(4, 2)
  }

  const payrollProcessSchema = object({
    payrollProviderId: string({
      required_error: t('form.validation.contractproviderrequired'),
      invalid_type_error: t('form.validation.contractproviderrequired')
    }).min(1, t('form.validation.contractproviderrequired')),
    dueDate: date({
      required_error: t('form.validation.duedaterequired'),
      invalid_type_error: t('form.validation.duedaterequired')
    }),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    payGroupId: string({
      required_error: t('form.validation.paygrouprequired'),
      invalid_type_error: t('form.validation.paygrouprequired')
    }).min(1, t('form.validation.paygrouprequired')),
    processName: string({
      required_error: t('form.validation.processnamerequired'),
      invalid_type_error: t('form.validation.processnamerequired')
    }).min(1, t('form.validation.processnamerequired')),
    notes: string().optional().nullable(),
    parameterTypeKey: string({
      required_error: t('form.validation.parameterrequired'),
      invalid_type_error: t('form.validation.parameterrequired')
    }).min(1, t('form.validation.parameterrequired')),
    period: date().optional(),
    contractIds: string()
      .array()
      .refine(data => !(activeStep > 2 && data.length < 1), {
        message: t('form.validation.populationrequired')
      })
  }).refine(
    data =>
      data.parameterTypeKey === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT
        ? data.period
        : date(),
    {
      message: t('form.validation.periodrequired'),
      path: ['period']
    }
  )
  type payrollProcessForm = TypeOf<typeof payrollProcessSchema>

  const { data: getSelectedProcessContracts } = useGetSelectedPayrollClosureProcessContractsQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )
  const updateProcessMutation = useUpdateProcessMutation()
  const updateProcessContractsMutation = useUpdatePayrollClosureProcessContractsMutation()
  const updateProcessStepMutation = useUpdateProcessStepMutation()

  const form = useForm<payrollProcessForm>({
    resolver: zodResolver(payrollProcessSchema),
    defaultValues: {
      payrollProviderId: getPayrollClosureProcess?.payrollClosureProcess.payrollProviderId,
      dueDate: getPayrollClosureProcess?.payrollClosureProcess.dueDate
        ? new Date(getPayrollClosureProcess?.payrollClosureProcess.dueDate)
        : undefined,
      employerId: getPayrollClosureProcess?.payrollClosureProcess.employerId,
      payGroupId: getPayrollClosureProcess?.payrollClosureProcess.payGroup.id,
      processName: getPayrollClosureProcess?.payrollClosureProcess.name,
      notes: getPayrollClosureProcess?.payrollClosureProcess.notes,
      period: getPayrollClosureProcess?.payrollClosureProcess.startDate
        ? new Date(getPayrollClosureProcess?.payrollClosureProcess.startDate)
        : undefined,
      parameterTypeKey: getPayrollClosureProcess?.payrollClosureProcess.parameterType?.key,
      contractIds: getSelectedProcessContracts?.selectedPayrollClosureProcessContracts || []
    }
  })
  const {
    clearErrors,
    formState: { errors },
    handleSubmit
  } = form

  const updateStep = (processId: string) => {
    let nextStep = activeStep + 1
    if (
      !getPayrollClosureProcess?.payrollClosureProcess.hasCorrections &&
      !getPayrollClosureProcess?.payrollClosureProcess.hasOriginalData &&
      activeStep === 4
    ) {
      nextStep = 7
    }
    const currentStep = getPayrollClosureProcess?.payrollClosureProcess.currentStep || 1
    if (nextStep > currentStep) {
      updateProcessStepMutation.mutateAsync({
        updateProcessStepCommand: {
          processId: processId,
          step: nextStep
        }
      })
    }
    setActiveStep(nextStep)
  }

  const handlePrevious = () => {
    let nextStep = activeStep - 1
    if (
      !getPayrollClosureProcess?.payrollClosureProcess.hasCorrections &&
      !getPayrollClosureProcess?.payrollClosureProcess.hasOriginalData &&
      activeStep === 7
    ) {
      nextStep = 4
    }
    clearErrors()
    setActiveStep(nextStep)
  }

  const handleUpdateProcessInfo = (payrollClosure: payrollProcessForm) => {
    updateProcessMutation
      .mutateAsync({
        updateProcessCommand: {
          processId: params.id || '',
          payrollProviderId: getPayrollClosureProcess?.payrollClosureProcess.payrollProviderId || '',
          dueDate: payrollClosure.dueDate,
          employerId: getPayrollClosureProcess?.payrollClosureProcess.employerId || '',
          payGroupId: getPayrollClosureProcess?.payrollClosureProcess.payGroup.id || '',
          name: payrollClosure.processName,
          notes: payrollClosure.notes,
          cancelled: getPayrollClosureProcess?.payrollClosureProcess.cancelled ?? false
        }
      })
      .then(() => {
        updateStep(params.id || '')
      })
  }

  const handleSelectContractsStep = (payrollClosure: payrollProcessForm) => {
    updateProcessContractsMutation
      .mutateAsync({
        updatePayrollClosureProcessContractsCommand: {
          processId: params.id || '',
          contractIds: payrollClosure.contractIds
        }
      })
      .then(() => {
        updateStep(params.id || '')
      })
  }

  const handleOnSubmit = async (payrollClosure: payrollProcessForm) => {
    switch (activeStep) {
      case 1:
        handleUpdateProcessInfo(payrollClosure)
        break
      case 3:
        handleSelectContractsStep(payrollClosure)
        break
      default:
        updateStep(params.id || '')
        break
    }
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return <Start />
      case 2:
        return <Parameters processId={params.id || ''} />
      case 3:
        return <Population processId={params.id || ''} />
      case 4:
        return <PersonalContractData processId={params.id || ''} />
      case 5:
        return getPayrollClosureProcess?.payrollClosureProcess.payGroup.hasPayComponentManagement ? (
          <SalaryData processId={params.id || ''} />
        ) : (
          <EmptyState title={t('payrollclosure.nopaycomponentmanagement')} />
        )
      case 6:
        return getPayrollClosureProcess?.payrollClosureProcess.payGroup.hasCalendarManagement ? (
          <CalendarData processId={params.id || ''} />
        ) : (
          <EmptyState title={t('payrollclosure.nocalendarmanagement')} />
        )
      case 7:
        return <Finish processId={params.id || ''} processType={PROCESS_TYPE.PAYROLL_CLOSURE} />
      default:
        return <Navigate to="/404" replace />
    }
  }

  return (
    <Paper
      sx={{
        px: 3,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <FormContainer
        sx={{ pt: 2, display: 'flex', flexDirection: 'column', flexGrow: 1 }}
        form={form}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: [2]
          }}
        >
          <HeaderTitleNavigation
            showDivider={false}
            backToLink={Process.PROCESSES()}
            title={t('payrollclosure.title')}
          />

          <Typography variant="h4" noWrap display="inline">
            {getPayrollClosureProcess?.payrollClosureProcess.isTestRun && (
              <span
                style={{
                  color: theme.palette.warning.light,
                  paddingRight: 1
                }}
              >
                {t('payrollclosure.istestrun')}
                &nbsp;
              </span>
            )}
            {getPayrollClosureProcess?.payrollClosureProcess.name}
          </Typography>

          <Box sx={{ display: 'flex', gap: 1 }}>
            {activeStep > 1 && !getPayrollClosureProcess?.payrollClosureProcess.completed && (
              <Button onClick={() => handlePrevious()}>{t('common.previous')}</Button>
            )}
            <Button type="submit" disabled={activeStep === 7} variant="contained">
              {t('common.next')}
            </Button>
          </Box>
        </Box>

        <Stepper sx={{ mt: 1, mb: 4 }} activeStep={activeStep - 1} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel error={activeStep === index + 1 && !!Object.keys(errors).length}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ px: 6, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>{renderStepContent()}</Box>
      </FormContainer>
    </Paper>
  )
}

export default PayrollClosure
