import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  byteArrayToFile,
  ExportWorkforceEvolutionQueryVariables,
  ToBackendFormatedDate,
  useExportWorkforceCompositionQuery,
  useExportWorkforceDemographicsQuery,
  useExportWorkforceEvolutionQuery,
  useExportWorkforceInjuriesQuery,
  useExportWorkforceOrganisationQuery,
  useExportWorkforceStartersLeaversQuery,
  formTypeSelectOptions,
  useGetAllPersonsQuery
} from '@epix-web-apps/core'
import { DetailPageLayout, DownloadButton, LoadingOverlay, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Box, Typography, Tabs, Tab, Button, Chip } from '@mui/material'
import FilterAnalyticsFlyin from '../../components/analytics-components/filter-analytics-flyin/filter-analytics-flyin'
import { TabPanel, a11yProps } from '@epix-web-apps/ui'
import OrganisationTab from '../../components/analytics-components/analytics-tabs/organisation-tab'
import DemographicsTab from '../../components/analytics-components/analytics-tabs/demographics-tab/demographics-tab'
import CompositionTab from '../../components/analytics-components/analytics-tabs/composition-tab/composition-tab'
import EvolutionTab from '../../components/analytics-components/analytics-tabs/evolution-tab/evolution-tab'
import StartersLeaversTab from '../../components/analytics-components/analytics-tabs/starters-leavers-tab/starters-leavers-tab'
import { useAnalyticsPersistedStore } from '../../stores/analytics-store'
import moment from 'moment'
import InjuriesTab from '../../components/analytics-components/analytics-tabs/injuries-tab'
import OnboardingWorkforceAnalyticsLayout from '../../components/onboarding-components/onboarding-workforce-analytics-layout'
import { usePeopleFilterStore } from '../../stores/people-page-filter-store'
import { SuspensePaper } from '../../components/suspense-paper'

export interface WorkforceFilters {
  date: Date
  gender: string | null
  functionArea: string | null
  functionLevel: string | null
  employeeType: string | null
}

/* eslint-disable-next-line */
export interface AnalyticsPageProps {}

export enum WorkforceAnalyticsTabs {
  EVOLUTION,
  STARTERS_LEAVERS,
  COMPOSITION,
  DEMOGRAPHICS,
  ORGANISATION,
  INJURIES
}

export function AnalyticsPage(props: AnalyticsPageProps) {
  const [tabIndex, setTabIndex] = useState(0)
  const { icpFilters } = useGlobalPersistedStore()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { selectedDate, sortByProperty, sortDirection } = usePeopleFilterStore()

  const store = useAnalyticsPersistedStore()

  const filters: WorkforceFilters = {
    date: store.dateFilter || new Date(),
    gender: store.genderFilter,
    functionArea: store.functionAreaFilter,
    functionLevel: store.functionLevelFilter,
    employeeType: store.employeeTypeFilter
  }

  function updateStore(filters: WorkforceFilters) {
    store.setDateFilter(filters.date)
    store.setGenderFilter(filters.gender)
    store.setFunctionAreaFilter(filters.functionArea)
    store.setFunctionLevelFilter(filters.functionLevel)
    store.setEmployeeTypeFilter(filters.employeeType)
  }

  function handleFilterDelete(filterName: [string, string | null]) {
    updateStore({ ...filters, [filterName[0]]: null })
  }

  const { genderOptions, employeeOptions, functionAreaOptions, functionLevelOptions } = formTypeSelectOptions
  const filterLabels = [genderOptions, functionAreaOptions, functionLevelOptions, employeeOptions].flat()

  const { data } = useGetAllPersonsQuery(
    {
      offset: 0,
      limit: 1,
      selectedDate: selectedDate,
      icps: icpFilters,
      sortByProperty: sortByProperty,
      orderDirection: sortDirection
    },
    { suspense: false }
  )

  const showOnboarding = data?.persons.totalCount === 0

  const queryParams: ExportWorkforceEvolutionQueryVariables = {
    icps: icpFilters,
    selectedDate: store.dateFilter,
    workforceFilterModel: {
      employeeType: filters.employeeType,
      functionAreaType: filters.functionArea,
      functionLevelType: filters.functionLevel,
      genderType: filters.gender
    }
  }

  const { refetch: refetchEvolution, isFetching: isFetchingEvolution } = useExportWorkforceEvolutionQuery(queryParams, {
    enabled: false,
    suspense: false
  })

  const { refetch: refetchStartersLeavers, isFetching: isFetchingStartersLeavers } =
    useExportWorkforceStartersLeaversQuery(queryParams, { enabled: false, suspense: false })

  const { refetch: refetchComposition, isFetching: isFetchingComposition } = useExportWorkforceCompositionQuery(
    queryParams,
    { enabled: false, suspense: false }
  )

  const { refetch: refetchDemographics, isFetching: isFetchingDemographics } = useExportWorkforceDemographicsQuery(
    queryParams,
    { enabled: false, suspense: false }
  )

  const { refetch: refetchOrganisation, isFetching: isFetchingOrganisation } = useExportWorkforceOrganisationQuery(
    queryParams,
    { enabled: false, suspense: false }
  )

  const { refetch: refetchInjuries, isFetching: isFetchingInjuries } = useExportWorkforceInjuriesQuery(queryParams, {
    enabled: false,
    suspense: false
  })

  function startDownload() {
    switch (tabIndex) {
      case WorkforceAnalyticsTabs.EVOLUTION:
        refetchEvolution().then(r => {
          byteArrayToFile(
            r.data?.exportWorkforceEvolution as unknown as Uint8Array,
            `workforce-evolution_${ToBackendFormatedDate(store.dateFilter)}.csv`
          )
        })
        break
      case WorkforceAnalyticsTabs.STARTERS_LEAVERS:
        refetchStartersLeavers().then(r => {
          byteArrayToFile(
            r.data?.exportWorkforceStartersLeavers as unknown as Uint8Array,
            `workforce-starters-leavers-yoy_${ToBackendFormatedDate(store.dateFilter)}.csv`
          )
        })
        break
      case WorkforceAnalyticsTabs.COMPOSITION:
        refetchComposition().then(r => {
          byteArrayToFile(
            r.data?.exportWorkforceComposition as unknown as Uint8Array,
            `workforce-composition_${ToBackendFormatedDate(store.dateFilter)}.csv`
          )
        })
        break
      case WorkforceAnalyticsTabs.DEMOGRAPHICS:
        refetchDemographics().then(r => {
          byteArrayToFile(
            r.data?.exportWorkforceDemographics as unknown as Uint8Array,
            `workforce-demographics_${ToBackendFormatedDate(store.dateFilter)}.csv`
          )
        })
        break
      case WorkforceAnalyticsTabs.ORGANISATION:
        refetchOrganisation().then(r => {
          byteArrayToFile(
            r.data?.exportWorkforceOrganisation as unknown as Uint8Array,
            `workforce-organisation_${ToBackendFormatedDate(store.dateFilter)}.csv`
          )
        })
        break
      case WorkforceAnalyticsTabs.INJURIES:
        refetchInjuries().then(r => {
          byteArrayToFile(
            r.data?.exportWorkforceInjuries as unknown as Uint8Array,
            `workforce-injuries_${ToBackendFormatedDate(store.dateFilter)}.csv`
          )
        })
        break
    }
  }

  const isFetching =
    isFetchingEvolution ||
    isFetchingStartersLeavers ||
    isFetchingComposition ||
    isFetchingDemographics ||
    isFetchingOrganisation ||
    isFetchingInjuries

  return (
    <DetailPageLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography ml={2} variant="h2">
            {t('homepage.analystics')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Typography>
              {t('analytics.filters.viewpoint')}: <strong>{new Date(filters.date).toLocaleDateString()}</strong>
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {Object.entries(filters).map(
                (entry: [string, string | null]) =>
                  entry[1] &&
                  !moment(entry[1]).isValid() && (
                    <Chip
                      key={entry[0]}
                      size="small"
                      variant="outlined"
                      label={filterLabels.find(f => f.id === entry[1]!.toString())?.label}
                      onDelete={() => handleFilterDelete(entry)}
                    />
                  )
              )}
            </Box>

            <Button
              variant="outlined"
              onClick={() =>
                openFlyIn({
                  content: <FilterAnalyticsFlyin filters={filters} updateStore={updateStore} />
                })
              }
            >
              {t('analytics.filters.title')}
            </Button>
          </Box>
        </Box>
        {showOnboarding ? (
          <OnboardingWorkforceAnalyticsLayout />
        ) : (
          <>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Tabs value={tabIndex} onChange={(e, tabIndex) => setTabIndex(tabIndex)}>
                <Tab label={t('analytics.tab.workforceevolution')} {...a11yProps(WorkforceAnalyticsTabs.EVOLUTION)} />
                <Tab
                  label={t('analytics.tab.startersandleavers')}
                  {...a11yProps(WorkforceAnalyticsTabs.STARTERS_LEAVERS)}
                />
                <Tab
                  label={t('analytics.tab.workforcecomposition')}
                  {...a11yProps(WorkforceAnalyticsTabs.COMPOSITION)}
                />
                <Tab label={t('analytics.tab.demographics')} {...a11yProps(WorkforceAnalyticsTabs.DEMOGRAPHICS)} />
                <Tab label={t('analytics.tab.organisation')} {...a11yProps(WorkforceAnalyticsTabs.ORGANISATION)} />
                <Tab label={t('analytics.tab.injuries')} {...a11yProps(WorkforceAnalyticsTabs.INJURIES)} />
              </Tabs>
              <DownloadButton isFetching={isFetching} onClick={startDownload} />
            </Box>

            <TabPanel value={tabIndex} index={WorkforceAnalyticsTabs.EVOLUTION}>
              <SuspensePaper>
                <EvolutionTab />
              </SuspensePaper>
            </TabPanel>

            <TabPanel value={tabIndex} index={WorkforceAnalyticsTabs.STARTERS_LEAVERS}>
              <Suspense fallback={<LoadingOverlay />}>
                <StartersLeaversTab />
              </Suspense>
            </TabPanel>

            <TabPanel value={tabIndex} index={WorkforceAnalyticsTabs.COMPOSITION}>
              <CompositionTab />
            </TabPanel>

            <TabPanel value={tabIndex} index={WorkforceAnalyticsTabs.DEMOGRAPHICS}>
              <DemographicsTab />
            </TabPanel>

            <TabPanel value={tabIndex} index={WorkforceAnalyticsTabs.ORGANISATION}>
              <OrganisationTab />
            </TabPanel>

            <TabPanel value={tabIndex} index={WorkforceAnalyticsTabs.INJURIES}>
              <InjuriesTab />
            </TabPanel>
          </>
        )}
      </Box>
    </DetailPageLayout>
  )
}

export default AnalyticsPage
