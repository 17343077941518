import { StrictMode, Suspense } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import App from './app/app'
import { environment } from './environments/environment'
import { Box } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundryHandler } from './app/myepix/components/error-boundry-handler'
import { LoadingOverlay } from '@epix-web-apps/ui'
import { clarity } from 'react-microsoft-clarity'

clarity.init('lgkpi82ojq')

const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      retry: false
    },
    mutations: {
      retry: false
    }
  }
})

root.render(
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}
  >
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorBoundryHandler}>
        <QueryClientProvider client={queryClient}>
          <Box sx={{ flex: 1 }}>
            <Suspense fallback={<LoadingOverlay />}>
              <App />
            </Suspense>
          </Box>
          {!environment.production && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
        </QueryClientProvider>
      </ErrorBoundary>
    </StrictMode>
  </Box>
)
