import { Box, Typography, Grid } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import {
  OrderDirection,
  useCreatePayGroupMutation,
  useGetCollectiveSchedulesByEmployerIdQuery,
  useGetEmployerByIdQuery,
  useGetFeaturesQuery,
  useGetPayGroupByIdQuery,
  useUpdatePayGroupMutation,
  FormSelectOption,
  DEFAULT_PAYPERIOD,
  numericString,
  PAYPERIODTYPES,
  GraphqlError,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { boolean, date, number, object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useEffect, useMemo, useState } from 'react'

export interface AddEditPaygroupProps {
  employerId: string
  payGroupId?: string
}

export function AddEditPaygroup({ employerId, payGroupId }: AddEditPaygroupProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: getFeatures } = useGetFeaturesQuery()

  const { data: getEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: employerId
    },
    {
      enabled: !!employerId
    }
  )

  const { data: collectiveSchedule } = useGetCollectiveSchedulesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      orderDirection: OrderDirection.Asc
    },
    {
      enabled: !!employerId
    }
  )

  const addEditPayGroupSchema = object({
    name: string({
      required_error: t('form.validation.paygroupnamerequired'),
      invalid_type_error: t('form.validation.paygroupnamerequired')
    }).min(1, t('form.validation.paygroupnamerequired')),
    code: string({
      required_error: t('form.validation.paygroupcoderequired'),
      invalid_type_error: t('form.validation.paygroupcoderequired')
    }).min(1, t('form.validation.paygroupcoderequired')),
    firstClosure: date().optional().nullable(),
    collectiveSchedule: string().nullable().optional(),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    calculatedByEpix: boolean(),
    hasPayComponentManagement: boolean(),
    hasCalendarManagement: boolean(),
    hasDocumentManagement: boolean(),
    hasSelfService: boolean(),
    hasCarManagement: boolean(),
    payPeriod: string({
      required_error: t('form.validation.payperiodtyperequired'),
      invalid_type_error: t('form.validation.payperiodtyperequired')
    }).min(1, t('form.validation.payperiodtyperequired')),
    payPeriodNumberOfWeeks: numericString(
      number()
        .gt(0, { message: t('form.validation.numberofweeksgreaterthan0') })
        .lt(5, { message: t('form.validation.numberofweekslessthan5') })
        .optional()
        .nullable()
    )
  })
    .refine(
      data =>
        getEmployerById?.employerById.calculatedByEpix
          ? data.firstClosure
          : date({
              required_error: t('form.validation.firstclosurerequired'),
              invalid_type_error: t('form.validation.firstclosurerequired')
            }),
      {
        message: t('form.validation.firstclosurerequired'),
        path: ['firstClosure']
      }
    )
    .refine(
      data =>
        data.payPeriod === PAYPERIODTYPES.WEEKLY
          ? data.payPeriodNumberOfWeeks
          : numericString(
              number({
                required_error: t('form.validation.numberofweeksrequired'),
                invalid_type_error: t('form.validation.numberofweeksrequired')
              })
                .gt(0, { message: t('form.validation.numberofweeksgreaterthan0') })
                .lt(5, { message: t('form.validation.numberofweekslessthan5') })
            ).optional(),
      {
        message: t('form.validation.numberofweeksrequired'),
        path: ['payPeriodNumberOfWeeks']
      }
    )
    .refine(
      data =>
        data.payPeriod === PAYPERIODTYPES.WEEKLY
          ? Number.isInteger(data.payPeriodNumberOfWeeks) && data.payPeriodNumberOfWeeks
          : true,
      {
        message: t('form.validation.numberofweeksmustbeaninteger'),
        path: ['payPeriodNumberOfWeeks']
      }
    )

  type CreateEditPayGroupForm = TypeOf<typeof addEditPayGroupSchema>

  const { data: getPayGroupById, refetch: refetchPayGroupById } = useGetPayGroupByIdQuery(
    {
      employerId: employerId || '',
      payGroupId: payGroupId || ''
    },
    {
      enabled: !!payGroupId
    }
  )

  const defaultFormValues = useMemo(
    () =>
      ({
        code: getPayGroupById?.payGroupById?.code || undefined,
        name: getPayGroupById?.payGroupById?.name || undefined,
        calculatedByEpix: getPayGroupById?.payGroupById?.calculatedByEpix ?? false,
        hasPayComponentManagement: getPayGroupById?.payGroupById?.hasPayComponentManagement ?? false,
        hasCalendarManagement: getPayGroupById?.payGroupById?.hasCalendarManagement ?? false,
        hasDocumentManagement: getPayGroupById?.payGroupById?.hasDocumentManagement ?? false,
        hasCarManagement: getPayGroupById?.payGroupById?.hasCarManagement ?? false,
        hasSelfService: getPayGroupById?.payGroupById?.hasSelfService ?? false,
        firstClosure: getPayGroupById?.payGroupById?.firstClosure
          ? new Date(getPayGroupById.payGroupById.firstClosure)
          : null,
        employerId: employerId,
        collectiveSchedule: getPayGroupById?.payGroupById?.collectiveScheduleId || '',
        payPeriod: getPayGroupById?.payGroupById?.payPeriodType.key,
        payPeriodNumberOfWeeks: getPayGroupById?.payGroupById?.payPeriodNumberOfWeeks || null
      } as CreateEditPayGroupForm),
    [employerId, getPayGroupById?.payGroupById]
  )

  const form = useForm<CreateEditPayGroupForm>({
    resolver: zodResolver(addEditPayGroupSchema),
    defaultValues: defaultFormValues
  })
  const { control, reset } = form
  useEffect(() => {
    reset(defaultFormValues)
  }, [defaultFormValues])

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { payPeriodOptions } = formTypeSelectOptions
  const createmutation = useCreatePayGroupMutation()
  const updatemutation = useUpdatePayGroupMutation()

  const handleOnSubmit = async (newPayGroup: CreateEditPayGroupForm) => {
    if (payGroupId === undefined) {
      await createmutation
        .mutateAsync({
          createPayGroupCommand: {
            code: newPayGroup.code,
            name: newPayGroup.name,
            firstClosure: newPayGroup.firstClosure && newPayGroup.firstClosure,
            calculatedByEpix: newPayGroup.calculatedByEpix,
            hasPayComponentManagement: newPayGroup.hasPayComponentManagement,
            hasCalendarManagement: newPayGroup.hasCalendarManagement,
            hasDocumentManagement: newPayGroup.hasCalendarManagement,
            hasSelfService: newPayGroup.hasSelfService,
            hasCarManagement: newPayGroup.hasCarManagement,
            employerId: employerId,
            collectiveScheduleId: newPayGroup.collectiveSchedule || null,
            payPeriodTypeKey: newPayGroup.payPeriod || DEFAULT_PAYPERIOD,
            payPeriodNumberOfWeeks: newPayGroup.payPeriodNumberOfWeeks || null
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updatemutation
        .mutateAsync({
          updatePayGroupCommand: {
            payGroupId: payGroupId || '',
            code: newPayGroup.code,
            name: newPayGroup.name,
            firstClosure: newPayGroup.firstClosure && newPayGroup.firstClosure,
            calculatedByEpix: newPayGroup.calculatedByEpix,
            hasPayComponentManagement: newPayGroup.hasPayComponentManagement,
            hasCalendarManagement: newPayGroup.hasCalendarManagement,
            hasDocumentManagement: newPayGroup.hasDocumentManagement,
            hasSelfService: newPayGroup.hasSelfService,
            hasCarManagement: newPayGroup.hasCarManagement,
            employerId: employerId || '',
            collectiveScheduleId: newPayGroup.collectiveSchedule || null,
            payPeriodTypeKey: newPayGroup.payPeriod || DEFAULT_PAYPERIOD,
            payPeriodNumberOfWeeks: newPayGroup.payPeriodNumberOfWeeks || null
          }
        })
        .then(() => {
          refetchPayGroupById()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  const watchCalendarManagement = useWatch({
    control,
    name: 'hasCalendarManagement'
  })

  const watchPayPeriod = useWatch({
    control,
    name: 'payPeriod'
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.paygroup.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.paygroupcode')}`} />

        <FormInput sx={12} name="name" label={`${t('form.field.description')}`} />

        <FormSelect
          sx={12}
          name="collectiveSchedule"
          label={`${t('form.field.collectiveschedule')}`}
          options={collectiveSchedule?.collectiveSchedules.data.map(x => new FormSelectOption(x.id, x.description))}
        />

        {getFeatures?.features.payPeriod && (
          <>
            <FormSelect
              sx={12}
              onChange={e => {
                form.resetField('payPeriodNumberOfWeeks', { defaultValue: null })
              }}
              name="payPeriod"
              label={`${t('form.field.payperiod')}`}
              options={payPeriodOptions}
            />
            {watchPayPeriod === PAYPERIODTYPES.WEEKLY && (
              <FormInput sx={12} name="payPeriodNumberOfWeeks" label={t('form.field.numberofweeks')} />
            )}
          </>
        )}
        <FormDatepicker sx={6} name="firstClosure" label={`${t('form.field.firstclosure')}`} />

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.calculatedByEpix}
          name="calculatedByEpix"
          label={t('form.field.defaultpayrollviaepix')}
        />

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.hasPayComponentManagement}
          name="hasPayComponentManagement"
          label={t('form.field.paycomponentmanagement')}
        />

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.hasCalendarManagement}
          name="hasCalendarManagement"
          onChange={e => {
            if (!e) {
              form.resetField('hasSelfService', { defaultValue: false })
            }
          }}
          label={t('form.field.calendarmanagement')}
        />

        <Box ml={8}>
          <FormSwitch
            sx={12}
            disabled={
              !getEmployerById?.employerById.hasSelfService ||
              (!watchCalendarManagement && getEmployerById?.employerById.hasSelfService)
            }
            name="hasSelfService"
            label={t('form.field.selfservice.selfservice')}
          />
        </Box>

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.hasCompanyCars}
          name="hasCarManagement"
          label={t('form.field.hascompanycars')}
        />

        {getFeatures?.features.documentManagement && (
          <FormSwitch sx={12} name="hasDocumentManagement" label={t('form.field.documentmanagement')} />
        )}

        <Grid xs={12} item>
          <Typography sx={{ fontStyle: 'italic' }}>{t('form.field.flagsdescription')}</Typography>
        </Grid>
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditPaygroup
