import { Box, Button, IconButton, MenuItem, Switch, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { isAdmin, isAuthorized, OrderDirection, ROLE, useGetAllIcpsQuery, useGetMeQuery } from '@epix-web-apps/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import { Configuration, useFlyIn } from '@epix-web-apps/ui'
import { AddEditIcp, EditIcp } from '../../components/icp-components'
import FilterButton from '../../components/filter-button/filter-button'
import SettingsButton from '../../components/settings-button'
import OnboardingIcpLayout from '../../components/onboarding-components/onboarding-icp-layout/onboarding-icp-layout'

/* eslint-disable-next-line */
export interface IcpProvidersPageProps {}

export function IcpProvidersPage(props: IcpProvidersPageProps) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const [clickedIcpId, setClickedIcpId] = useState<string>()
  const [pageSize, setPageSize] = useState(25)
  const { openFlyIn } = useFlyIn()
  const [filters, setFilters] = useState({
    showInactive: false
  })
  const [defaultIcp, setDefaultIcp] = useState(false)
  const { data: me } = useGetMeQuery()

  const handleLinkClick = () => {
    openFlyIn({
      content: <AddEditIcp icpId={clickedIcpId} />,
      callbackAfterClose: () => () => {
        refetchIcps()
      }
    })
  }

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedIcpId(rowParams.row.id)
          setAnchorEl(e.currentTarget)
          setDefaultIcp(rowParams.row.managedbyepix)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      {rowParams.row.active ? (
        <IconButton aria-label="row details" aria-controls="details-row">
          <KeyboardArrowRightIcon />
        </IconButton>
      ) : (
        ''
      )}
    </>
  )

  const activeActions = (rowParams: GridRenderCellParams) => (rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />)

  const managedByEpixActions = (rowParams: GridRenderCellParams) => (rowParams.value ? <DoneIcon /> : <ClearIcon />)

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('icpproviderspage.datatable.column.country'),
      flex: 1
    },
    {
      field: 'country',
      headerName: t('icpproviderspage.datatable.column.countrycode'),
      flex: 1
    },
    {
      field: 'currency',
      headerName: t('icpproviderspage.datatable.column.currency'),
      flex: 1
    },
    {
      field: 'code',
      headerName: t('icpproviderspage.datatable.column.icpcode'),
      flex: 1
    },
    {
      field: 'payrollprovider',
      headerName: t('icpproviderspage.datatable.column.payrollprovider'),
      flex: 1
    },
    {
      field: 'active',
      headerName: t('icpproviderspage.datatable.column.active'),
      sortable: true,
      editable: false,
      renderCell: activeActions
    },
    {
      field: 'managedbyepix',
      headerName: t('icpproviderspage.datatable.column.managedbyepix'),
      sortable: true,
      editable: false,
      renderCell: managedByEpixActions
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>([])

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('icpproviderspage.title')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FilterButton filters={filters}>
            <p>
              <Switch
                checked={filters.showInactive}
                onChange={e => setFilters({ ...filters, showInactive: e.target.checked })}
                aria-label={t('peoplepage.filters.showinactivepeople')}
              />
              {t('configurationicpproviders.filters.showinactiveicpproviders')}
            </p>
          </FilterButton>

          {isAuthorized(me?.me.roles, [ROLE.MANAGELISTS, ROLE.ADMIN]) && (
            <SettingsButton
              menuItems={[
                {
                  label: t('icpproviderspage.settings.externalcodes'),
                  to: Configuration.ICP_PROVIDERS_EXTERNAL_CODES()
                }
              ]}
            />
          )}
        </Box>

        {isAdmin(me?.me.roles) && (
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddEditIcp />,
                callbackAfterClose: () => refetchIcps
              })
            }
          >
            <AddIcon />
            {t('icpproviderspage.button.addicp')}
          </Button>
        )}
      </Box>
    </Box>
  )

  useEffect(() => {
    setPage(0)
  }, [filters])

  const {
    data: getAllIcps,
    isLoading: isLoadingIcps,
    refetch: refetchIcps
  } = useGetAllIcpsQuery({
    activeOnly: !filters.showInactive,
    offset: page * pageSize,
    limit: pageSize,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  const goToEmployersPage = (clickedRow: GridRowId | undefined, active: boolean) => {
    if (clickedRow && active) {
      navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERS(clickedRow.toString()))
    }
  }

  useEffect(() => {
    if (getAllIcps && getAllIcps?.icps) {
      const icps = getAllIcps.icps
      setRowCount(icps.totalCount)
      setTableData(
        icps.data.map(row => {
          return {
            id: row.id,
            country: row.country,
            code: row.code,
            payrollprovider: row.payrollProvider,
            active: row.active,
            managedbyepix: row.managedByEpix,
            name: row.countryName,
            currency: row.currencyName + ' (' + row.currencyKey + ')'
          }
        })
      )
    }
  }, [getAllIcps])

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onRowClick={row => goToEmployersPage(row.row.id, row.row.active)}
        onSortChange={setSortModel}
        isLoading={isLoadingIcps}
        defaultSortModel={sortModel}
        filterBarElement={filterBar}
        emptyStateElement={<OnboardingIcpLayout onLinkClick={handleLinkClick} />}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditIcp icpId={clickedIcpId} />,
              callbackAfterClose: () => refetchIcps
            })
          }
        >
          {t('icpproviderspage.list.row.menu.viewdetails')}
        </MenuItem>

        {!defaultIcp && (
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: <AddEditIcp icpId={clickedIcpId} />,
                callbackAfterClose: () => refetchIcps
              })
            }
          >
            {t('icpproviderspage.list.row.menu.editdetails')}
          </MenuItem>
        )}
        <MenuItem onClick={() => goToEmployersPage(clickedIcpId, true)}>
          {t('icpproviderspage.list.row.menu.employers')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default IcpProvidersPage
