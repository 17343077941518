import { useState } from 'react'
import { Box, Button, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailPageLayout, TabPanel, a11yProps, useFlyIn } from '@epix-web-apps/ui'
import ProductivityEvolutionTab from '../../components/productivity-analytics-components/productivity-evolution-tab'
import { SuspensePaper } from '../../components/suspense-paper'
import FilterProductivityFlyin from '../../components/productivity-analytics-components/filter-productivity-flyin'
import { useProductivityAnalyticsPersistedStore } from '../../stores/productivity-store'
import ProductivityOverviewTab from '../../components/productivity-analytics-components/productivity-overview-tab'
import ProductivityPersonTab from '../../components/productivity-analytics-components/productivity-person-tab'
import { OrderDirection, ProductivityAnalyticsViewPeriods } from '@epix-web-apps/core'
import ProductivityBradfordTab from '../../components/productivity-analytics-components/productivity-bradford-tab/productivity-bradford-tab'

export enum ProductivityAnalyticsTabs {
  EVOLUTION,
  OVERVIEW,
  PERSON,
  BRADFORD
}

export interface ProductivityFilters {
  date: Date | null
  viewPeriod: ProductivityAnalyticsViewPeriods
  orderDirection: OrderDirection
  employer: string | null
  paygroup: string | null
  subgroups: string[]
}

/* eslint-disable-next-line */
export interface ProductivityAnalyticsPageProps {}

function ProductivityAnalyticsPage(props: ProductivityAnalyticsPageProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const [tabIndex, setTabIndex] = useState(0)
  const store = useProductivityAnalyticsPersistedStore()

  const filters: ProductivityFilters = {
    date: store.dateFilter,
    viewPeriod: store.viewPeriod,
    orderDirection: store.orderDirectionFilter,
    employer: store.employerFilter,
    paygroup: store.paygroupFilter,
    subgroups: store.subgroupFilter
  }

  function updateStore(filter: ProductivityFilters) {
    store.setDateFilter(filter.date)
    store.setViewPeriod(filter.viewPeriod ?? ProductivityAnalyticsViewPeriods.MONTH)
    store.setOrderDirectionFilter(filter.orderDirection)
    store.setEmployerFilter(filter.employer)
    store.setPaygroupFilter(filter.paygroup)
    store.setSubgroupFilter(filter.subgroups)
  }

  return (
    <DetailPageLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography ml={2} variant="h2">
            {t('homepage.productivityanalytics')}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Typography>
              {t('productivity.filters.viewpoint')}:{' '}
              <strong>{filters.date ? filters.date.toLocaleDateString() : new Date().toLocaleDateString()}</strong>
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                openFlyIn({
                  content: <FilterProductivityFlyin filters={filters} updateStore={updateStore} currentTab={tabIndex} />
                })
              }}
            >
              {t('productivity.filters.title')}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Tabs value={tabIndex} onChange={(_, tabIndex) => setTabIndex(tabIndex)}>
            <Tab label={t('productivity.evolution.tab')} {...a11yProps(ProductivityAnalyticsTabs.EVOLUTION)} />
            <Tab label={t('productivity.overview.tab')} {...a11yProps(ProductivityAnalyticsTabs.OVERVIEW)} />
            <Tab label={t('productivity.person.tab')} {...a11yProps(ProductivityAnalyticsTabs.PERSON)} />
            <Tab label={t('productivity.bradford.tab')} {...a11yProps(ProductivityAnalyticsTabs.BRADFORD)} />
          </Tabs>
        </Box>

        <TabPanel index={ProductivityAnalyticsTabs.EVOLUTION} value={tabIndex}>
          <SuspensePaper>
            <ProductivityEvolutionTab />
          </SuspensePaper>
        </TabPanel>

        <TabPanel index={ProductivityAnalyticsTabs.OVERVIEW} value={tabIndex}>
          <ProductivityOverviewTab />
        </TabPanel>

        <TabPanel index={ProductivityAnalyticsTabs.PERSON} value={tabIndex}>
          <SuspensePaper>
            <ProductivityPersonTab />
          </SuspensePaper>
          <Typography color={theme.palette.text.secondary}>{t('productivity.person.first100records')}</Typography>
        </TabPanel>

        <TabPanel index={ProductivityAnalyticsTabs.BRADFORD} value={tabIndex}>
          <SuspensePaper sx={{ border: 0, p: 0 }}>
            <ProductivityBradfordTab />
          </SuspensePaper>
          <Typography color={theme.palette.text.secondary}>{t('productivity.bradford.first100records')}</Typography>
        </TabPanel>
      </Box>
    </DetailPageLayout>
  )
}

export default ProductivityAnalyticsPage
