import { useTranslation } from 'react-i18next'
import { Box, Typography, TextField, Divider, useTheme, Button } from '@mui/material'
import {
  DEFAULT_CURRENCY,
  FormSelectOption,
  formTypeSelectOptions,
  useGetAllEmployersByIcpIdsQuery,
  useGetGroupTypesQuery,
  useGetMeQuery,
  useGetSubGroupTypesQuery
} from '@epix-web-apps/core'
import { useMemo, useState } from 'react'
import { FilterSelectBox, useGlobalPersistedStore } from '@epix-web-apps/ui'
import ListComponent from '../list-component'
import { EpixDatePicker } from '../../epix-date-picker'
import { useFlyIn } from '@epix-web-apps/ui'
import { LabourCostFilters } from '../../../pages/labour-cost-analytics-page/labour-cost-analytics-page'
import { endOfMonth, startOfYear } from 'date-fns'
import CheckBoxTree, { CheckboxTreeNode } from '../../filter-components/check-box-tree'

const DEFAULT_GROUP_TYPE_SELECTED = 'E06'
export const DEFAULT_SUBGROUP_TYPE_SELECTED = [
  'E06010',
  'E06020',
  'E06030',
  'E06040',
  'E06050',
  'E06060',
  'E06070',
  'E06080',
  'E06090',
  'E06990'
]

/* eslint-disable-next-line */
export interface FilterLabourCostFlyinProps {
  filters: LabourCostFilters
  updateStore: (filters: LabourCostFilters) => void
}

export function FilterLabourCostFlyin({ filters: initialFilters, updateStore }: FilterLabourCostFlyinProps) {
  const { closeFlyIn } = useFlyIn()
  const { t } = useTranslation()
  const theme = useTheme()
  const { icpFilters } = useGlobalPersistedStore()

  const { data: me } = useGetMeQuery()
  const { data: getAllSubGroups } = useGetSubGroupTypesQuery()
  const { data: getAllGroupTypes } = useGetGroupTypesQuery()

  const { data: employerData } = useGetAllEmployersByIcpIdsQuery({ icps: icpFilters })
  const employerOptions = employerData?.employersByIcpIds.map(e => new FormSelectOption(e.id, e.companyName))

  const { genderOptions, employeeOptions, functionAreaOptions, functionLevelOptions, currencyOptions } =
    formTypeSelectOptions
  const [filters, setFilters] = useState(initialFilters)

  const tree = useMemo(() => {
    const subGroupTypeTree = getAllGroupTypes?.groupTypes.map(g => {
      const children = getAllSubGroups?.subGroupTypes
        .filter(s => s.parentKey === g.typeModel.key)
        .map(s => {
          return {
            id: s.key,
            key: s.key,
            value: s.value,
            checked: filters.subGroupTypesFilter ? filters.subGroupTypesFilter.includes(s.key) : false,
            children: []
          } as CheckboxTreeNode
        })

      return {
        id: g.typeModel.key,
        key: null,
        value: g.typeModel.value,
        checked: false,
        children: children ?? []
      } as CheckboxTreeNode
    })
    return subGroupTypeTree ?? []
  }, [getAllGroupTypes, getAllSubGroups, filters.subGroupTypesFilter])

  function handleFilter() {
    updateStore(filters)
    closeFlyIn()
  }

  function handleReset() {
    updateStore({
      dateFrom: startOfYear(new Date()),
      dateTo: endOfMonth(new Date()),
      currency: me?.me.currency || DEFAULT_CURRENCY,
      gender: null,
      functionArea: null,
      functionLevel: null,
      employer: null,
      employeeType: null,
      subGroupTypesFilter:
        getAllSubGroups?.subGroupTypes.filter(s => s.parentKey === DEFAULT_GROUP_TYPE_SELECTED).map(s => s.key) ?? []
    })
    closeFlyIn()
  }

  return (
    <>
      <Box sx={{ width: '25rem', display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Typography color={theme.palette.primary.main} variant="h4">
            {t('analytics.filters.title')}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <EpixDatePicker
            onChange={e => (e ? setFilters({ ...filters, dateFrom: e }) : null)}
            label={t('analytics.datepicker.startdate')}
            value={filters.dateFrom}
            renderInput={params => (
              <TextField sx={{ width: '100%', font: theme.palette.common.white }} size="small" {...params} />
            )}
            componentsProps={{
              actionBar: {
                actions: ['today']
              }
            }}
          />
          <EpixDatePicker
            onChange={e => (e ? setFilters({ ...filters, dateTo: e }) : null)}
            label={t('analytics.datepicker.enddate')}
            value={filters.dateTo}
            renderInput={params => (
              <TextField sx={{ width: '100%', font: theme.palette.common.white }} size="small" {...params} />
            )}
            componentsProps={{
              actionBar: {
                actions: ['today']
              }
            }}
          />
          <FilterSelectBox
            id={'currencyId'}
            label={t('analytics.sidebar.currencies')}
            keys={currencyOptions}
            value={filters.currency}
            handleChange={(e: string) => setFilters({ ...filters, currency: e })}
          />
        </Box>

        <Box>
          <Divider sx={{ borderColor: theme.palette.divider }} />
          <ListComponent listItemText={t('analytics.sidebar.employees')}>
            <FilterSelectBox
              id={'genderId'}
              label={t('analytics.sidebar.gender')}
              keys={genderOptions}
              value={filters.gender}
              handleChange={(e: string) => setFilters({ ...filters, gender: e })}
            />
            <FilterSelectBox
              id={'functionAreaId'}
              label={t('analytics.sidebar.functionarea')}
              keys={functionAreaOptions}
              value={filters.functionArea}
              handleChange={(e: string) => setFilters({ ...filters, functionArea: e })}
            />
            <FilterSelectBox
              id={'functionLevelId'}
              label={t('analytics.sidebar.functionlevel')}
              keys={functionLevelOptions}
              value={filters.functionLevel}
              handleChange={(e: string) => setFilters({ ...filters, functionLevel: e })}
            />
          </ListComponent>
          <Divider sx={{ borderColor: theme.palette.divider }} />
          <ListComponent listItemText={t('analytics.sidebar.contracts')}>
            <FilterSelectBox
              id={'employeeId'}
              label={t('analytics.sidebar.employee')}
              keys={employerOptions}
              value={filters.employer}
              handleChange={(e: string) => setFilters({ ...filters, employer: e })}
            />
            <FilterSelectBox
              id={'employeeId'}
              label={t('analytics.sidebar.employeetype')}
              keys={employeeOptions}
              value={filters.employeeType}
              handleChange={(e: string) => setFilters({ ...filters, employeeType: e })}
            />
          </ListComponent>
          <Divider sx={{ borderColor: theme.palette.divider }} />
          <ListComponent listItemText={t('analytics.sidebar.payroll')}>
            {tree.map(t => (
              <CheckBoxTree
                key={t.id}
                treeNode={t}
                filters={filters.subGroupTypesFilter ?? []}
                handleChange={(e: string[]) => {
                  setFilters({
                    ...filters,
                    subGroupTypesFilter: e
                  })
                }}
              />
            ))}
          </ListComponent>
          <Divider sx={{ borderColor: theme.palette.divider }} />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          py: 2,
          zIndex: 10
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button variant="contained" onClick={handleFilter}>
              {t('analytics.flyin.filter')}
            </Button>
            <Button variant="outlined" onClick={closeFlyIn}>
              {t('analytics.flyin.cancel')}
            </Button>
          </Box>
          <Button variant="text" onClick={handleReset}>
            {t('analytics.flyin.reset')}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default FilterLabourCostFlyin
