import { Box, SvgIconTypeMap, Typography, useTheme } from '@mui/material'
import styled from '@emotion/styled'

/* eslint-disable-next-line */
export interface OnboardingLayoutProps {
  title: string
  icon: React.ReactElement<SvgIconTypeMap>
}

const StyledBox = styled(Box)<any>(() => {
  const theme = useTheme()
  return {
    marginTop: 2,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    svg: {
      marginBottom: 30,
      color: theme.palette.text.secondary,
      height: '6rem',
      width: '6rem'
    },
    h4: {
      color: theme.palette.text.secondary,
      mb: 2
    },
    '::after': {
      content: '""',
      position: 'absolute',
      top: 260,
      transform: 'translateX(-50%)',
      width: '6rem',
      height: '1.2rem',
      backgroundColor: '#F2F2F2',
      borderRadius: '60%',
      zIndex: 0
    }
  }
})

export function OnboardingLayout({ title, icon }: OnboardingLayoutProps) {
  return (
    <StyledBox>
      {icon}
      <Typography variant="h4">{title}</Typography>
    </StyledBox>
  )
}

export default OnboardingLayout
