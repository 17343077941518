import { ListItemButton, ListItemIcon, ListItemText, SxProps, Theme, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

export interface ListItemProps {
  to: string
  icon: any
  text: string
  sx?: SxProps<Theme> | undefined
}

/* eslint-disable-next-line */
export interface ListItemTooltipComponentProps extends ListItemProps {
  open?: boolean
}

export const ListItem = ({ to, icon, text, sx }: ListItemProps) => {
  const location = useLocation()
  return (
    <ListItemButton sx={sx ? sx : { mx: 2, padding: 0.5 }} selected={location.pathname + location.search === to} component={Link} to={to}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  )
}

export function ListItemTooltipComponent({ to, icon, text, open = undefined }: ListItemTooltipComponentProps) {
  return !open ? (
    <Tooltip title={text} placement="right">
      <>
        <ListItem to={to} icon={icon} text={text} sx={{}} />
      </>
    </Tooltip>
  ) : (
    <ListItem to={to} icon={icon} text={text} sx={{}} />
  )
}

export default ListItemTooltipComponent
