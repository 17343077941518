/* eslint-disable-next-line */
export interface EpixLoadingPageProps {
  message?: string
}

export function EpixLoadingPage({ message }: EpixLoadingPageProps) {
  return (
    <div id="loadingpage">
      <div className="container">
        <div className="logo">
          <img src="/assets/epix-logo-white.png" />
        </div>
        <div className="spinner-container">
          <div className="spinner"></div>
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  )
}

export default EpixLoadingPage
