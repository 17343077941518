import {
  useCreateEmployerMutation,
  useGetEmployerByIdQuery,
  useGetFeaturesQuery,
  useUpdateEmployerMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography, Box, Grid } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { boolean, object, string, TypeOf } from 'zod'
import { DetailPageBaseQueryParams, FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { useForm, useWatch } from 'react-hook-form'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface AddEditEmployerProps {
  employerId?: string
  create?: boolean
}

export function AddEditEmployer({ employerId, create = false }: AddEditEmployerProps) {
  const { t } = useTranslation()
  const addEditEmployerSchema = object({
    companyName: string({
      required_error: t('form.validation.companynamerequired'),
      invalid_type_error: t('form.validation.companynamerequired')
    }).min(1, t('form.validation.companynamerequired')),
    number: string({
      required_error: t('form.validation.numberrequired'),
      invalid_type_error: t('form.validation.numberrequired')
    }).min(1, t('form.validation.numberrequired')),
    companyRegistrationNumber: string().nullable(),
    nationalSocialSecurityNumber: string().nullable(),
    providerPayrollContactEmail: string().nullable(),
    calculatedByEpix: boolean(),
    hasPayComponentManagement: boolean(),
    hasCalendarManagement: boolean(),
    hasDocumentManagement: boolean(),
    hasSelfService: boolean(),
    hasCompanyCars: boolean(),
    active: boolean()
  })
  type CreateEditEmployerForm = TypeOf<typeof addEditEmployerSchema>
  const { closeFlyIn } = useFlyIn()
  const createmutation = useCreateEmployerMutation()
  const updatemutation = useUpdateEmployerMutation()
  const params = useParams<DetailPageBaseQueryParams>()
  const { data: getFeatures } = useGetFeaturesQuery()

  const { data: getEmployerById, refetch: refetchEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: employerId ?? ''
    },
    {
      enabled: !create
    }
  )

  const form = useForm<CreateEditEmployerForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      companyName: getEmployerById?.employerById.companyName,
      number: getEmployerById?.employerById.number,
      companyRegistrationNumber: getEmployerById?.employerById.companyRegistrationNumber,
      nationalSocialSecurityNumber: getEmployerById?.employerById.nationalSocialSecurityNumber,
      providerPayrollContactEmail: getEmployerById?.employerById.providerPayrollContactEmail,
      calculatedByEpix: getEmployerById?.employerById.calculatedByEpix ?? false,
      hasCalendarManagement: getEmployerById?.employerById.hasCalendarManagement,
      hasDocumentManagement: getEmployerById?.employerById.hasDocumentManagement ?? false,
      hasPayComponentManagement: getEmployerById?.employerById.hasPayComponentManagement,
      active: getEmployerById?.employerById.active ?? true,
      hasSelfService: getEmployerById?.employerById.hasSelfService ?? false,
      hasCompanyCars: getEmployerById?.employerById.hasCompanyCars ?? false
    },
    resolver: zodResolver(addEditEmployerSchema)
  })

  const { control } = form

  const handleOnSubmit = async (newEmployer: CreateEditEmployerForm) => {
    if (create) {
      await createmutation
        .mutateAsync({
          createEmployerCommand: {
            icpId: params.id || '',
            companyName: newEmployer.companyName,
            number: newEmployer.number,
            companyRegistrationNumber: newEmployer.companyRegistrationNumber,
            nationalSocialSecurityNumber: newEmployer.nationalSocialSecurityNumber,
            providerPayrollContactEmail: newEmployer.providerPayrollContactEmail,
            calculatedByEpix: newEmployer.calculatedByEpix,
            hasPayComponentManagement: newEmployer.hasPayComponentManagement,
            hasCalendarManagement: newEmployer.hasCalendarManagement,
            hasDocumentManagement: newEmployer.hasDocumentManagement,
            hasSelfService: newEmployer.hasSelfService,
            hasCompanyCars: newEmployer.hasCompanyCars,
            active: newEmployer.active
          }
        })
        .then(closeFlyIn)
    } else {
      await updatemutation
        .mutateAsync({
          updateEmployerCommand: {
            id: employerId ?? '',
            companyName: newEmployer.companyName,
            number: newEmployer.number,
            companyRegistrationNumber: newEmployer.companyRegistrationNumber,
            nationalSocialSecurityNumber: newEmployer.nationalSocialSecurityNumber,
            providerPayrollContactEmail: newEmployer.providerPayrollContactEmail,
            calculatedByEpix: newEmployer.calculatedByEpix,
            hasPayComponentManagement: newEmployer.hasPayComponentManagement,
            hasCalendarManagement: newEmployer.hasCalendarManagement,
            hasDocumentManagement: newEmployer.hasDocumentManagement,
            hasSelfService: newEmployer.hasSelfService,
            hasCompanyCars: newEmployer.hasCompanyCars,
            active: newEmployer.active
          }
        })
        .then(() => {
          refetchEmployerById()
          closeFlyIn()
        })
    }
  }

  const watchCalendarManagement = useWatch({
    control,
    name: 'hasCalendarManagement'
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addemployer.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="companyName" label={`${t('form.field.employer')} *`} />

        <FormInput sx={12} name="number" label={`${t('form.field.employernumber')} *`} />

        <FormInput sx={12} name="companyRegistrationNumber" label={`${t('form.field.companyregistrationnumber')}`} />

        <FormInput sx={12} name="nationalSocialSecurityNumber" label={`${t('form.field.socialsecuritynumber')}`} />

        <FormInput
          sx={12}
          name="providerPayrollContactEmail"
          label={`${t('form.field.providerpayrollcontactemail')}`}
        />

        <FormSwitch
          sx={12}
          name="calculatedByEpix"
          onChange={e => {
            if (e) {
              form.resetField('hasPayComponentManagement', {
                defaultValue: true
              })
              form.resetField('hasCalendarManagement', { defaultValue: true })
              form.resetField('hasSelfService', { defaultValue: true })
            }
          }}
          label={t('form.field.payrollviaepix')}
        />

        <FormSwitch sx={12} name="hasPayComponentManagement" label={t('form.field.paycomponentmanagement')} />

        <FormSwitch
          sx={12}
          name="hasCalendarManagement"
          onChange={e => {
            if (!e) {
              form.resetField('hasSelfService', { defaultValue: false })
            }
          }}
          label={t('form.field.calendarmanagement')}
        />

        <Box ml={8}>
          <FormSwitch
            sx={12}
            disabled={!watchCalendarManagement}
            name="hasSelfService"
            label={t('form.field.selfservice.selfservice')}
          />
        </Box>

        <FormSwitch sx={12} name="hasCompanyCars" label={t('form.field.hascompanycars')} />

        {getFeatures?.features.documentManagement && (
          <FormSwitch sx={12} name="hasDocumentManagement" label={t('form.field.documentmanagement')} />
        )}

        <FormSwitch sx={12} name="active" label={t('form.field.active')} />

        <Grid xs={12} item>
          <Typography sx={{ fontStyle: 'italic' }}>
            <Trans
              i18nKey="form.field.activeemployerdescription"
              values={{
                settingscode: getEmployerById?.employerById.settingsCode
              }}
              components={{ bold: <span className="bold" /> }}
            />
          </Typography>
        </Grid>
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditEmployer
