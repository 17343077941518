import { useTranslation } from 'react-i18next'
import { Typography, useTheme } from '@mui/material'
import { FormActionButtons, FormContainer, FormErrorList, useFlyIn } from '@epix-web-apps/ui'
import {
  numericString,
  useGetPhocusContractDetailsQuery,
  useUpdatePhocusContractDetailTaxesMutation
} from '@epix-web-apps/core'
import { TypeOf, boolean, number, object } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormSwitch } from '../../../form-components/form-switch'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface EditPhocusTaxesProps {
  contractId: string | undefined
}

export function EditPhocusTaxes({ contractId }: EditPhocusTaxesProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const theme = useTheme()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } = useGetPhocusContractDetailsQuery({
    contractId: contractId || ''
  })

  const editPhocusTaxesSchema = object({
    wageTaxCredit: boolean(),
    annualWageSpecialRate: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    ),
    annualWage: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    )
  })

  type EditPhocusTaxesForm = TypeOf<typeof editPhocusTaxesSchema>

  const updateMutation = useUpdatePhocusContractDetailTaxesMutation()

  const form = useForm<EditPhocusTaxesForm>({
    resolver: zodResolver(editPhocusTaxesSchema),
    defaultValues: {
      wageTaxCredit:
        partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailTaxesModel.wageTaxCredit,
      annualWageSpecialRate:
        partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailTaxesModel.annualWageSpecialRate,
      annualWage: partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailTaxesModel.annualWage
    }
  })

  const handleOnSubmit = async (phocusTaxes: EditPhocusTaxesForm) => {
    await updateMutation
      .mutateAsync({
        phocusContractDetailTaxesCommand: {
          id: partnerSpecificDetails?.phocusContractDetailByContractId.id || '',
          wageTaxCredit: phocusTaxes.wageTaxCredit,
          annualWageSpecialRate: phocusTaxes.annualWageSpecialRate,
          annualWage: phocusTaxes.annualWage
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editphocustaxes.title')}</Typography>

      <FormGridLayout>
        <FormSwitch sx={12} name="wageTaxCredit" label={t('form.field.wagetaxcredit')} />
        <FormInput sx={12} name="annualWageSpecialRate" label={t('form.field.annualwagespecialrate')} />
        <FormInput sx={12} name="annualWage" label={t('form.field.annualwage')} />
      </FormGridLayout>

      <Typography sx={{ fontStyle: 'italic', color: theme.palette.text.secondary }}>
        <p>{t('flyin.editphocustaxes.info1')}</p>
        <br />
        <p>{t('flyin.editphocustaxes.info2')}</p>
        <p>{t('flyin.editphocustaxes.info3')}</p>
        <p>{t('flyin.editphocustaxes.info4')}</p>
        <p>{t('flyin.editphocustaxes.info5')}</p>
      </Typography>

      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPhocusTaxes
