import { getNumberOfColors, ToBackendFormatedDate, useGetProductivityForPeopleQuery } from '@epix-web-apps/core'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'
import BarchartComponent from '../../analytics-components/barchart-component/barchart-component'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

function ProductivityPersonTab() {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, employerFilter, subgroupFilter, viewPeriod, paygroupFilter, orderDirectionFilter } =
    useProductivityAnalyticsPersistedStore()
  const { data: getProductivityForPeople } = useGetProductivityForPeopleQuery({
    viewDate: dateFilter ? ToBackendFormatedDate(dateFilter) : null,
    viewPeriodKey: viewPeriod,
    employerId: employerFilter,
    paygroupId: paygroupFilter,
    subgroupKeys: subgroupFilter,
    orderDirection: orderDirectionFilter,
    icps: icpFilters
  })

  const indexByKey = 'name'
  const keys = new Set(
    getProductivityForPeople?.productivityForPeople.flatMap(p =>
      p.payrollSubGroupHours.flatMap(psgh => psgh.subGroupName)
    )
  )

  const data = getProductivityForPeople?.productivityForPeople.map(p => {
    const record: Record<string, any> = {}
    record[indexByKey] = `${p.name} - ${p.countryKey}`
    p.payrollSubGroupHours.forEach(psgh => {
      record[psgh.subGroupName] = psgh.amount
    })
    return record
  })

  if (data?.length === 0) {
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
  }

  return (
    <BarchartComponent
      keys={Array.from(keys)}
      indexBy={indexByKey}
      data={data?.reverse()}
      groupMode="stacked"
      layoutMode="horizontal"
      colors={getNumberOfColors(keys.size)}
      marginRight={50}
      marginLeft={200}
      legendCharLength={25}
      legendWidth={175}
      showLegend={keys.size <= 5}
      axisBottom={t('barchartcomponent.component.axe.hours')}
    />
  )
}

export default ProductivityPersonTab
