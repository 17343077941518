import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, IconButton, SxProps, TextField, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { EpixDatePicker } from '../../epix-date-picker'
import { DateRange, LastDayOfMonth, nextMonth, prevMonth } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface DateComponentProps {
  label?: string
  startDate: Date
  onChange?: (period: DateRange) => void
}

const sxPopper: SxProps = {
  '& .MuiInputBase-input': {
    padding: '0',
    margin: '0'
  }
}

export function PeriodNavigationDatePicker({ startDate, onChange }: DateComponentProps) {
  const theme = useTheme()
  const [period, setPeriod] = useState<DateRange>({
    startDate: startDate,
    endDate: LastDayOfMonth(startDate)
  })

  useEffect(() => {
    setPeriod(period)
    onChange && onChange(period)
  }, [period])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        color: theme.palette.text.secondary,
        maxWidth: '13rem'
      }}
    >
      <IconButton size="small" onClick={() => setPeriod(prevMonth(period))}>
        <KeyboardArrowLeftIcon fontSize="medium" sx={{ verticalAlign: 'bottom' }} />
      </IconButton>
      <EpixDatePicker
        openTo="month"
        value={startDate}
        onAccept={(newStartDate: any) => {
          const period = {
            startDate: newStartDate,
            endDate: LastDayOfMonth(newStartDate)
          } as DateRange
          setPeriod(period)
          onChange && onChange(period)
        }}
        views={['year', 'month']}
        InputProps={{
          disableUnderline: true,
          sx: sxPopper
        }}
        renderInput={params => <TextField onKeyDown={(e: any) => e.preventDefault()} variant="standard" {...params} />}
      />
      <IconButton size="small" onClick={() => setPeriod(nextMonth(period))}>
        <KeyboardArrowRightIcon fontSize="medium" sx={{ verticalAlign: 'bottom' }} />
      </IconButton>
    </Box>
  )
}

export default PeriodNavigationDatePicker
