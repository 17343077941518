import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined'
import { isSelfServiceUser, useGetMeQuery } from '@epix-web-apps/core'
import { Root } from '../../constants-routing'

/* eslint-disable-next-line */
export interface NotMobileOptimizedProps {
  setSelfServiceMode: (selfServiceMode: boolean) => void
}

export function NotMobileOptimized({ setSelfServiceMode }: NotMobileOptimizedProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: me } = useGetMeQuery()
  const hasSelfServiceAccess = isSelfServiceUser(me?.me.roles)

  return (
    <Box
      className="not-mobile-optimized"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant="h1">{t('notoptimizedformobile.title')}</Typography>
      {hasSelfServiceAccess && (
        <Button
          variant="contained"
          onClick={() => {
            navigate(Root.ROOT, {})
            setSelfServiceMode(true)
          }}
        >
          <RoomServiceOutlinedIcon />
          {t('usermenu.switchtoselfservice')}
        </Button>
      )}
    </Box>
  )
}

export default NotMobileOptimized
