import { ReactNode, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DropdownMenu from '../dropdown-menu/dropdown-menu'

export interface FilterButtonProps {
  children?: ReactNode
  filters: { [key: string]: boolean }
}

function FilterButton({ children, filters }: FilterButtonProps) {
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null)
  const filterShouldBeActive = Object.values(filters).some(item => item)

  return (
    <>
      <IconButton
        sx={{ p: 0.5 }}
        aria-label="filter button"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setFilterAnchorEl(e.currentTarget)
        }}
      >
        {filterShouldBeActive ? <FilterAltIcon color="primary" /> : <FilterAltOutlinedIcon />}
      </IconButton>

      <DropdownMenu
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        closeOnChildClicked={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box sx={{ px: 2 }}>{children}</Box>
      </DropdownMenu>
    </>
  )
}

export default FilterButton
