import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { createEnhancedJSONStorage, OrderDirection, ProductivityAnalyticsViewPeriods } from '@epix-web-apps/core'

interface IProductivityAnalyticsPersistedStore {
  dateFilter: Date | null
  setDateFilter: (date: Date | null) => void
  viewPeriod: ProductivityAnalyticsViewPeriods
  setViewPeriod: (viewPeriod: ProductivityAnalyticsViewPeriods) => void
  orderDirectionFilter: OrderDirection
  setOrderDirectionFilter: (orderDirection: OrderDirection) => void
  employerFilter: string | null
  setEmployerFilter: (employer: string | null) => void
  paygroupFilter: string | null
  setPaygroupFilter: (paygroup: string | null) => void
  subgroupFilter: string[]
  setSubgroupFilter: (subgroup: string[]) => void
}

export const useProductivityAnalyticsPersistedStore = create<IProductivityAnalyticsPersistedStore>()(
  persist(
    (set, get) => ({
      dateFilter: null,
      setDateFilter: async (date: Date | null) => {
        set({ dateFilter: date })
      },
      viewPeriod: ProductivityAnalyticsViewPeriods.MONTH,
      setViewPeriod: async (viewPeriod: ProductivityAnalyticsViewPeriods) => {
        set({ viewPeriod: viewPeriod })
      },
      orderDirectionFilter: OrderDirection.Desc,
      setOrderDirectionFilter: async (orderDirection: OrderDirection) => {
        set({ orderDirectionFilter: orderDirection })
      },
      employerFilter: null,
      setEmployerFilter: async (employer: string | null) => {
        set({ employerFilter: employer })
      },
      paygroupFilter: null,
      setPaygroupFilter: async (paygroup: string | null) => {
        set({ paygroupFilter: paygroup })
      },
      subgroupFilter: ['E01020', 'E01010', 'E01040', 'E01990', 'E01030'],
      setSubgroupFilter: async (subgroups: string[]) => {
        set({ subgroupFilter: subgroups })
      }
    }),
    { name: 'productivityAnalyticsPersistedStore', storage: createEnhancedJSONStorage(() => localStorage) }
  )
)
