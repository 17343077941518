import { FEATURE, ROLE, isAuthorized, useGetFeaturesQuery, useGetMeQuery } from '@epix-web-apps/core'
import { Fragment, ReactNode } from 'react'
import NotAuthorizedPage from '../../pages/not-authorized-page'

/* eslint-disable-next-line */
export interface IsAuthorizedProps {}

export function IsAuthorized({
  children,
  roles,
  feature
}: {
  children: ReactNode
  roles?: Array<ROLE>
  feature?: FEATURE
}) {
  const { data: me } = useGetMeQuery(undefined, { staleTime: 1000 * 60 * 5 })
  const { data: getFeatures } = useGetFeaturesQuery()

  if (isAuthorized(me?.me.roles, roles) && (!feature || getFeatures?.features[feature])) {
    return <Fragment>{children}</Fragment>
  }

  return <NotAuthorizedPage />
}

export default IsAuthorized
