import {
  DEFAULT_DAY_DEFINITION,
  FormSelectOption,
  getReferenceFullDay,
  OrderDirection,
  useGetAllDayDefinitionsByEmployerIdQuery,
  useGetAllShiftCodesByEmployerIdQuery,
  useGetEmployerByIdQuery,
  useGetMeQuery,
  useGetWorkScheduleByIdQuery,
  useUpdateWorkScheduleDayMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { number, object, string, TypeOf } from 'zod'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface EditDayEntriesProps {
  employerId: string | undefined
  workScheduleId: string
}

export function EditDayEntries({ employerId, workScheduleId }: EditDayEntriesProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: workSchedule, refetch: refetchWorkScheduleById } = useGetWorkScheduleByIdQuery({
    workScheduleId: workScheduleId
  })

  const { data: dayDefinitionsData } = useGetAllDayDefinitionsByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      sortByProperty: null,
      orderDirection: OrderDirection.Asc,
      includeDefault: true
    },
    {
      suspense: false
    }
  )

  const { data: shiftCodesData } = useGetAllShiftCodesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      sortByProperty: null,
      orderDirection: OrderDirection.Asc
    },
    {
      enabled: !!employerId
    }
  )

  const GetDefaultDayDefinition = (): any => {
    return dayDefinitionsData?.dayDefinitions.data.find(day => day.code === DEFAULT_DAY_DEFINITION)?.id
  }

  const EditDayEntrySchema = object({
    dayDefinitionId: string({
      required_error: t('form.validation.daydefinitionrequired'),
      invalid_type_error: t('form.validation.daydefinitionrequired')
    }).min(1, t('form.validation.daydefinitionrequired')),
    dayNumber: number(),
    shiftCodeId: string().optional().nullable()
  })

  const EditDayEntriesSchema = object({
    dayEntries: EditDayEntrySchema.array().min(1)
  })

  type EditDayEntriesForm = TypeOf<typeof EditDayEntriesSchema>

  const form = useForm<EditDayEntriesForm>({
    resolver: zodResolver(EditDayEntriesSchema),
    defaultValues: {
      dayEntries:
        workSchedule?.workScheduleById?.days.map(day => ({
          workScheduleId: workScheduleId,
          dayDefinitionId: day.dayDefinition?.id || GetDefaultDayDefinition(),
          dayNumber: day.dayNumber,
          shiftCodeId: day.shiftCodeId
        })) || []
    }
  })
  const {
    formState: { errors },
    control,
    handleSubmit
  } = form

  const { data: me } = useGetMeQuery()

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId || ''
  })

  const getWeeks = (value: number): number => {
    return Math.ceil((value + 1) / 7)
  }

  const getDay = (value: number): number => {
    return ((value - 1) % 7) + 1
  }

  const dayEntriesArray = useFieldArray({ control, name: 'dayEntries' })
  const company = getEmployerById?.employerById.companyName
  const mutation = useUpdateWorkScheduleDayMutation()

  const handleOnSubmit = async (newWorkSchedule: EditDayEntriesForm) => {
    if (workSchedule?.workScheduleById.isInUse) return

    const promises = dayEntriesArray.fields.map(async (item, index) => {
      return mutation.mutateAsync({
        updateWorkScheduleDayCommand: {
          dayNumber: newWorkSchedule.dayEntries[index].dayNumber,
          workScheduleId: workScheduleId,
          dayDefinitionId: newWorkSchedule.dayEntries[index].dayDefinitionId,
          shiftCodeId: newWorkSchedule.dayEntries[index].shiftCodeId
        }
      })
    })
    await Promise.all(promises).then(() => {
      refetchWorkScheduleById()
      closeFlyIn()
    })
  }

  return (
    <FormContainer form={form} onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.dayentries.title')}</Typography>
      <Typography variant="h5" color={'gray'}>
        {t('flyin.workschedule.subtitle')}: {company}
      </Typography>
      <Grid container columnSpacing={2} paddingBottom={3}>
        {dayEntriesArray.fields.map((item, index) => (
          <>
            <Box sx={{ width: '100%', ml: '1rem' }}>
              <Typography variant="h6">
                {`${getReferenceFullDay(
                  workSchedule?.workScheduleById.referenceStartDate,
                  me?.me.locale.locale,
                  index
                )} (${t('form.field.week')} ${getWeeks(index)}, ${t('form.field.day')} ${getDay(index + 1)})`}
              </Typography>
            </Box>
            <FormSelect
              disabled={workSchedule?.workScheduleById.isInUse}
              sx={7}
              key={`daydefinition-${index}`}
              name={`dayEntries.${index}.dayDefinitionId`}
              label={t('form.field.daydefinition')}
              options={dayDefinitionsData?.dayDefinitions.data.map(x => new FormSelectOption(x.id, x.description))}
            />
            <FormSelect
              disabled={workSchedule?.workScheduleById.isInUse}
              sx={5}
              key={`shiftcode-${index}`}
              name={`dayEntries.${index}.shiftCodeId`}
              label={t('form.field.shiftcode')}
              options={shiftCodesData?.allShiftCodesByEmployerId.data.map(
                x => new FormSelectOption(x.id, x.description)
              )}
            />
          </>
        ))}
      </Grid>
      <FormErrorList />
      {workSchedule?.workScheduleById.isInUse ? (
        <Button variant="contained" onClick={() => closeFlyIn()}>
          {t('common.close')}
        </Button>
      ) : (
        <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
      )}
    </FormContainer>
  )
}

export default EditDayEntries
