import { nationalityBarchartColor, useGetNationalityDistributionQuery } from '@epix-web-apps/core'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import { Typography, Box } from '@mui/material'
import BarchartComponent from '../barchart-component'

/* eslint-disable-next-line */
export interface NationalityComponentProps {}

export function NationalityComponent(props: NationalityComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const store = useAnalyticsPersistedStore()

  const { data: nationalityDistribution } = useGetNationalityDistributionQuery({
    icpIds: icpFilters,
    selectedDate: store.dateFilter,
    filterModel: {
      genderType: store.genderFilter,
      functionAreaType: store.functionAreaFilter,
      functionLevelType: store.functionLevelFilter,
      employeeType: store.employeeTypeFilter
    }
  })

  if (nationalityDistribution?.nationalityDistribution.entries.length === 0) {
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
  }

  return (
    <Box height={'90%'} minHeight={'400px'}>
      <BarchartComponent
        data={nationalityDistribution?.nationalityDistribution.entries.map(x => ({
          nationality: x.nationality,
          [t('analytics.amount')]: x.amount
        }))}
        keys={[t('analytics.amount')]}
        indexBy={'nationality'}
        groupMode={'grouped'}
        layoutMode="vertical"
        colors={nationalityBarchartColor}
        innerPadding={4}
        marginRight={50}
        marginLeft={50}
        showLegend={false}
      />
    </Box>
  )
}

export default NationalityComponent
