import { Box, Divider, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { DownloadButton } from '@epix-web-apps/ui'
import {
  PROCESSINGIMPORTJOBTYPES,
  byteArrayToFile,
  useExportCsvImportJobErrorsByJobIdQuery,
  useGetCsvImportJobByIdQuery,
  useGetProcessingImportJobTypesQuery
} from '@epix-web-apps/core'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { IImportRow, IMPORT_STATE } from '../../../pages/import-status-page/import-status-page'
import { useEffect, useState } from 'react'

/* eslint-disable-next-line */
export interface ImportDetailsProps {
  csvImportJobId: IImportRow
}

const useIconForState = (state: IMPORT_STATE) => {
  const theme = useTheme()

  switch (state) {
    case IMPORT_STATE.Queued:
      return <ScheduleOutlinedIcon sx={{ color: theme.palette.primary.main }} />
    case IMPORT_STATE.Processing:
      return <ChangeCircleOutlinedIcon sx={{ color: theme.palette.primary.main }} />
    case IMPORT_STATE.Success:
      return <CheckCircleOutlineOutlinedIcon sx={{ color: theme.palette.success.main }} />
    case IMPORT_STATE.Failed:
      return <CancelOutlinedIcon sx={{ color: theme.palette.error.main }} />
    default:
      return null
  }
}

export const RowState = (state: IMPORT_STATE) => {
  const icon = useIconForState(state)
  return icon
}

export function ImportDetails({ csvImportJobId }: ImportDetailsProps) {
  const { t } = useTranslation()
  const [download, setDownload] = useState(false)

  const { data: importById } = useGetCsvImportJobByIdQuery({
    id: csvImportJobId?.id || ''
  })

  const { data: processingImportJobTypes } = useGetProcessingImportJobTypesQuery()

  const { data: exportCsvJobErrors, isFetching: isFetchingDownload } = useExportCsvImportJobErrorsByJobIdQuery(
    {
      jobId: csvImportJobId?.id || ''
    },
    { enabled: download }
  )

  useEffect(() => {
    if (download && exportCsvJobErrors && exportCsvJobErrors.exportCsvImportJobErrorsByJobId) {
      byteArrayToFile(
        exportCsvJobErrors.exportCsvImportJobErrorsByJobId as unknown as Uint8Array,
        'importjoberrors.csv'
      )
      setDownload(false)
    }
  }, [exportCsvJobErrors?.exportCsvImportJobErrorsByJobId])

  const handleDownloadClick = () => {
    setDownload(true)
  }

  return (
    <Box
      sx={{
        width: '25rem',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Typography variant="h3">{t('configurationimport.status.detail.title')}</Typography>
      <Typography sx={{ display: 'flex', gap: 1, pb: 1 }}>
        <span>{useIconForState(csvImportJobId?.state)}</span>
        <span className="bold">{importById?.cvsImportJobById.statusType}</span>
      </Typography>
      <Divider />
      {importById?.cvsImportJobById.errors && importById?.cvsImportJobById.errors.length > 0 ? (
        <Typography sx={{ py: 1 }}>
          <Trans
            i18nKey="configurationimport.status.detail.descriptionfailed"
            values={{
              filename: importById?.cvsImportJobById?.fileName,
              type: importById?.cvsImportJobById?.processingImportJobType.value
            }}
            components={{ bold: <span className="bold" /> }}
          />
        </Typography>
      ) : (
        <Typography sx={{ py: 1 }}>
          <Trans
            i18nKey="configurationimport.status.detail.descriptionsuccess"
            values={{
              filename: importById?.cvsImportJobById.fileName,
              type: importById?.cvsImportJobById.processingImportJobType.value
            }}
            components={{ bold: <span className="bold" /> }}
          />
        </Typography>
      )}

      {importById?.cvsImportJobById.errors && importById?.cvsImportJobById.errors.length > 0 && (
        <Box>
          {(importById?.cvsImportJobById?.processingImportJobType ===
            processingImportJobTypes?.allProcessingImportJobTypes.find(
              x => x.key === PROCESSINGIMPORTJOBTYPES.CALCULATED_DATA
            ) ||
            importById?.cvsImportJobById.processingImportJobType ===
              processingImportJobTypes?.allProcessingImportJobTypes.find(
                x => x.key === PROCESSINGIMPORTJOBTYPES.PERSONAL_CALENDAR_DAYS
              ) ||
            importById?.cvsImportJobById.processingImportJobType ===
              processingImportJobTypes?.allProcessingImportJobTypes.find(
                x => x.key === PROCESSINGIMPORTJOBTYPES.MANUAL_EVENTS
              )) && (
            <Box
              sx={{
                display: 'flex',
                marginTop: 3,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography>{t('configurationimport.status.detail.errordownload')}</Typography>
              <DownloadButton isFetching={isFetchingDownload} onClick={handleDownloadClick} />
            </Box>
          )}
        </Box>
      )}
      {importById?.cvsImportJobById.errors && importById?.cvsImportJobById.errors.length > 0 && (
        <>
          <Divider />
          <Box sx={{ fontSize: 12 }}>
            <Typography sx={{ pt: 1 }}>
              <span className="bold">Errors</span>
            </Typography>
            {importById?.cvsImportJobById.errors.map(error => {
              return <Typography sx={{ pb: 1 }}>{error.message}</Typography>
            })}
          </Box>
        </>
      )}
      <Divider />
      <Typography sx={{ fontSize: 12, pt: 3 }}>
        <Trans
          i18nKey="configurationimport.status.detail.datetimestarted"
          values={{
            started: new Date(importById?.cvsImportJobById.startedOn).toLocaleString()
          }}
          components={{ bold: <span className="bold" /> }}
        />
      </Typography>
      {importById?.cvsImportJobById.endedOn && (
        <Typography sx={{ fontSize: 12 }}>
          <Trans
            i18nKey="configurationimport.status.detail.datetimeended"
            values={{
              ended: new Date(importById?.cvsImportJobById.endedOn).toLocaleString()
            }}
            components={{ bold: <span className="bold" /> }}
          />
        </Typography>
      )}
    </Box>
  )
}

export default ImportDetails
