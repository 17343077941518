import { ToBackendFormatedDate, useGetProductivityBradfordFactorsQuery } from '@epix-web-apps/core'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'
import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { TypographyBold, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { environment } from '../../../../../environments/environment'

function ProductivityBradfordTab() {
  const store = useProductivityAnalyticsPersistedStore()
  const theme = useTheme()
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()

  const { data: getProductivityBradfordFactorsQuery } = useGetProductivityBradfordFactorsQuery({
    viewDate: ToBackendFormatedDate(new Date()),
    employerId: store.employerFilter,
    paygroupId: store.paygroupFilter,
    orderDirection: store.orderDirectionFilter,
    icps: icpFilters,
    limit: 100
  })

  function BorderedTableCell(props: any) {
    return <TableCell sx={{ borderRight: `1px solid ${theme.palette.divider}` }}>{props.children}</TableCell>
  }

  return (
    <Box sx={{ overflowY: 'scroll', height: '100%', flex: 1 }}>
      <Box sx={{ height: '50vh' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TypographyBold color={theme.palette.primary.main}>
            {t('productivity.bradford.title')}{' '}
            {`${new Date(
              getProductivityBradfordFactorsQuery?.productivityBradfordFactors.startDate
            ).toLocaleDateString()} - ${new Date(
              getProductivityBradfordFactorsQuery?.productivityBradfordFactors.endDate
            ).toLocaleDateString()}`}
          </TypographyBold>
          <Link target="_blank" href={`${environment.docs.domainName}/Epix/epix-analytics/productivityanalytics/bradford`}>
            <Typography color={theme.palette.text.secondary}>{t('productivity.bradford.moreinfo')}</Typography>
          </Link>
        </Box>
        <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
          <TableHead sx={{ backgroundColor: theme.palette.grey[100], position: 'sticky', top: -1 }}>
            <TableRow>
              <BorderedTableCell>{t('productivity.bradford.table.name')}</BorderedTableCell>
              <BorderedTableCell>{t('productivity.bradford.table.country')}</BorderedTableCell>
              <BorderedTableCell>{t('productivity.bradford.table.employer')}</BorderedTableCell>
              <BorderedTableCell>{t('productivity.bradford.table.paygroup')}</BorderedTableCell>
              <BorderedTableCell>{t('productivity.bradford.table.periods')}</BorderedTableCell>
              <BorderedTableCell>{t('productivity.bradford.table.days')}</BorderedTableCell>
              <BorderedTableCell>{t('productivity.bradford.table.factor')}</BorderedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getProductivityBradfordFactorsQuery?.productivityBradfordFactors.people.length !== 0 &&
              getProductivityBradfordFactorsQuery?.productivityBradfordFactors.people.map(p => (
                <TableRow key={p.id}>
                  <BorderedTableCell>{p.name}</BorderedTableCell>
                  <BorderedTableCell>{p.countryKey}</BorderedTableCell>
                  <BorderedTableCell>{p.employerName}</BorderedTableCell>
                  <BorderedTableCell>{p.paygroupName}</BorderedTableCell>
                  <BorderedTableCell>{p.periods}</BorderedTableCell>
                  <BorderedTableCell>{p.days}</BorderedTableCell>
                  <BorderedTableCell>{p.factor}</BorderedTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {getProductivityBradfordFactorsQuery?.productivityBradfordFactors.people.length === 0 && (
          <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
        )}
      </Box>
    </Box>
  )
}

export default ProductivityBradfordTab
