import React, { Suspense, useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  List,
  Toolbar,
  useTheme,
  AppBar,
  Drawer,
  styled,
  Tooltip,
  MenuItem,
  Divider,
  ListItemIcon
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import EventIcon from '@mui/icons-material/Event'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyIcon from '@mui/icons-material/Key'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined'
import {
  ToRouteDateFormat,
  isOnlySelfServiceUser,
  useGetAllTeamsWithRoleForMeQuery,
  useGetFeaturesQuery,
  useGetMeQuery,
  useGetRequestPolicyContractsByPersonIdByTeamStructureQuery,
  useGetTeamsForMeForTeamStructureQuery
} from '@epix-web-apps/core'
import DropdownMenu from '../../myepix/components/dropdown-menu'
import { useMsal } from '@azure/msal-react'
import { logoutRequest, resetPasswordRequest } from '../../myepix/components/is-authenticted/is-authenticted'
import { ListItem } from '../../myepix/components/list-item-tooltip-component/list-item-tooltip-component'
import { EpixAvatar, LoadingOverlay, Root, SelfService, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { FlyInProvider } from '@epix-web-apps/ui'
import { FlyIn } from '@epix-web-apps/ui'

const DRAWER_WIDTH = '100%'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    boxSizing: 'border-box'
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root, & .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: 'white'
    },
    '& .MuiListItemButton-root:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& .MuiSvgIcon-root, & .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    },
    '& .MuiListItemButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      '& .MuiSvgIcon-root, & .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    }
  }
}))

const Main = styled('main', {
  shouldForwardProp: prop => prop !== 'open'
})<any>(({ open, theme }) => {
  return {
    overflow: 'hidden',
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + 1rem)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      padding: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: DRAWER_WIDTH
    })
  }
})

/* eslint-disable-next-line */
export interface DrawerMobileLayoutProps {}

export function DrawerMobileLayout(props: DrawerMobileLayoutProps) {
  const { setSelfServiceMode } = useGlobalPersistedStore()
  const { data: getMeQuery } = useGetMeQuery()
  const { data: getFeatures } = useGetFeaturesQuery()
  const navigate = useNavigate()
  const me = getMeQuery?.me
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = () => setOpen(!open)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { instance: msalInstance } = useMsal()
  const handleClose = () => setAnchorEl(null)
  const handleLogout = () => msalInstance.logout(logoutRequest)
  const handleResetPassword = () => msalInstance.loginRedirect(resetPasswordRequest)
  const handleProfileSettings = () => navigate(SelfService.PROFILE_SETTINGS())
  const showSelfServiceSwitch = !isOnlySelfServiceUser(me?.roles)

  const { data: getTeamsByPersonId } = useGetTeamsForMeForTeamStructureQuery()

  const { data: getRequestPolicyContractsByPersonIdQuery } =
    useGetRequestPolicyContractsByPersonIdByTeamStructureQuery()

  function hasRequestPolicies() {
    const requestPolicies = getRequestPolicyContractsByPersonIdQuery?.allRequestPolicyContractsByPersonIdByTeamStructure
    return requestPolicies ? requestPolicies.length > 0 : false
  }

  const { data: getAllTeamsWithRole } = useGetAllTeamsWithRoleForMeQuery()

  const teamsByPersonId = getTeamsByPersonId?.teamsForMeForTeamStructure || []
  const teamWithRole = getAllTeamsWithRole?.allTeamsWithRoleForMe || []

  const allTeams = teamsByPersonId.concat(teamWithRole).filter((team, index, self) => {
    return self.findIndex(t => t.id === team.id) === index
  })

  const [teamId, _] = useState(allTeams[0]?.id)

  useEffect(() => {
    if (me?.locale.locale) {
      sessionStorage.setItem('language', me.locale.locale)
      i18n.changeLanguage(me.locale.locale)
    }
  }, [me?.locale.locale])

  return (
    <FlyInProvider>
      <Box
        className="responsive-layout"
        sx={{
          display: 'flex',
          minHeight: '100vh'
        }}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                color: theme.palette.primary.light,
                marginRight: '36px',
                marginLeft: '-8px',
                [theme.breakpoints.up('sm')]: {
                  marginRight: '36px',
                  marginLeft: '-16px'
                },
                ...(open && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexGrow: 1
              }}
            >
              <Tooltip title={t('usermenu.tooltip.opensettings')}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={e => setAnchorEl(e.currentTarget)}
                  color="inherit"
                >
                  <EpixAvatar personId={me?.personId || ''}>
                    <PersonIcon />
                  </EpixAvatar>
                </IconButton>
              </Tooltip>
              <DropdownMenu anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem onClick={handleProfileSettings}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  {t('usermenu.profilesettings')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleResetPassword}>
                  <ListItemIcon>
                    <KeyIcon />
                  </ListItemIcon>
                  {t('usermenu.changepassword')}
                </MenuItem>
                {showSelfServiceSwitch && <Divider />}
                {showSelfServiceSwitch && (
                  <MenuItem
                    onClick={() => {
                      navigate(Root.ROOT, {})
                      setSelfServiceMode(false)
                    }}
                  >
                    <ListItemIcon>
                      <DesktopMacOutlinedIcon />
                    </ListItemIcon>
                    {t('usermenu.switchtomyepix')}
                  </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  {t('usermenu.signout')}
                </MenuItem>
              </DropdownMenu>
            </Box>
          </Toolbar>
        </AppBar>
        <StyledDrawer variant="persistent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1]
            }}
          >
            {open && (
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            )}
          </Toolbar>
          <List component="nav" onClick={toggleDrawer}>
            <ListItem to="/" icon={<HomeIcon />} text={t('side.menu.home')} />
            <ListItem
              to={`/selfservice/request-absence/${me?.personId}`}
              icon={<EventIcon />}
              text={t('side.menu.request-absence')}
            />
            {teamId && (
              <ListItem
                to={SelfService.TEAM_CALENDAR_TEAMID_ID(`${teamId}`, `${me?.personId}`)}
                icon={<EventIcon />}
                text={t('side.menu.team-calendar')}
              />
            )}
            <ListItem to={SelfService.MY_REQUESTS()} icon={<HelpOutlineIcon />} text={t('side.menu.my-requests')} />
            {me?.personId && hasRequestPolicies() && (
              <ListItem
                to={SelfService.BALANCE_OVERVIEW_DATE(`${ToRouteDateFormat(new Date())}`)}
                icon={<StackedBarChartIcon />}
                text={t('side.menu.allbalances')}
              />
            )}
            {getFeatures?.features.documentManagement && (
              <>
                <ListItem to={SelfService.DOCUMENTS()} icon={<FolderOutlinedIcon />} text={t('side.menu.documents')} />
                <ListItem
                  to={SelfService.PENDING_TASKS()}
                  icon={<TaskOutlinedIcon />}
                  text={t('side.menu.pending-tasks')}
                />
              </>
            )}
          </List>
        </StyledDrawer>
        <Main open={open}>
          <Suspense fallback={<LoadingOverlay />}>
            <Outlet />
          </Suspense>
        </Main>
      </Box>
      <FlyIn />
    </FlyInProvider>
  )
}

export default DrawerMobileLayout
