import {
  OrderDirection,
  useCreateFunctionLevelTypeMutation,
  useGetAllFunctionLevelTypesQuery,
  useGetFunctionLevelTypeByKeyQuery,
  useUpdateFunctionLevelTypeMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface AddEditFunctionLevelProps {
  functionLevelKey?: string
}

export function AddEditFunctionLevel({ functionLevelKey }: AddEditFunctionLevelProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditFunctionLevelSchema = object({
    key: string({
      required_error: t('form.validation.keyrequired'),
      invalid_type_error: t('form.validation.keyrequired')
    }).min(1, t('form.validation.keyrequired')),
    value: string({
      required_error: t('form.validation.valuerequired'),
      invalid_type_error: t('form.validation.valuerequired')
    }).min(1, t('form.validation.valuerequired')),
    isActive: boolean()
  })

  type CreateEditFunctionLevelForm = TypeOf<typeof addEditFunctionLevelSchema>

  const { data: functionLevelsData } = useGetAllFunctionLevelTypesQuery(
    {
      offset: 0,
      limit: -1,
      sortByProperty: 'key',
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )

  const { data: getFunctionLevelByKey, refetch: refetchFunctionLevelByKey } = useGetFunctionLevelTypeByKeyQuery(
    {
      key: functionLevelKey ?? ''
    },
    {
      enabled: !!functionLevelKey
    }
  )

  const form = useForm<CreateEditFunctionLevelForm>({
    resolver: zodResolver(addEditFunctionLevelSchema),
    defaultValues: {
      key: getFunctionLevelByKey?.functionLevelTypeByKey?.key,
      value: getFunctionLevelByKey?.functionLevelTypeByKey?.value,
      isActive: getFunctionLevelByKey?.functionLevelTypeByKey?.active ?? false
    }
  })

  const createmutation = useCreateFunctionLevelTypeMutation()
  const editmutation = useUpdateFunctionLevelTypeMutation()

  const handleOnSubmit = async (newFunctionLevel: CreateEditFunctionLevelForm) => {
    if (
      !functionLevelKey &&
      newFunctionLevel.key !==
        functionLevelsData?.functionLevelTypes.data.find(f => f.key.includes(newFunctionLevel.key))?.key
    ) {
      await createmutation
        .mutateAsync({
          createFunctionLevelTypeCommand: {
            key: newFunctionLevel.key,
            value: newFunctionLevel.value
          }
        })
        .then(closeFlyIn)
    } else {
      await editmutation
        .mutateAsync({
          updateFunctionLevelTypeCommand: {
            key: newFunctionLevel.key,
            value: newFunctionLevel.value,
            active: newFunctionLevel.isActive
          }
        })
        .then(() => {
          refetchFunctionLevelByKey()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditfunctionlevel.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="key" disabled={!!functionLevelKey} label={`${t('form.field.key')}`} />

        <FormInput sx={12} name="value" label={`${t('form.field.value')}`} />

        {functionLevelKey && (
          <FormSwitch
            sx={12}
            name="isActive"
            label={t('form.field.active')}
            disabled={getFunctionLevelByKey?.functionLevelTypeByKey?.inUseCount !== 0}
          />
        )}
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || editmutation.isLoading}
        onCancel={() => closeFlyIn()}
      />

      <Grid item xs={12}>
        <Typography color="gray">
          {t('functionlevelspage.flyin.amountofpeople')} {getFunctionLevelByKey?.functionLevelTypeByKey.inUseCount}
        </Typography>
      </Grid>
    </FormContainer>
  )
}

export default AddEditFunctionLevel
