import { OrderDirection, createEnhancedJSONStorage } from '@epix-web-apps/core'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface IPeopleFilterStore {
  selectedDate: Date
  setSelectedDate: (value: Date) => void
  showInactive: boolean
  setShowInactive: (value: boolean) => void
  showWithoutContract: boolean
  setShowWithoutContract: (value: boolean) => void
  showValidations: boolean
  setShowValidations: (value: boolean) => void
  sortByProperty: string
  setSortByProperty: (value: string) => void
  sortDirection: OrderDirection
  setSortDirection: (value: OrderDirection) => void
  page: number
  setPage: (value: number) => void
}

export const usePeopleFilterStore = create<IPeopleFilterStore>()(
  persist(
    (set, get) => ({
      selectedDate: new Date(),
      setSelectedDate: async (value: Date) => {
        set({ selectedDate: value })
      },
      showInactive: false,
      setShowInactive: async (value: boolean) => {
        set({ showInactive: value })
      },
      showWithoutContract: false,
      setShowWithoutContract: async (value: boolean) => {
        set({ showWithoutContract: value })
      },
      showValidations: false,
      setShowValidations: async (value: boolean) => {
        set({ showValidations: value })
      },
      sortByProperty: '',
      setSortByProperty: async (value: string) => {
        set({ sortByProperty: value })
      },
      sortDirection: OrderDirection.Asc,
      setSortDirection: async (value: OrderDirection) => {
        set({ sortDirection: value })
      },
      page: 0,
      setPage: async (value: number) => {
        set({ page: value })
      }
    }),
    {
      name: 'peopleFilterStore',
      storage: createEnhancedJSONStorage(() => sessionStorage)
    }
  )
)
