import { FormActionButtons, FormContainer, FormErrorList, FormSelect } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import {
  formTypeSelectOptions,
  numericString,
  useGetBuropartnerContractDetailsQuery,
  useUpdateBuropartnerContractDetailTaxesMutation
} from '@epix-web-apps/core'
import { TypeOf, number, object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

/* eslint-disable-next-line */
export interface EditBuropartnerTaxesProps {
  contractId: string | undefined
}

export function EditBuropartnerTaxes({ contractId }: EditBuropartnerTaxesProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useGetBuropartnerContractDetailsQuery({
      contractId: contractId || ''
    })

  const { churchDenominationTypeOptions, taxClassesOptions } = formTypeSelectOptions

  const editBuropartnerTaxesSchema = object({
    taxIdentificationNumber: string().optional().nullable(),
    taxClassType: string().optional().nullable(),
    churchDenominationType: string().optional().nullable(),
    childAllowance: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    )
  })

  type EditBuropartnerTaxesForm = TypeOf<typeof editBuropartnerTaxesSchema>

  const updateMutation = useUpdateBuropartnerContractDetailTaxesMutation()

  const form = useForm<EditBuropartnerTaxesForm>({
    resolver: zodResolver(editBuropartnerTaxesSchema),
    defaultValues: {
      taxIdentificationNumber:
        partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailTaxesModel
          .taxIdentificationNumber,
      taxClassType:
        partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailTaxesModel.taxClassType
          ?.key,
      childAllowance:
        partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailTaxesModel
          .childAllowance,
      churchDenominationType:
        partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailTaxesModel
          .churchDenominationType?.key
    }
  })

  const handleOnSubmit = async (buropartnerTaxes: EditBuropartnerTaxesForm) => {
    await updateMutation
      .mutateAsync({
        buropartnerContractDetailTaxesCommand: {
          id: partnerSpecificDetails?.buropartnerContractDetailByContractId.id || '',
          taxIdentificationNumber: buropartnerTaxes.taxIdentificationNumber,
          churchDenominationTypeKey: buropartnerTaxes.churchDenominationType,
          childAllowance: buropartnerTaxes.childAllowance,
          taxClassTypeKey: buropartnerTaxes.taxClassType
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editburoparnertaxes.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="taxIdentificationNumber" label={t('form.field.taxidentificationnumber')} />

        <FormSelect sx={12} name="taxClassType" label={t('form.field.taxclass')} options={taxClassesOptions} />

        <FormInput sx={12} name="childAllowance" label={t('form.field.childallowance')} />

        <FormSelect
          sx={12}
          name="churchDenominationType"
          label={t('form.field.churchdenomination')}
          options={churchDenominationTypeOptions}
        />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditBuropartnerTaxes
