import { Box, Button, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { FormContainer, FormErrorList, Process } from '@epix-web-apps/ui'
import { HeaderTitleNavigation } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { Finish, Start, StepParams } from '../generic-steps'
import {
  GraphqlError,
  PROCESS_TYPE,
  formTypeSelectOptions,
  numericString,
  useGetPayComponentHistoriesByPayComponentUpdateProcessParametersQuery,
  useGetPayComponentUpdateProcessByIdQuery,
  useGetSelectedPayComponentUpdateProcessContractsQuery,
  useUpdatePayComponentUpdateProcessContractsMutation,
  useUpdatePayComponentUpdateProcessMutation,
  useUpdateProcessMutation,
  useUpdateProcessStepMutation
} from '@epix-web-apps/core'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { TypeOf, boolean, date, number, object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Overview, PaycomponentParameters } from './steps'

/* eslint-disable-next-line */
export interface PaycomponentUpdateProps {}

export function PaycomponentUpdate(props: PaycomponentUpdateProps) {
  const { t } = useTranslation()
  const params = useParams<StepParams>()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const [clickedNext, setClickedNext] = useState<boolean>(true)

  const { data: getPayComponentUpdateProcess, refetch: refetchPayComponentUpdateProcess } =
    useGetPayComponentUpdateProcessByIdQuery(
      {
        id: params?.id || ''
      },
      {
        enabled: !!params?.id
      }
    )

  const { data: payComponentsData } = useGetPayComponentHistoriesByPayComponentUpdateProcessParametersQuery(
    {
      payComponentUpdateProcessId: params?.id || '',
      offset: 0,
      limit: -1
    },
    {
      suspense: false
    }
  )

  const { data: getSelectedPayComponentUpdateProcess } = useGetSelectedPayComponentUpdateProcessContractsQuery(
    {
      processId: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  const { salaryChangedReasonOptions } = formTypeSelectOptions

  const [activeStep, setActiveStep] = useState(
    getPayComponentUpdateProcess?.payComponentUpdateProcessById.currentStep || 1
  )
  const steps = [
    t('wizard.step.start'),
    t('wizard.step.parameters'),
    t('wizard.step.overview'),
    t('wizard.step.finish')
  ]

  enum step {
    START = 1,
    PARAMETERS = 2,
    OVERVIEW = 3,
    FINISH = 4
  }

  const overviewSchema = object({
    contractId: string({
      required_error: t('form.validation.contractrequired'),
      invalid_type_error: t('form.validation.contractrequired')
    }).min(1, t('form.validation.contractrequired')),
    payComponentHistoryId: string().optional().nullable(),
    newValue: numericString(
      number()
        .optional()
        .nullable()
        .refine(data => !(activeStep > step.PARAMETERS && data == null), {
          message: t('form.validation.newvaluerequired')
        })
    )
  })

  const payComponentUpdateProcessSchema = object({
    // step 1 parameters
    payrollProviderId: string({
      required_error: t('form.validation.contractproviderrequired'),
      invalid_type_error: t('form.validation.contractproviderrequired')
    }).min(1, t('form.validation.contractproviderrequired')),
    dueDate: date({
      required_error: t('form.validation.duedaterequired'),
      invalid_type_error: t('form.validation.duedaterequired')
    }),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    payGroupId: string().optional(),
    processName: string({
      required_error: t('form.validation.processnamerequired'),
      invalid_type_error: t('form.validation.processnamerequired')
    }).min(1, t('form.validation.processnamerequired')),
    notes: string().optional().nullable(),
    // step 2 parameters
    groupTypeKey: string()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && (data == null || data.trim() === '')), {
        message: t('form.validation.grouptyperequired')
      }),
    subGroupTypeKey: string()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && (data == null || data.trim() === '')), {
        message: t('form.validation.subgrouptyperequired')
      }),
    payrollCodeId: string()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && (data == null || data.trim() === '')), {
        message: t('form.validation.payrollcoderequired')
      }),
    valueTypeKey: string()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && (data == null || data.trim() === '')), {
        message: t('form.validation.valuetyperequired')
      }),
    reasonSalaryChangeTypeKey: string().optional().nullable(),
    affectedContractsParameterTypeKey: string()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && (data == null || data.trim() === '')), {
        message: t('form.validation.affectedcontractsparametertyperequired')
      }),
    referencePeriod: date()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && data == null), {
        message: t('form.validation.referencedaterequired')
      }),
    startDate: date()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && data == null), {
        message: t('form.validation.startdaterequired')
      }),
    endDate: date().optional().nullable(),
    updateOperationTypeKey: string()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && data == null), {
        message: t('form.validation.updateoperationtyperequired')
      }),
    excludeContracts: boolean()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && data == null), {
        message: t('form.validation.excludecontractsrequired')
      }),
    value: numericString(number().optional().nullable()),
    multiplicator: numericString(number().optional().nullable()),
    useFTE: boolean()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && data == null), {
        message: t('form.validation.usefterequired')
      }),
    decimals: numericString(
      number().refine(data => !(activeStep > step.START && data == null), {
        message: t('form.validation.decimalsrequired')
      })
    ),
    hasExistingValueOnStartDate: boolean()
      .optional()
      .nullable()
      .refine(data => !(activeStep > step.START && data == null), {
        message: t('form.validation.hasexistingvalueonstartdaterequired')
      }),
    comment: string().optional().nullable(),
    // step 3 parameters
    payComponentHistories: overviewSchema
      .array()
      .refine(data => !(activeStep > step.PARAMETERS && data.length < 1 && clickedNext), {
        message: t('form.validation.overviewrequired')
      }),
    contractIds: string()
      .array()
      .refine(data => !(activeStep > step.PARAMETERS && data.length < 1 && clickedNext), {
        message: t('form.validation.overviewrequired')
      })
  })
  type PayComponentUpdateProcessForm = TypeOf<typeof payComponentUpdateProcessSchema>

  const updateProcessMutation = useUpdateProcessMutation()
  const updateProcessStepMutation = useUpdateProcessStepMutation()
  const updateProcessParametersMutation = useUpdatePayComponentUpdateProcessMutation()
  const updateProcessContractMutation = useUpdatePayComponentUpdateProcessContractsMutation()

  const form = useForm<PayComponentUpdateProcessForm>({
    resolver: zodResolver(payComponentUpdateProcessSchema),
    defaultValues: {
      // step 1 parameters
      payrollProviderId: getPayComponentUpdateProcess?.payComponentUpdateProcessById.payrollProviderId,
      dueDate: getPayComponentUpdateProcess?.payComponentUpdateProcessById.dueDate
        ? new Date(getPayComponentUpdateProcess?.payComponentUpdateProcessById.dueDate)
        : undefined,
      employerId: getPayComponentUpdateProcess?.payComponentUpdateProcessById.employerId,
      payGroupId: getPayComponentUpdateProcess?.payComponentUpdateProcessById.payGroup.id,
      processName: getPayComponentUpdateProcess?.payComponentUpdateProcessById.name,
      notes: getPayComponentUpdateProcess?.payComponentUpdateProcessById.notes,
      // step 2 parameters
      payrollCodeId: getPayComponentUpdateProcess?.payComponentUpdateProcessById.payrollCodeId || '',
      groupTypeKey: getPayComponentUpdateProcess?.payComponentUpdateProcessById.groupType?.key,
      subGroupTypeKey: getPayComponentUpdateProcess?.payComponentUpdateProcessById?.subGroupType?.key,
      valueTypeKey: getPayComponentUpdateProcess?.payComponentUpdateProcessById.valueType?.key,
      reasonSalaryChangeTypeKey:
        getPayComponentUpdateProcess?.payComponentUpdateProcessById.reasonSalaryChangeType?.key,
      affectedContractsParameterTypeKey:
        getPayComponentUpdateProcess?.payComponentUpdateProcessById.affectedContractsParameterType?.key,
      referencePeriod: getPayComponentUpdateProcess?.payComponentUpdateProcessById.referencePeriod
        ? new Date(getPayComponentUpdateProcess?.payComponentUpdateProcessById.referencePeriod)
        : undefined,
      startDate: getPayComponentUpdateProcess?.payComponentUpdateProcessById.startDate
        ? new Date(getPayComponentUpdateProcess?.payComponentUpdateProcessById.startDate)
        : undefined,
      endDate: getPayComponentUpdateProcess?.payComponentUpdateProcessById.endDate
        ? new Date(getPayComponentUpdateProcess?.payComponentUpdateProcessById.endDate)
        : undefined,
      updateOperationTypeKey: getPayComponentUpdateProcess?.payComponentUpdateProcessById.updateOperationType?.key,
      excludeContracts: getPayComponentUpdateProcess?.payComponentUpdateProcessById.excludeContracts ?? false,
      value: getPayComponentUpdateProcess?.payComponentUpdateProcessById.value || undefined,
      multiplicator: getPayComponentUpdateProcess?.payComponentUpdateProcessById.multiplicator || undefined,
      useFTE: getPayComponentUpdateProcess?.payComponentUpdateProcessById.useFTE ?? false,
      decimals: getPayComponentUpdateProcess?.payComponentUpdateProcessById.decimals.toString(),
      hasExistingValueOnStartDate:
        getPayComponentUpdateProcess?.payComponentUpdateProcessById.hasExistingValueOnStartDate ?? false,
      comment: getPayComponentUpdateProcess?.payComponentUpdateProcessById.comment,
      // step 3 parameters
      payComponentHistories:
        payComponentsData?.payComponentHistoriesByPayComponentUpdateProcessParameters.data.map(x => ({
          contractId: x.contractId,
          payComponentHistoryId: x.payComponentHistoryId || '',
          newValue: x.newValue
        })) || [],
      contractIds:
        getSelectedPayComponentUpdateProcess?.selectedPayComponentUpdateProcessContracts.map(x => x.contractId) || []
    }
  })

  const {
    clearErrors,
    formState: { errors },
    handleSubmit
  } = form

  const handleOnSubmit = async (payComponentUpdate: PayComponentUpdateProcessForm) => {
    switch (activeStep) {
      case step.START:
        handleUpdateProcessGeneralInfo(payComponentUpdate)
        break
      case step.PARAMETERS:
        handleUpdateProcessParameters(payComponentUpdate)
        break
      case step.OVERVIEW:
        handleSelectContractsStep(payComponentUpdate)
        break
      default:
        updateStep(params.id || '')
        break
    }
  }

  const handleUpdateProcessGeneralInfo = (updateGeneralInfo: PayComponentUpdateProcessForm) => {
    const employerName = getPayComponentUpdateProcess?.payComponentUpdateProcessById.employerName
    const payGroup = getPayComponentUpdateProcess?.payComponentUpdateProcessById.payGroup.name
    const reasonSalaryChange = salaryChangedReasonOptions
      .filter(x => x.id === updateGeneralInfo.reasonSalaryChangeTypeKey)
      .at(0)

    updateProcessMutation
      .mutateAsync({
        updateProcessCommand: {
          processId: params.id || '',
          payrollProviderId: getPayComponentUpdateProcess?.payComponentUpdateProcessById.payrollProviderId || '',
          dueDate: updateGeneralInfo.dueDate,
          employerId: getPayComponentUpdateProcess?.payComponentUpdateProcessById.employerId || '',
          payGroupId: getPayComponentUpdateProcess?.payComponentUpdateProcessById.payGroup.id || '',
          name: reasonSalaryChange
            ? `${employerName} - ${payGroup} - ${reasonSalaryChange.label}`
            : `${employerName} - ${payGroup}`,
          notes: updateGeneralInfo.notes,
          cancelled: getPayComponentUpdateProcess?.payComponentUpdateProcessById.cancelled ?? false
        }
      })
      .then(() => {
        setBackendErrors([])
        updateStep(params.id || '')
      })
  }

  function getLastDayOfMonth(date: Date): Date {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return lastDay
  }

  const handleUpdateProcessParameters = (updateParameters: PayComponentUpdateProcessForm) => {
    updateProcessParametersMutation
      .mutateAsync({
        updatePayComponentUpdateProcessCommand: {
          id: getPayComponentUpdateProcess?.payComponentUpdateProcessById.id || '',
          payrollCodeId: updateParameters.payrollCodeId || '',
          valueTypeKey: updateParameters.valueTypeKey || '',
          reasonSalaryChangeTypeKey: updateParameters.reasonSalaryChangeTypeKey,
          affectedContractsParameterTypeKey: updateParameters.affectedContractsParameterTypeKey || '',
          referencePeriod: updateParameters.referencePeriod,
          startDate: updateParameters.startDate,
          endDate: updateParameters.endDate ? getLastDayOfMonth(updateParameters.endDate) : null,
          updateOperationTypeKey: updateParameters.updateOperationTypeKey || '',
          excludeContracts: updateParameters.excludeContracts ?? false,
          value: updateParameters.value || undefined,
          multiplicator: updateParameters.multiplicator || undefined,
          useFTE: updateParameters.useFTE ?? false,
          decimals: updateParameters.decimals,
          hasExistingValueOnStartDate: updateParameters.hasExistingValueOnStartDate ?? false,
          comment: updateParameters.comment
        }
      })
      .then(() => {
        handleUpdateProcessGeneralInfo(updateParameters)
      })
      .then(() => refetchPayComponentUpdateProcess())
      .catch(e => setBackendErrors([e]))
  }

  const handleSelectContractsStep = (payComponentUpdateProcess: PayComponentUpdateProcessForm) => {
    const dataToSend = payComponentUpdateProcess.payComponentHistories
      .filter(x => payComponentUpdateProcess.contractIds.includes(x.contractId))
      .map(x => ({
        contractId: x.contractId,
        payComponentHistoryId: x.payComponentHistoryId,
        newValue: x.newValue
      }))

    updateProcessContractMutation
      .mutateAsync({
        updatePayComponentUpdateProcessContractsCommand: {
          processId: params.id || '',
          payComponentHistories: dataToSend
        }
      })
      .then(() => {
        if (clickedNext) {
          updateStep(params.id || '')
        } else {
          updateStep(params.id || '')
          setClickedNext(true)
          handlePrevious()
        }
      })
  }

  const updateStep = (processId: string) => {
    const nextStep = activeStep + 1
    const currentStep = getPayComponentUpdateProcess?.payComponentUpdateProcessById.currentStep || 1
    if (nextStep > currentStep || !clickedNext) {
      updateProcessStepMutation
        .mutateAsync({
          updateProcessStepCommand: {
            processId: processId,
            step: clickedNext ? nextStep : activeStep
          }
        })
        .then(() => refetchPayComponentUpdateProcess())
    }
    setActiveStep(nextStep)
  }

  const handlePrevious = () => {
    const nextStep = activeStep - 1
    clearErrors()
    setActiveStep(nextStep)
    refetchPayComponentUpdateProcess()
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case step.START:
        return <Start />
      case step.PARAMETERS:
        return (
          <PaycomponentParameters
            employerId={getPayComponentUpdateProcess?.payComponentUpdateProcessById.employerId || ''}
          />
        )
      case step.OVERVIEW:
        return <Overview processId={params.id || ''} />
      case step.FINISH:
        return <Finish processId={params.id || ''} processType={PROCESS_TYPE.PAYCOMPONENT_UPDATE} />
      default:
        return <Navigate to="/404" replace />
    }
  }

  return (
    <Paper
      sx={{
        px: 3,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <FormContainer
        sx={{ pt: 2, display: 'flex', flexDirection: 'column', flexGrow: 1 }}
        form={form}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: [2]
          }}
        >
          <Box sx={{ flexGrow: 1, flexBasis: 0, gap: 1 }}>
            <HeaderTitleNavigation
              backToLink={Process.PROCESSES()}
              title={t('paycomponentupdate.title')}
              showDivider={false}
            />
          </Box>

          <Typography variant="h4" noWrap>
            {getPayComponentUpdateProcess?.payComponentUpdateProcessById.name}
          </Typography>

          <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
            <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
              {activeStep > step.START &&
                !getPayComponentUpdateProcess?.payComponentUpdateProcessById.completed &&
                activeStep !== step.OVERVIEW && (
                  <Button onClick={() => handlePrevious()}>{t('common.previous')}</Button>
                )}
              {activeStep === step.OVERVIEW && (
                <Button onClick={() => setClickedNext(false)} type="submit">
                  {t('common.previous')}
                </Button>
              )}
              <Button type="submit" disabled={activeStep === step.FINISH} variant="contained">
                {t('common.next')}
              </Button>
            </Box>
          </Box>
        </Box>

        <Stepper sx={{ mt: 1, mb: 4 }} activeStep={activeStep - 1} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel error={activeStep === index + 1 && !!Object.keys(errors).length}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ px: 6, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {renderStepContent()}
          <FormErrorList customErrors={backendErrors} />
        </Box>
      </FormContainer>
    </Paper>
  )
}

export default PaycomponentUpdate
