import {
  numericString,
  OrderDirection,
  useGetBranchUnitsByEmployerIdQuery,
  useGetContractByIdQuery,
  useUpdateContractAdditionalInformationMutation,
  FormSelectOption,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { date, number, object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography, useTheme } from '@mui/material'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import {
  FormActionButtons,
  FormDatepicker,
  FormErrorList,
  FormContainer,
  FormGridLayout,
  FormSelect,
  FormInput,
  useFlyIn
} from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface EditContractAdditionalInformationProps {}

export function EditContractAdditionalInformation(props: EditContractAdditionalInformationProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const editContractAdditionalInfoSchema = object({
    department: string().nullable(),
    costCenter: string().nullable(),
    fte: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .gte(0, { message: t('form.validation.ftegreaterthan0') })
        .lte(100, { message: t('form.validation.ftelessthan100') })
    ),
    notes: string().nullable(),
    branchUnitId: string().optional().nullable(),
    workPermitType: string().optional().nullable(),
    workPermitStartDate: date().optional().nullable(),
    workPermitEndDate: date().optional().nullable()
  })
  type EditContractAdditionalInfoForm = TypeOf<typeof editContractAdditionalInfoSchema>
  const params = useParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const { data: getContractById, refetch: refetchContractById } = useGetContractByIdQuery(
    {
      contractId: params?.contractid || ''
    },
    {
      enabled: !!params?.contractid
    }
  )

  const { workPermitOptions } = formTypeSelectOptions

  const contract = getContractById?.contractById

  const { data: branchUnits } = useGetBranchUnitsByEmployerIdQuery(
    {
      employerId: contract?.employerId || '',
      offset: 0,
      limit: -1,
      orderDirection: OrderDirection.Asc,
      showInactive: false
    },
    {
      suspense: false
    }
  )
  const mutation = useUpdateContractAdditionalInformationMutation()
  const form = useForm<EditContractAdditionalInfoForm>({
    resolver: zodResolver(editContractAdditionalInfoSchema),
    defaultValues: {
      department: contract?.department,
      costCenter: contract?.costCenter,
      fte: contract?.fte || 100,
      notes: contract?.notes,
      branchUnitId: contract?.branchUnit?.id ?? undefined,
      workPermitType: contract?.workPermitType?.key,
      workPermitStartDate: contract?.workPermitStartDate ? new Date(contract.workPermitStartDate) : null,
      workPermitEndDate: contract?.workPermitEndDate ? new Date(contract.workPermitEndDate) : null
    }
  })

  const handleOnSubmit = async (contractPaymentInfo: EditContractAdditionalInfoForm) => {
    await mutation
      .mutateAsync({
        updateContractAdditionalInformationCommand: {
          contractId: params.contractid || '',
          department: contractPaymentInfo.department,
          costCenter: contractPaymentInfo.costCenter,
          fte: contractPaymentInfo.fte,
          notes: contractPaymentInfo.notes,
          branchUnitId: contractPaymentInfo.branchUnitId || undefined,
          workPermitKey: contractPaymentInfo.workPermitType,
          workPermitStartDate: contractPaymentInfo.workPermitStartDate,
          workPermitEndDate: contractPaymentInfo.workPermitEndDate
        }
      })
      .then(() => {
        refetchContractById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontract.additionalinfo.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="branchUnitId"
          label={`${t('form.field.branchunit')}`}
          options={branchUnits?.branchUnits.data.map(x => new FormSelectOption(x.id, x.description))}
        />

        <FormInput sx={12} name="department" label={t('form.field.department')} />

        <FormInput sx={12} name="costCenter" label={t('form.field.costCenter')} />

        <FormInput
          sx={12}
          name="fte"
          label={`${t('form.field.fte')} | ${t('form.label.ftedefaultis')}`}
          onBlur={e => !e.target?.value && form.resetField('fte', { defaultValue: 100 })}
        />
        <Typography
          sx={{
            mt: 2,
            ml: 2,
            color: theme.palette.text.secondary,
            fontStyle: 'italic'
          }}
        >
          {t('flyin.editcontract.additionalinfo.fte')}
        </Typography>

        <FormSelect sx={12} name="workPermitType" label={`${t('form.field.workpermit')}`} options={workPermitOptions} />

        <FormDatepicker sx={6} name="workPermitStartDate" label={`${t('form.field.workpermitstartdate')}`} />

        <FormDatepicker sx={6} name="workPermitEndDate" label={`${t('form.field.workpermitenddate')}`} />

        <FormInput sx={12} multiline name="notes" label={t('form.field.notes')} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditContractAdditionalInformation
